import React from "react"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { newVideoData, reccomendedChannel } from "../../data";

const Default = () => {
    return (
        <div>
            <div className="pt-3">
                <p className="text-white text-[15px] font-medium tracking-wider">
                    New Videos
                </p>
                <div className="pt-1">
                    <Carousel
                        ssr={true}
                        arrows={false}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={{
                            desktop: {
                                breakpoint: { max: 3000, min: 1024 },
                                items: 2.5,
                                slidesToSlide: 1
                            },
                            tablet: {
                                breakpoint: { max: 1024, min: 464 },
                                items: 2.5,
                                slidesToSlide: 1
                            },
                            mobile: {
                                breakpoint: { max: 464, min: 0 },
                                items: 2.5,
                                slidesToSlide: 1
                            }
                        }}
                    >
                        {newVideoData.map((data) => (
                            <div className="select-none" key={data.id}>
                                <img
                                    alt="playlist"
                                    src={data.image}
                                    className="w-[6.5rem] h-[4.4rem] object-cover rounded-sm"
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>

            <div className="pt-7">
                <p className="text-white text-[15px] font-medium tracking-wider">
                    Reccomended Channels
                </p>
                <div className="pt-1">
                    <Carousel
                        ssr={true}
                        arrows={false}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={{
                            desktop: {
                                breakpoint: { max: 3000, min: 1024 },
                                items: 4.5,
                                slidesToSlide: 1
                            },
                            tablet: {
                                breakpoint: { max: 1024, min: 464 },
                                items: 4.5,
                                slidesToSlide: 1
                            },
                            mobile: {
                                breakpoint: { max: 464, min: 0 },
                                items: 4.5,
                                slidesToSlide: 1
                            }
                        }}
                    >
                        {reccomendedChannel.map((data) => (
                            <div className="select-none" key={data.id}>
                                <img
                                    alt="playlist"
                                    src={data.image}
                                    className="w-[3.5rem] h-[3.5rem] object-cover rounded-full cursor-pointer"
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default Default