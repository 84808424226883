import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { mainHomePageData } from "../../data";
import { IoIosSettings, IoMdArrowRoundBack } from "react-icons/io";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaHeart } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { HiDownload } from "react-icons/hi";
import { GoBookmark } from "react-icons/go";
import { MdFullscreen, MdFullscreenExit, MdOutlineReport } from "react-icons/md";
import { MdOutlineForward10, MdOutlineReplay10 } from "react-icons/md";
import CommentView from "./CommentView";
import { IoPlay } from "react-icons/io5";
import { Progress } from "@material-tailwind/react";

const VideoDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState(null);
    const [isSubscribe, setIsSubscribe] = useState(true);
    const [menu, setMenu] = useState(false);
    const [showComment, setShowComment] = useState(true);
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [showTitle, setShowTitle] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        const data = mainHomePageData.find(video => video.id === parseInt(id));
        setVideoData(data);
    }, [id]);

    if (!videoData) {
        return <div>Video not found</div>;
    }

    const handleComment = () => {
        setShowComment(!showComment);
        setShowCommentInput(false);
    }

    return (
        <div className="bg-black h-full relative">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Video Details / V2 / 06 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>
            <div className="sticky top-0">
                <div className="grid grid-cols-12 px-2 bg-black">
                    <div className="col-span-2 flex justify-start pt-2">
                        <IoMdArrowRoundBack
                            onClick={() => navigate(-1)}
                            className="text-white text-[18px] cursor-pointer" />
                    </div>
                    <div className="col-span-8 flex flex-col items-center">
                        <p
                            // className="text-[#ffffff] text-[12px] line-clamp-1" 
                            onClick={() => setShowTitle(!showTitle)}
                            className={`${showTitle ? "line-clamp-none leading-[15px]" : "line-clamp-1"} text-[#ffffff] text-[12px] text-center cursor-pointer`}
                        >
                            {videoData.title}
                        </p>
                        <p className="text-[#9b9b9b] text-[12px]">{videoData.channel}</p>
                    </div>
                    <div className="col-span-2 flex justify-end pt-2 relative">
                        {menu ?
                            <p
                                onClick={() => setMenu(false)}
                                className="text-[#ffffff] text-[12px] font-medium hover:text-red-500 cursor-pointer"
                            >
                                Close
                            </p>
                            :
                            <HiOutlineDotsHorizontal
                                onClick={() => setMenu(!menu)}
                                className="text-white text-[18px] cursor-pointer"
                            />
                        }
                        {menu &&
                            <div className="absolute top-8 right-0 bg-white py-1 rounded-lg z-10">
                                <p className="text-[11px] font-medium px-3 py-[2px] cursor-pointer hover:text-red-500 border-b border-[#c8c8c8]">Save</p>
                                <p className="text-[11px] font-medium px-3 py-[2px] cursor-pointer hover:text-red-500 border-b border-[#c8c8c8]">Share</p>
                                <p className="text-[11px] font-medium px-3 py-[2px] cursor-pointer hover:text-red-500 border-b border-[#c8c8c8]">Report</p>
                                <p className="text-[11px] font-medium px-3 py-[2px] cursor-pointer hover:text-red-500">Download</p>
                            </div>
                        }
                    </div>
                </div>

                <div className="p-2 relative group bg-black">
                    <video
                        loop
                        muted
                        autoPlay
                        disablePictureInPicture
                        src={videoData.video}
                        className="w-full h-[9.5rem] object-cover rounded-sm"
                    />

                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                        <div className="flex items-center gap-3">
                            <div className="bg-[#00000043] h-[30px] w-[30px] rounded-full cursor-pointer flex items-center justify-center">
                                <MdOutlineReplay10 className="text-white text-[20px]" />
                            </div>
                            <div className="bg-[#00000043] h-[30px] w-[30px] rounded-full cursor-pointer flex items-center justify-center">
                                <IoPlay className="text-white text-[16px]" />
                            </div>
                            <div className="bg-[#00000043] h-[30px] w-[30px] rounded-full cursor-pointer flex items-center justify-center">
                                <MdOutlineForward10 className="text-white text-[20px]" />
                            </div>
                        </div>
                    </div>

                    <div className="absolute bottom-[30px] left-0 w-full flex justify-center opacity-0 group-hover:opacity-100">
                        <Progress 
                            value={50} 
                            size="sm" 
                            color="green" 
                            className="cursor-pointer w-[80%]" 
                        />
                    </div>

                    <div className="absolute bottom-[5px] left-0 w-full px-3 pt-1 pb-1 bg-gradient-to-t from-[#000000] opacity-0 group-hover:opacity-100">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-1">
                                <p className="text-white text-[10px]">00:02</p>
                                <p className="text-white text-[10px]">/</p>
                                <p className="text-white text-[10px]">00:30</p>
                            </div>

                            <div className="flex items-center gap-1">
                                <IoIosSettings className="text-[14px] text-white cursor-pointer transition-all duration-500 hover:rotate-180" />
                                {isFullScreen ?
                                    <MdFullscreenExit onClick={() => setIsFullScreen(false)} className="text-[14px] text-white cursor-pointer" />
                                    :
                                    <MdFullscreen onClick={() => setIsFullScreen(true)} className="text-[14px] text-white cursor-pointer" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="overflow-y-scroll">
                {showComment ?
                    <>
                        <div className="px-2">
                            <div className="grid grid-cols-12 gap-y-0 gap-2">
                                <div className="col-span-10">
                                    <p className="text-[12px] text-[#ffffff] leading-[15px] mt-[2px]">{videoData.title}</p>
                                </div>
                                <div className="col-span-2 flex justify-end items-start">
                                    <button className="bg-white p-1 rounded-md mt-[2px]">
                                        <FaHeart className="text-[12px] hover:text-red-600" />
                                    </button>
                                </div>
                                <div className="col-span-8 flex items-center gap-3 py-1">
                                    <img
                                        alt={videoData.channel}
                                        src={videoData.channelLogo}
                                        className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                    />
                                    <button
                                        onClick={() => setIsSubscribe(!isSubscribe)}
                                        className={`${isSubscribe ? "bg-white text-black " : "border border-white text-white bg-black"} font-medium rounded-full px-3 py-[2px] text-[12px]`}
                                    >
                                        {isSubscribe ? "Subscribe" : "Unsubscribe"}
                                    </button>
                                </div>
                                <div className="col-span-4 pt-[10px]">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-1 text-[#9b9b9b]">
                                            <FaEye className="text-[12px]" />
                                            <p className="text-[12px]">530</p>
                                        </div>
                                        <div className="flex items-center gap-1 text-[#9b9b9b]">
                                            <FaHeart className="text-[12px]" />
                                            <p className="text-[12px]">20</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 flex items-center justify-between pt-1">
                                    <div className="bg-[#353535] text-[#ffffff] flex items-center px-2 py-[2px] rounded-full cursor-pointer">
                                        <RiShareForwardLine className="text-[14px]" />
                                        <p className="text-[10px] ml-1">Share</p>
                                    </div>
                                    <div className="bg-[#353535] text-[#ffffff] flex items-center px-2 py-[2px] rounded-full cursor-pointer">
                                        <HiDownload className="text-[14px]" />
                                        <p className="text-[10px] ml-1">Download</p>
                                    </div>
                                    <div className="bg-[#353535] text-[#ffffff] flex items-center px-2 py-[2px] rounded-full cursor-pointer">
                                        <GoBookmark className="text-[14px]" />
                                        <p className="text-[10px] ml-1">Save</p>
                                    </div>
                                    <div className="bg-[#353535] text-[#ffffff] flex items-center px-2 py-[2px] rounded-full cursor-pointer">
                                        <MdOutlineReport className="text-[14px]" />
                                        <p className="text-[10px] ml-1">Report</p>
                                    </div>
                                </div>
                                <div className="col-span-12 pt-2">
                                    <div className="bg-[#353535] p-2 rounded-lg">
                                        <p className="text-[10px] text-white flex items-center justify-end">550 Comments</p>
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-2">
                                                <img
                                                    alt="user"
                                                    src="https://cdn.pixabay.com/photo/2023/08/05/16/22/man-8171388_1280.jpg"
                                                    className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                                />
                                            </div>
                                            <div className="col-span-9 -ml-3">
                                                <p className="text-white text-[12px] font-semibold mt-[-2px]">John</p>
                                                <p className="text-[#b2b2b2] text-[10px] leading-[12px] line-clamp-2">
                                                    Wow this is so good Wow this is so good Wow this is so good Wow this is so good Wow this is so good
                                                </p>
                                            </div>
                                            <div className="col-span-12 mt-2">
                                                <p
                                                    onClick={() => setShowComment(!showComment)}
                                                    className="bg-[#242424] text-white text-[10px] text-center py-[6px] rounded-lg cursor-pointer"
                                                >
                                                    Type here to view or add comment
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-2">
                            {mainHomePageData
                                .filter((data) => data.id !== parseInt(id))
                                .map((data) => (
                                    <div className="px-2 pb-2" key={data.id}>
                                        <img
                                            alt="thumbnail"
                                            src={data.thumbnail}
                                            onClick={() => navigate(`/video/${data.id}`)}
                                            className="w-full h-[10rem] object-cover rounded-sm cursor-pointer"
                                        />
                                        <div className="grid grid-cols-12 mt-1">
                                            <div className="col-span-2">
                                                <img
                                                    alt="profile"
                                                    className="h-8 w-8 rounded-full object-cover cursor-pointer"
                                                    src={data.channelLogo}
                                                />
                                                <div className="flex flex-col items-center justify-start ml-[-12px]">
                                                    <p className="text-white text-[13px]">{data.views}</p>
                                                    <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                                                </div>
                                            </div>
                                            <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                                                <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                                                <p className="text-[#8d8d8d] text-[12px]">{data.channel} - {data.days}</p>
                                            </div>
                                            <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                                                <p className="text-white text-[14px] cursor-pointer">: :</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                    :
                    <CommentView
                        closeComment={handleComment}
                        showInput={() => setShowCommentInput(!showCommentInput)}
                    />
                }
            </div>

            {showCommentInput &&
                <>
                    {!showComment && (
                        <div className="sticky bottom-[5.5rem] left-0 w-full bg-[#4e4e4e] px-3 py-2 flex items-center">
                            <input
                                type="text"
                                placeholder="Add a comment..."
                                className="flex-1 py-1 px-2 bg-[#353535] text-white rounded-md focus:outline-none"
                            />
                            <button className="bg-blue-500 text-white px-2 py-[7px] text-[12px] ml-2 rounded-md">
                                Send
                            </button>
                        </div>
                    )}
                </>
            }
        </div>
    );
}

export default VideoDetails;
