import React from "react"
import { social_logo } from "../data"
import { useNavigate } from "react-router-dom"

const LoginPage = () => {
   const lable = "KV / FE / LOGIN / V1 / 27 July, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   const navigate = useNavigate();

   return (
      <div className="h-full bg-[#2C672E] relative">
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
         </div>
         
         <h1 className="text-[3rem] px-3 font-semibold uppercase text-white">
            kv
         </h1>

         <form className="px-6 mt-6">
            <p className="text-white font-semibold text-[24px] text-center">
               Welcome Back !
            </p>
            <div className="mt-4">
               <label className="block text-[#D9D9D9] font-normal" htmlFor="email">
                  Email
               </label>
               <input
                  type="email"
                  placeholder="Enter your email address"
                  className="w-full px-3 py-2 mt-1 text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
               />
            </div>
            <div className="mt-2">
               <label className="block text-[#D9D9D9] font-normal" htmlFor="email">
                  Password
               </label>
               <input
                  type="password"
                  placeholder="Enter your password"
                  className="w-full px-3 py-2 mt-1 text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
               />
            </div>
            <div className="mt-2 flex items-center justify-between">
               <div className="flex items-center">
                  <input
                     type="checkbox"
                     className="mr-1 border-none focus:outline-none cursor-pointer"
                  />
                  <p className="text-white text-[12px]">
                     Remember me
                  </p>
               </div>
               <p className="cursor-pointer text-[12px] text-[#00FF0A] font-semibold" onClick={() => navigate("/recoverpass")}>
                  Recover password
               </p>
            </div>
            <button
               type="submit"
               onClick={() => navigate("/home")}
               className="w-full py-2 mt-4 text-white bg-[#00FF0A] rounded-md focus:outline-none"
            >
               Login
            </button>

            <div className="flex items-center justify-between gap-4 mt-2">
               <div className="h-[0.4px] bg-white rounded-full w-full" />
               <p className="text-[10px] text-white whitespace-nowrap">or log in with</p>
               <div className="h-[0.4px] bg-white rounded-full w-full" />
            </div>

            <div className="flex items-center justify-between mt-2">
               {social_logo.map((data) => (
                  <div key={data.id} className="bg-white p-2 rounded">
                     {data.src ? (
                        <img
                           alt={data.name}
                           src={data.src}
                           className="h-5 w-5"
                        />
                     ) : (
                        <p className="text-sm font-extrabold h-5 w-5 text-center">{data.name}</p>
                     )}
                  </div>
               ))}
            </div>
         </form>

         <div className="absolute flex items-center justify-center gap-1 bottom-5 w-full">
            <p className="text-[14px] text-white">Don’t have an account?</p>
            <p className="font-semibold text-[#00FF0A] text-[14px] cursor-pointer" onClick={() => navigate("/signup")}>Sign Up</p>
         </div>
      </div>
   )
}

export default LoginPage