import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import MainMenu from "../components/common/MainMenu";

const Sponsored = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / KV Sponsored / V1 / 26 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                <div className="col-span-3 flex items-center justify-start gap-3">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                    <IoMdArrowRoundBack
                        onClick={() => navigate(-1)}
                        className="text-[15px] text-white bg-black cursor-pointer"
                    />
                </div>
                <div className="col-span-6 flex items-center justify-center gap-2">
                    <p className="text-[12px] font-bold">
                        Become Sponsored
                    </p>
                </div>
                <div className="col-span-3 flex items-center justify-end gap-2">
                    <MainMenu />
                </div>
            </div>

            <div className="mt-2 px-2">
                <p className="text-white text-[13px] text-justify leading-4">
                    If you are a content creator, no matter how large or small,
                    you can apply to become "KV Sponsored" ! For a set fee, your
                    uploads will be boosted on our algorithm, meaning more views,
                    subscribers, and likes!
                </p>

                <div className="flex items-center justify-between mt-4">
                    <button className="text-white bg-[#1e1e1e] text-[13px] px-3 py-[3px] rounded-sm">
                        Application
                    </button>
                    <button className="text-white bg-[#1e1e1e] text-[13px] px-3 py-[3px] rounded-sm">
                        pricing
                    </button>
                    <button className="text-white bg-[#1e1e1e] text-[13px] px-3 py-[3px] rounded-sm">
                        About
                    </button>
                    <button className="text-white bg-[#1e1e1e] text-[13px] px-3 py-[3px] rounded-sm">
                        T & C
                    </button>
                </div>

                <div className="mt-4">
                    <div className="grid grid-cols-12 mb-2">
                        <div className="col-span-4 flex items-end">
                            <p className="text-white whitespace-nowrap text-[13.5px]">Account Name :</p>
                        </div>
                        <div className="col-span-8">
                            <input
                                type="text"
                                placeholder="Enter your account name"
                                className="border-[#dedede] text-white text-[13.5px] placeholder:text-[13.5px] border-b-[0.4px] w-full bg-transparent outline-none"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 mb-2">
                        <div className="col-span-4 flex items-end">
                            <p className="text-white whitespace-nowrap text-[13.5px]">Email :</p>
                        </div>
                        <div className="col-span-8">
                            <input
                                type="text"
                                placeholder="Enter your email"
                                className="border-[#dedede] text-white text-[13.5px] placeholder:text-[13.5px] border-b-[0.4px] w-full bg-transparent outline-none"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 mb-2">
                        <div className="col-span-4 flex items-end">
                            <p className="text-white whitespace-nowrap text-[13.5px]">Password :</p>
                        </div>
                        <div className="col-span-8">
                            <input
                                type="text"
                                placeholder="Enter your password"
                                className="border-[#dedede] text-white text-[13.5px] placeholder:text-[13.5px] border-b-[0.4px] w-full bg-transparent outline-none"
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <p className="text-[16px] text-white font-bold underline underline-offset-4 mb-2">
                        Pricing Plan Options
                    </p>

                    <div className="flex items-center gap-2">
                        <input type="radio" name="pricing" id="basic" className="mr-2 cursor-pointer" />
                        <label htmlFor="basic" className="text-white font-bold text-[20px]">Basic</label>
                        <p className="text-[#54b929] text-[12px]">$10</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" name="pricing" id="standard" className="mr-2 cursor-pointer" />
                        <label htmlFor="standard" className="text-white font-bold text-[20px]">Standard</label>
                        <p className="text-[#54b929] text-[12px]">$20</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" name="pricing" id="premium" className="mr-2 cursor-pointer" />
                        <label htmlFor="premium" className="text-white font-bold text-[20px]">Premium</label>
                        <p className="text-[#54b929] text-[12px]">$30</p>
                    </div>

                    <div className="mt-4 flex items-center justify-center">
                        <button className="text-[14px] px-4 py-1 rounded bg-white text-black hover:opacity-90">
                            Apply Now
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Sponsored