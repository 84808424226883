import React, { useState } from "react";
import { IoMdClose, IoMdHeart, IoMdHeartDislike } from "react-icons/io";
import { commentData } from "../../data";

const CommentView = ({ closeComment, showInput }) => {
    const [openReplyId, setOpenReplyId] = useState(null);

    const toggleReplies = (id) => {
        setOpenReplyId((prev) => (prev === id ? null : id));
    };

    return (
        <div className="px-2 mb-2">
            <div className="bg-[#353535] p-1 rounded-lg">
                <div className="flex items-center justify-between my-2">
                    <button className="text-[12px] font-semibold bg-[#ffffff] px-2 py-1 rounded-full" onClick={showInput}>
                        Add Comments
                    </button>
                    <IoMdClose
                        onClick={closeComment}
                        className="text-[20px] text-white cursor-pointer"
                    />
                </div>

                {commentData.map((data) => (
                    <div className="grid grid-cols-12 bg-[#242424] rounded-lg p-2 my-1" key={data.id}>
                        <div className="col-span-2">
                            <img
                                alt={data.name}
                                src={data.image}
                                className="h-7 w-7 rounded-full object-cover cursor-pointer"
                            />
                        </div>
                        <div className="col-span-10 -ml-3">
                            <p className="text-white text-[12px] font-semibold mt-[-2px]">{data.name}</p>
                            <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                {data.message}
                            </p>
                            <div className="flex items-center gap-3 mt-1">
                                <p className="text-cyan-500 text-[10px] font-semibold cursor-pointer" onClick={() => toggleReplies(data.id)}>Replies</p>
                                <IoMdHeart className="h-3 w-3 text-white cursor-pointer hover:text-lime-500" />
                                <IoMdHeartDislike className="h-3 w-3 text-white cursor-pointer hover:text-red-600" />
                            </div>
                            {openReplyId === data.id &&
                                <div className="grid grid-cols-12 mt-2">
                                    <div className="col-span-2">
                                        <img
                                            alt={data.name}
                                            src={data.image}
                                            className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                        />
                                    </div>
                                    <div className="col-span-10">
                                        <p className="text-white text-[12px] font-semibold mt-[-2px]">{data.name}</p>
                                        <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                            {data.message}
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CommentView;
