import React, { useState } from "react";
// import { IoMenu } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import Likes from "./Likes";
import Comments from "./Comments";
import Subscribers from "./Subscribers";
import Mentions from "./Mentions";
import Analytics from "./Analytics";
import Messages from "./Messages";
import Default from "./Default";

import { IoFilterCircle } from "react-icons/io5";
import { IoIosSettings } from "react-icons/io";

const Notification = () => {
    const lable = "KV / FE / Notification / V1 / 05 Aug, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [activeComponent, setActiveComponent] = useState("default");

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    const handleFilterPopUp = () => {
        setShowFilter(!showFilter);
        setShowSettings(false);
    }

    const handleSettingsPopUp = () => {
        setShowSettings(!showSettings);
        setShowFilter(false);
    }

    return (
        <div className="h-full">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>
            <div className="sticky top-0">
                <div className="grid grid-cols-12 items-center px-2 bg-white">
                    <div className="col-span-2 flex items-center justify-start">
                        <h1
                            onClick={() => navigate("/home")}
                            className="text-[1.6rem] font-bold uppercase text-black cursor-pointer"
                        >
                            kv
                        </h1>
                    </div>
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-black text-[16px] font-medium" onClick={() => setActiveComponent("default")}>Notifications</p>
                    </div>
                    <div className="col-span-2 flex items-center justify-end">
                        <label className="flex cursor-pointer select-none items-center ml-3">
                            <div className="relative">
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    className="sr-only"
                                />
                                <div className={`block h-5 w-9 rounded-full ${isChecked ? "bg-green-600" : "bg-black"}`}>

                                </div>
                                <div className={`absolute left-[2px] top-[2px] flex h-4 w-4 items-center justify-center text-[7px] rounded-full bg-white transition ${isChecked ? "translate-x-full" : ""}`}>
                                    {isChecked ? "on" : "off"}
                                </div>
                            </div>
                        </label>

                        {/* <IoMenu className="text-black h-7 w-7 cursor-pointer" /> */}
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-3 px-2 pt-3 pb-2 border-b-[0.4px] border-[#434343] bg-black">
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("likes")}
                            className="flex items-center justify-center px-1 py-1 bg-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Likes</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("comments")}
                            className="flex items-center justify-center px-1 py-1 bg-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Comments</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("subscribers")}
                            className="flex items-center justify-center px-1 py-1 bg-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Subscribers</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("mentions")}
                            className="flex items-center justify-center px-1 py-1 bg-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Mentions</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("analytics")}
                            className="flex items-center justify-center px-1 py-1 bg-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Analytics</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("messages")}
                            className="flex items-center justify-center px-1 py-1 bg-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Messages</p>
                        </div>
                    </div>
                    {activeComponent === "default" ? null :
                        <div className="col-span-3 relative">
                            <div className="flex items-center justify-between">
                                <IoFilterCircle
                                    onClick={handleFilterPopUp}
                                    className="h-6 w-6 text-white cursor-pointer"
                                />
                                <IoIosSettings
                                    onClick={handleSettingsPopUp}
                                    className="h-6 w-6 text-white cursor-pointer"
                                />
                            </div>

                            {showFilter &&
                                <div className="absolute top-8 left-0 bg-white px-2 py-1 rounded-lg">
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Latest Notifications</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Older Notifications</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Sort by date</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">A - Z</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                </div>
                            }

                            {showSettings &&
                                <div className="absolute top-8 right-0 bg-white px-2 py-1 rounded-lg">
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Highlight Unread</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Show Categories</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Delete Notification</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <div className="px-2 pt-1 overflow-y-scroll">
                {activeComponent === "default" && <Default />}
                {activeComponent === "likes" && <Likes />}
                {activeComponent === "comments" && <Comments />}
                {activeComponent === "subscribers" && <Subscribers />}
                {activeComponent === "mentions" && <Mentions />}
                {activeComponent === "analytics" && <Analytics />}
                {activeComponent === "messages" && <Messages />}
            </div>
        </div>
    )
}

export default Notification