import React, { Fragment } from "react"
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BlogPage = () => {
    const navigate = useNavigate();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Blog / V1 / 20 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>
            
            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer ml-[-3px]" onClick={() => navigate(-1)} />
                    <p className="text-white text-[16px] font-medium">Blog</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3">
                    <CiSearch className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate("/search")} />
                    <HiOutlineDotsVertical className="text-white h-5 w-5 cursor-pointer mr-[-8px]" />
                </div>
            </div>

            <div className="px-2">
                <Carousel
                    draggable
                    infinite={true}
                    arrows={false}
                    autoPlay={true}
                    showDots={true}
                    autoPlaySpeed={2000}
                    responsive={responsive}
                    renderDotsOutside={true}
                >
                    <img
                        alt="blog"
                        className="h-[165px] w-full object-cover rounded"
                        src="https://cdn.pixabay.com/photo/2020/07/08/04/12/work-5382501_960_720.jpg"
                    />
                    <img
                        alt="blog"
                        className="h-[165px] w-full object-cover rounded"
                        src="https://cdn.pixabay.com/photo/2017/07/31/11/21/people-2557396_1280.jpg"
                    />
                    <img
                        alt="blog"
                        className="h-[165px] w-full object-cover rounded"
                        src="https://cdn.pixabay.com/photo/2017/01/14/10/56/people-1979261_1280.jpg"
                    />
                </Carousel>
            </div>

            <div className="px-2 mt-4">
                <p className="text-[16px] leading-4 font-semibold text-white">
                    Sodales ad accumsan lacus, phasellus etiam curabitur,
                    posuere mattis magna lorem est hac cursus sapien.
                </p>
                <p className="mt-2 text-[12px] leading-4 text-white">
                    Praesent imperdiet velit lobortis, cras tortor tristique,
                    aliquam dolor etiam pellentesque scelerisque eget.
                    Inceptos vestibulum, pharetra posuere nam elementum.
                    Cursus potenti, curabitur elit luctus. Donec tellus
                    ante quis, facilisis odio ultrices, erat sapien eros
                    ad nullam turpis tempus
                </p>

                <img
                    alt="blog"
                    className="h-[165px] w-full object-cover my-6 rounded"
                    src="https://cdn.pixabay.com/photo/2017/05/04/16/37/meeting-2284501_1280.jpg"
                />

                <p className="text-[16px] leading-4 font-semibold text-white">
                    Sodales ad accumsan lacus, phasellus etiam curabitur.
                </p>
                <p className="mt-2 text-[12px] leading-4 text-white">
                    Praesent imperdiet velit lobortis, cras tortor tristique,
                    aliquam dolor etiam pellentesque scelerisque eget. Inceptos
                    vestibulum, pharetra posuere nam elementum. Cursus potenti,
                    curabitur elit luctus. Donec tellus ante quis, facilisis odio
                    ultrices, erat sapien eros ad nullam turpis tempus
                </p>

                <img
                    alt="blog"
                    className="h-[165px] w-full object-cover my-6 rounded"
                    src="https://cdn.pixabay.com/photo/2018/03/10/12/00/teamwork-3213924_1280.jpg"
                />
            </div>
        </Fragment>
    )
}

export default BlogPage