import apple_logo from "../src/assets/apple_logo.png";
import google_logo from "../src/assets/google_logo.png";
import facebook_logo from "../src/assets/facebook_logo.png";

export const social_logo = [
    {
        id: 1,
        name: "apple",
        src: apple_logo
    },
    {
        id: 2,
        name: "google",
        src: google_logo
    },
    {
        id: 3,
        name: "facebook",
        src: facebook_logo
    },
    {
        id: 4,
        name: "CC",
        src: false
    },
    {
        id: 5,
        name: "C",
        src: false
    }
];

export const mainHomePageData = [
    {
        id: 1,
        title: "Day in the life of a D1 College Tennis Player",
        description: "Experience the breathtaking beauty of nature as Animal Planet takes you on a journey through some of the most stunning landscapes on Earth. From towering mountains to serene lakes, this video captures the essence of nature's splendor.",
        channel: "UServe",
        followers: "123K",
        type: "Nature",
        views: "1.2M",
        days: "10 hours ago",
        date: "2024-08-12",
        videoLength: "03:45",
        thumbnail: "https://i.ytimg.com/vi/5RbQV0EZI-A/hq720.jpg?sqp=-oaymwE2CNAFEJQDSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhHIFgoZTAP&rs=AOn4CLDSyFKPOZ51b1mt_JLMk8vEZ7-LTg",
        video: "https://cdn.pixabay.com/video/2017/07/23/10814-226624958_large.mp4",
        channelLogo: "https://yt3.ggpht.com/I6FfLXHdPC72OXlADnT8bSymlgE4wBioaQscy_i8gCecZ0YK_l4o-fqLhALhUjMtIgCAYMsDzQ=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 2,
        title: "USMNT vs. Brazil | Highlights | June 12, 2024",
        description: "Join EpicNature as they showcase one of the most incredible waterfalls you will ever see. This mesmerizing waterfall, surrounded by lush greenery, offers a perfect escape into the wilderness.",
        channel: "U.S. Soccer",
        followers: "12.3M",
        type: "Nature",
        views: "340K",
        days: "18 hours ago",
        date: "2024-08-13",
        videoLength: "05:12",
        thumbnail: "https://i.ytimg.com/vi/mno2V0D-eck/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC6n6PX9d-4QpYr_wouzFXYkAS9ZA",
        video: "https://cdn.pixabay.com/video/2024/07/25/223067_large.mp4",
        channelLogo: "https://yt3.ggpht.com/AJ1BgpeOwPRRYi68ZbtR80-87V0qawy688ZT3KSgJWJ-XOwlHD1BIGDT-vFQyZ-G4ESc5AIchQ=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 3,
        title: "Miami Dolphins vs. Tampa Bay Buccaneers | 2024 Preseason Week 3 Game Highlights",
        description: "Relive the excitement of the latest football matches with Sports World's ultimate football highlights. Watch the best goals, thrilling saves, and game-changing moments that kept fans on the edge of their seats.",
        channel: "NFL",
        followers: "1.5M",
        type: "Sports",
        views: "5.6M",
        days: "1 days ago",
        date: "2024-08-07",
        videoLength: "11:01",
        thumbnail: "https://i.ytimg.com/vi/aMCo8ENIlEM/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBzZ83MzQ00hCfpBNVWXQRAXEfR3w",
        video: "https://cdn.pixabay.com/video/2023/10/02/183229-870457423_large.mp4",
        channelLogo: "https://yt3.ggpht.com/kDUg480xOHHjIEnvADRDAz8w9MapJEx-3UJjJQzfeg4O-SrAGHTOSpd5Tm5QtJi0RlSfafMxQg=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 4,
        title: "GoPro: Let Me Take You To The Mountain",
        description: "Dive into the world of gaming with Gamer's Paradise and enjoy the best gaming moments captured on video. From epic wins to hilarious fails, this compilation is a must-watch for all gaming enthusiasts.",
        channel: "GoPro",
        followers: "593k",
        type: "Gaming",
        views: "2.8M",
        days: "2 day ago",
        date: "2024-08-18",
        videoLength: "15:39",
        thumbnail: "https://i.ytimg.com/vi/C-y70ZOSzE0/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCuPl_mszoR8PMi_RCqSdKhcbhanw",
        video: "https://cdn.pixabay.com/video/2017/04/02/8559-211218179_large.mp4",
        channelLogo: "https://yt3.ggpht.com/C_fpQjksnDktzG4qR_mlm5DaylN_c5_CDUl3TFuqVhPeFvCJVf6fksbHF6Q2Xl6AeHYas-dvqAw=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 5,
        title: "BUCKET LIST RACES | Ep 1 | Best Running Races | Run4Adventure",
        description: "Rock out with Music Hub's top 10 rock songs of all time. This video features some of the greatest rock hits that have defined the genre and continue to inspire new generations of rock fans.",
        channel: "Olympics",
        followers: "25.6M",
        type: "Music",
        views: "4.1M",
        days: "4 days ago",
        date: "2024-08-23",
        videoLength: "19:44",
        thumbnail: "https://i.ytimg.com/vi/GZ-iF3pVwZk/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBHYBOfNxDhOHfG9f-03ZwLyGvSzA",
        video: "https://cdn.pixabay.com/video/2019/04/20/22913-336128301_large.mp4",
        channelLogo: "https://yt3.ggpht.com/ytc/AIdro_nluEWCse21YbTlTHnnt6YtkIbSTFue0qBvGpLTGGRyquTB=s68-c-k-c0x00ffffff-no-rj",
    }
];

export const paylistData = [
    {
        id: 1,
        image: "https://images.unsplash.com/photo-1580617971627-cffa74e39d1d?q=80&w=1906&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        id: 2,
        image: "https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        id: 3,
        image: "https://images.unsplash.com/photo-1683457859681-66672260d3ef?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        id: 4,
        image: "https://images.unsplash.com/photo-1570829174962-38c53efc5e5a?q=80&w=2063&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
];

export const historyData = [
    {
        id: 1,
        image: "https://images.unsplash.com/photo-1722166153862-2a853d435197?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        id: 2,
        image: "https://images.unsplash.com/photo-1656528837404-92618ec26aa3?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        id: 3,
        image: "https://images.unsplash.com/photo-1715363146696-95a90cd88596?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        id: 4,
        image: "https://images.unsplash.com/photo-1721633617180-97c67428a48e?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
];

export const newVideoData = [
    {
        id: 1,
        image: "https://cdn.pixabay.com/photo/2023/11/14/20/08/woman-8388428_1280.jpg"
    },
    {
        id: 2,
        image: "https://cdn.pixabay.com/photo/2018/07/28/14/30/soccer-3568168_1280.jpg"
    },
    {
        id: 3,
        image: "https://cdn.pixabay.com/photo/2024/06/29/06/30/forest-8860740_1280.png"
    },
    {
        id: 4,
        image: "https://cdn.pixabay.com/photo/2023/05/23/15/42/bengal-tiger-8013012_1280.jpg"
    },
    {
        id: 5,
        image: "https://cdn.pixabay.com/photo/2017/08/06/04/31/people-2588747_1280.jpg"
    },
];

export const reccomendedChannel = [
    {
        id: 1,
        image: "https://cdn.pixabay.com/photo/2015/01/05/11/02/wordpress-589121_1280.jpg"
    },
    {
        id: 2,
        image: "https://cdn.pixabay.com/photo/2012/12/11/21/28/great-69521_1280.jpg"
    },
    {
        id: 3,
        image: "https://cdn.pixabay.com/photo/2017/06/08/23/00/apple-2385198_1280.jpg"
    },
    {
        id: 4,
        image: "https://cdn.pixabay.com/photo/2016/03/26/23/18/coffee-1281842_1280.jpg"
    },
    {
        id: 5,
        image: "https://cdn.pixabay.com/photo/2017/08/29/10/11/mercedes-benz-2692776_960_720.jpg"
    },
];

export const likeNotification = [
    {
        id: 1,
        time: "8:30 am",
        title: "John Doe liked your video",
        description: "John Doe liked your video: 'Exploring the Mountains'.",
        posterImg: "https://cdn.pixabay.com/photo/2024/01/08/21/40/mountain-8496397_1280.jpg",
        channelLogo: "https://cdn.pixabay.com/photo/2017/08/29/10/11/mercedes-benz-2692776_960_720.jpg"
    },
    {
        id: 2,
        time: "9:00 am",
        title: "Jane Smith liked your post",
        description: "Jane Smith liked your post: 'Enjoying a beautiful sunny day!'",
        posterImg: "https://cdn.pixabay.com/photo/2022/11/22/17/22/mountains-7610216_1280.jpg",
        channelLogo: "https://cdn.pixabay.com/photo/2016/03/26/23/18/coffee-1281842_1280.jpg"
    },
    {
        id: 3,
        time: "10:15 am",
        title: "Alice Johnson liked your comment",
        description: "Alice Johnson liked your comment on John's post: 'Great job on the project!'",
        posterImg: "https://cdn.pixabay.com/photo/2016/11/20/09/06/laptop-1842297_1280.jpg",
        channelLogo: "https://cdn.pixabay.com/photo/2017/06/08/23/00/apple-2385198_1280.jpg"
    },
    {
        id: 4,
        time: "11:20 am",
        title: "Bob Lee liked your photo",
        description: "Bob Lee liked your photo: 'Sunset at the beach'.",
        posterImg: "https://cdn.pixabay.com/photo/2019/03/18/17/58/waves-4063684_1280.jpg",
        channelLogo: "https://cdn.pixabay.com/photo/2012/12/11/21/28/great-69521_1280.jpg"
    },
    {
        id: 5,
        time: "12:45 pm",
        title: "Charlie Brown liked your post",
        description: "Charlie Brown liked your post: 'Excited about my new project!'",
        posterImg: "https://cdn.pixabay.com/photo/2016/04/07/06/53/bmw-1313343_1280.jpg",
        channelLogo: "https://cdn.pixabay.com/photo/2015/01/05/11/02/wordpress-589121_1280.jpg"
    },
    {
        id: 6,
        time: "12:45 pm",
        title: "Charlie Brown liked your post",
        description: "Charlie Brown liked your post: 'Excited about my new project!'",
        posterImg: "https://cdn.pixabay.com/photo/2016/04/07/06/53/bmw-1313343_1280.jpg",
        channelLogo: "https://cdn.pixabay.com/photo/2015/01/05/11/02/wordpress-589121_1280.jpg"
    },
    {
        id: 7,
        time: "12:45 pm",
        title: "Charlie Brown liked your post",
        description: "Charlie Brown liked your post: 'Excited about my new project!'",
        posterImg: "https://cdn.pixabay.com/photo/2016/04/07/06/53/bmw-1313343_1280.jpg",
        channelLogo: "https://cdn.pixabay.com/photo/2015/01/05/11/02/wordpress-589121_1280.jpg"
    },
    {
        id: 8,
        time: "12:45 pm",
        title: "Charlie Brown liked your post",
        description: "Charlie Brown liked your post: 'Excited about my new project!'",
        posterImg: "https://cdn.pixabay.com/photo/2016/04/07/06/53/bmw-1313343_1280.jpg",
        channelLogo: "https://cdn.pixabay.com/photo/2015/01/05/11/02/wordpress-589121_1280.jpg"
    },
];

export const commentData = [
    {
        id: 1,
        name: "John Doe",
        image: "https://images.unsplash.com/photo-1534030347209-467a5b0ad3e6?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        message: "Had a great workout session today! Feeling energized and ready to take on the week. Looking forward to more progress and hitting my fitness goals.",
        daysAgo: "2"
    },
    {
        id: 2,
        name: "Jane Smith",
        image: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        message: "Enjoying the beautiful weather!",
        daysAgo: "3"
    },
    {
        id: 3,
        name: "Robert Brown",
        image: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        message: "Just finished reading an amazing book. It was such a captivating story with well-developed characters and a plot that kept me on the edge of my seat. Highly recommend it!",
        daysAgo: "5"
    },
    {
        id: 4,
        name: "Emily Johnson",
        image: "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        message: "Delicious homemade dinner tonight.",
        daysAgo: "1"
    },
    {
        id: 5,
        name: "Michael Williams",
        image: "https://images.unsplash.com/photo-1613592237018-bbf8973eef4f?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        message: "Went for a long walk in the park today. The fresh air and nature were so refreshing. I feel recharged and ready for the upcoming week.",
        daysAgo: "4"
    },
    {
        id: 6,
        name: "Sarah Davis",
        image: "https://images.unsplash.com/photo-1619451819631-2c93f8db4924?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        message: "Had a fantastic time with friends over the weekend!",
        daysAgo: "6"
    },
    {
        id: 7,
        name: "David Martinez",
        image: "https://plus.unsplash.com/premium_photo-1664199486587-37f325d15182?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        message: "Completed a challenging project at work. It was tough, but the satisfaction of finishing it successfully is unmatched.",
        daysAgo: "7"
    },
    {
        id: 8,
        name: "Sophia Hernandez",
        image: "https://images.unsplash.com/photo-1614204424926-196a80bf0be8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        message: "Spent the day at the beach. The sun, sand, and waves were exactly what I needed to relax and unwind. Can't wait to go back!",
        daysAgo: "8"
    }
];

export const likedVideoData = [
    {
        id: 1,
        title: "ABC World News Tonight with David Muir Full Broadcast - Aug. 12, 2024",
        channel: "ABC News",
        subscribers: "17.2M",
        views: "557K",
        uploaded: "1 day ago",
        thumbnail: "https://i.ytimg.com/vi/sDXwkLQpwGo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC8wizc3WVPRbkgbfGzHRKi0uBwnA",
    },
    {
        id: 2,
        title: "John King breaks down how the race has changed since Biden's exit",
        channel: "CNN",
        subscribers: "16.7M",
        views: "1.3M",
        uploaded: "6 hours ago",
        thumbnail: "https://i.ytimg.com/vi/a8tlPU9rZew/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAMQElKLlDaPkXLJXdlzZ8y6wgYvg",
    },
    {
        id: 3,
        title: "Project 2025 Exposed: The shocking plot to erase diversity in America",
        channel: "MSNBC",
        subscribers: "12.8M",
        views: "80k",
        uploaded: "13 hours ago",
        thumbnail: "https://i.ytimg.com/vi/CQWhp7DLCFM/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBk2j3GNEohMi7hrPu_1wOJVYvroQ",
    },
    {
        id: 4,
        title: "Good Morning America Top Stories – Tuesday, August 13, 2024",
        channel: "ABC News",
        subscribers: "5.9M",
        views: "65K",
        uploaded: "1 day ago",
        thumbnail: "https://i.ytimg.com/vi/pB17JIUCeh0/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDWKRLi5-HVVUy5nZFGmS0J31x_tQ",
    },
    {
        id: 5,
        title: "Harris surges in polls and Trump rambles with Musk. Where does Trump go from here? | Planet America",
        channel: "ABC News In-depth",
        subscribers: "1.69M",
        views: "206k",
        uploaded: "7 hours ago",
        thumbnail: "https://i.ytimg.com/vi/UkdzJLXvoxk/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBdjxYRhln_47kG35dwJQcPD72AWw",
    },
    {
        id: 6,
        title: "Harris leading in 3 key states, Elon Musk to interview Trump on X, more | America Decides",
        channel: "CBS News",
        subscribers: "850K",
        views: "18k",
        uploaded: "3 hours ago",
        thumbnail: "https://i.ytimg.com/vi/v_qHxYWAMYI/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCfNota3Us6KhwSCJbLG1fPyVDJ0w",
    },
    {
        id: 7,
        title: "Rivers of America and Tom Sawyer Island Closing Forever, MuppetVision is Safe",
        channel: "WDW News Today",
        subscribers: "1.2M",
        views: "39K",
        uploaded: "4 hours ago",
        thumbnail: "https://i.ytimg.com/vi/SqrTQriku4c/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAOD0ihfr1ZYw76HrVpRI9R51qk0w",
    },
];

export const trendingPageData = [
    {
        id: 1,
        title: "Dallas Cowboys vs. Los Angeles Rams | 2024 Preseason Week 1 Game Highlights",
        subscribers: "1.2M",
        views: "987K",
        days: "3 days ago",
        channelName: "NFL",
        thumbnail: "https://i.ytimg.com/vi/meOTx0YLY6o/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCvXvoV_4MQurEvEYQNThkKx9MMcw",
        channelLogo: "https://yt3.ggpht.com/kDUg480xOHHjIEnvADRDAz8w9MapJEx-3UJjJQzfeg4O-SrAGHTOSpd5Tm5QtJi0RlSfafMxQg=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 2,
        title: "Harris’ favorability skyrockets as 2024 race increasingly becomes ‘referendum on Trump’",
        subscribers: "5.9M",
        views: "334K",
        days: "2 days ago",
        channelName: "NBC News",
        thumbnail: "https://i.ytimg.com/vi/EZL8_a9voz0/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBFqprB-dEkHSophw47WoH7JIu9Wg",
        channelLogo: "https://yt3.ggpht.com/GjDLYFGF4IQaUobUK-6q3nOsU4o8fRMl4XgVipPWRqdRVt61s2LqgnbBXu3-qYL4Ab2xsfVo=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 3,
        title: "Houston Texans vs. Pittsburgh Steelers | 2024 Preseason Week 1 Game Highlights",
        subscribers: "1.2M",
        views: "962K",
        days: "5 days ago",
        channelName: "NFL",
        thumbnail: "https://i.ytimg.com/vi/1lvrPwENbzk/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBYiD7QDkSkvArTpLN7vzWCRFzCiQ",
        channelLogo: "https://yt3.ggpht.com/kDUg480xOHHjIEnvADRDAz8w9MapJEx-3UJjJQzfeg4O-SrAGHTOSpd5Tm5QtJi0RlSfafMxQg=s68-c-k-c0x00ffffff-no-rj",
    },
];

export const playlistData = [
    {
        id: 1,
        totalItems: 32,
        playListType: "Public",
        playListName: "Today News",
        updated: "Updated 3 days ago",
        playListCover: "https://i.ytimg.com/vi/nZ-sWeUjuxo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD4y5M7daXYlvz0uoXY7IqDtrrYbg",
    },
    {
        id: 2,
        totalItems: 18,
        playListType: "Private",
        playListName: "Bike Collection",
        updated: "Updated 10 days ago",
        playListCover: "https://images.unsplash.com/photo-1698695290237-5c7be2bd52a8?q=80&w=1772&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    }
];

export const searchlistData = [
    {
        id: 1,
        search: "upcoming concerts in New York 2024",
    },
    {
        id: 2,
        search: "best coffee shops in San Francisco",
    },
    {
        id: 3,
        search: "top hiking trails in Colorado",
    },
    {
        id: 4,
        search: "art galleries in Los Angeles",
    },
    {
        id: 5,
        search: "best brunch spots in Chicago",
    },
    {
        id: 6,
        search: "wine tasting tours in Napa Valley",
    },
    {
        id: 7,
        search: "ski resorts in the Rocky Mountains",
    },
    {
        id: 8,
        search: "theater shows in London 2024",
    },
    {
        id: 9,
        search: "luxury hotels in Paris",
    },
    {
        id: 10,
        search: "surfing spots in California",
    },
];

export const popularSearchlistData = [
    {
        id: 1,
        search: "current dollar exchange rate in New York",
        totalSearch: 2156,
        thumbnail: "https://images.unsplash.com/photo-1666223914734-e4b6dc2cfc20?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 2,
        search: "tomorrow's weather forecast for Los Angeles",
        totalSearch: 89512,
        thumbnail: "https://images.unsplash.com/photo-1561553590-267fc716698a?q=80&w=1792&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 3,
        search: "latest technology news and updates 2024",
        totalSearch: 158450,
        thumbnail: "https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
];

export const accordionData = [
    {
        id: 1,
        title: "Why can’t I log in to my account?",
        content: "There could be several reasons why you're unable to log in to your account. Please ensure your email and password are entered correctly. If you still can't log in, try resetting your password or contacting support."
    },
    {
        id: 2,
        title: "Why was my account disabled?",
        content: "Accounts may be disabled due to violations of terms and conditions or unusual activity. Contact support to find out more about why your account was disabled."
    },
    {
        id: 3,
        title: "How to activate a disabled account?",
        content: "To reactivate a disabled account, follow the instructions provided in the email sent to you or contact customer support for assistance."
    },
    {
        id: 4,
        title: "My email address is not recognized when I try to reset my password?",
        content: "If your email address is not recognized, make sure you are using the email associated with your account. You may also want to check for typos or try another email address."
    },
    {
        id: 5,
        title: "How do I reset my password?",
        content: "To reset your password, click on 'Forgot Password' on the login page, enter your email address, and follow the instructions in the email you receive."
    },
    {
        id: 6,
        title: "Why didn’t I receive a password reset email?",
        content: "If you didn't receive a password reset email, check your spam folder. Also, ensure that you entered the correct email address. If the problem persists, contact support."
    },
    {
        id: 7,
        title: "Why can’t I access my email address and I can’t log in?",
        content: "If you can't access your email and can't log in, you may need to update your account with a new email address or contact support for further assistance."
    },
    {
        id: 8,
        title: "I forgot my password, how do I log in?",
        content: "If you forgot your password, click on 'Forgot Password' on the login page, and follow the instructions to reset your password and regain access."
    },
    {
        id: 9,
        title: "How to reopen an account you closed?",
        content: "To reopen a closed account, log in with your credentials and follow the prompts to reactivate your account, or contact support if you need help."
    },
    {
        id: 10,
        title: "Why was my account permanently suspended?",
        content: "Permanent suspensions usually occur due to repeated violations of terms and conditions. Contact support to understand the specifics of your suspension."
    },
    {
        id: 11,
        title: "What are login alert notifications? And when are they sent?",
        content: "Login alert notifications inform you of suspicious login attempts. They are sent when there is a login from an unrecognized device or location."
    },
    {
        id: 12,
        title: "What do the login alert notifications say?",
        content: "Login alert notifications typically include information about the device and location where the login attempt was made and instructions on what to do if it wasn't you."
    },
    {
        id: 13,
        title: "Why can’t I activate my account?",
        content: "If you can't activate your account, ensure you've followed all the instructions in the activation email. If you still can't activate, contact support for assistance."
    },
    {
        id: 14,
        title: "What are the terms and conditions to create an account?",
        content: "The terms and conditions for creating an account include providing accurate information, agreeing to the privacy policy, and not engaging in prohibited activities."
    },
    {
        id: 15,
        title: "How to create multiple accounts?",
        content: "Creating multiple accounts may be allowed depending on the platform's policy. Follow the standard account creation process, ensuring each account has a unique email address."
    }
];

export const AboutAccordionData = [
    {
        id: 1,
        title: "KV policies on implementing things",
        content: "KV policies are designed to ensure that all implementations align with the company's core values and operational guidelines. Each step of implementation is thoroughly reviewed to maintain quality, security, and efficiency across all projects."
    },
    {
        id: 2,
        title: "KV Safety measures",
        content: "KV is committed to providing a safe environment for all users. Our safety measures include regular system audits, data encryption, and strict access controls to protect user information and prevent unauthorized access."
    },
    {
        id: 3,
        title: "Terms and conditions to follow",
        content: "Users must adhere to the terms and conditions set forth by KV, which include respecting intellectual property rights, avoiding the dissemination of harmful content, and maintaining ethical behavior while using our services."
    },
    {
        id: 4,
        title: "User success stories on gaining money",
        content: "Many KV users have shared success stories about how they have gained financial benefits by leveraging the platform. These stories highlight how consistent effort, creative content, and effective monetization strategies can lead to significant earnings."
    },
    {
        id: 5,
        title: "Uploading content monetization",
        content: "KV offers multiple ways to monetize uploaded content, including ad revenue sharing, sponsorships, and premium content subscriptions. Users are encouraged to explore these opportunities to maximize their earning potential."
    },
    {
        id: 6,
        title: "Equal measures to every individual",
        content: "KV is dedicated to ensuring that all users are treated equally. Our policies and algorithms are designed to provide equal opportunities for exposure, monetization, and support, regardless of the user's background or location."
    }
];

export const subscribedData = [
    {
        id: 1,
        channelName: "ABC News",
        channelLogo: "https://yt3.ggpht.com/OOT0eq8LoW8V3PMURGYuk0kRSgRGP7EsuGZoODhHBcg1jCB1qkuIAxS-XGnjKX9Q6rf-WKTvkQ=s176-c-k-c0x00ffffff-no-rj-mo",
    },
    {
        id: 2,
        channelName: "USA TODAY",
        channelLogo: "https://yt3.googleusercontent.com/ytc/AIdro_mpuS6J3PwGJatG2czLGfmZHyE_kNicqukg0jBnCbENmiJl=s160-c-k-c0x00ffffff-no-rj"
    },
    {
        id: 3,
        channelName: "MSNBC",
        channelLogo: "https://yt3.googleusercontent.com/cexkusY_WDfoU04VaLRfZkPpwaFsopQ43v_g-6Na1U2eO-HV6J1pe3iEx5BI59tqqZou_zOeQw=s160-c-k-c0x00ffffff-no-rj"
    },
    {
        id: 4,
        channelName: "LiveNOW from FOX",
        channelLogo: "https://yt3.googleusercontent.com/dF1ZRcwWOxfn3AysqfW-MMIod2jPfCfgFkMLYiQWGMOiELVA7-nWN7PaXnEv1RfVxRe5S9z36ow=s160-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 5,
        channelName: "DW News",
        channelLogo: "https://yt3.googleusercontent.com/w5ls-zGHOJYlOOYIi6K_9Hjjbh2XGoM2SYFvL8KuOrk8BJ8G_XFW8d2lYjjd4Gq9INwsARLk0Q=s160-c-k-c0x00ffffff-no-rj",
    },
];

export const padcastData = [
    {
        id: 1,
        title: "The FULL Cristiano Ronaldo Interview With Piers Morgan | Parts 1 and 2",
        channel: "Piers Morgan Uncensored",
        followers: "3.19M",
        type: "podcast",
        views: "6.8M",
        days: "1 hours ago",
        thumbnail: "https://i.ytimg.com/vi/Jk9uJRMvBIA/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAXx5Q7hx88wDms-NqC7AWtODxb_A",
        video: "https://cdn.pixabay.com/video/2017/07/23/10814-226624958_large.mp4",
        channelLogo: "https://yt3.googleusercontent.com/NpBpB2j3PrmMiYVQAXyU7JWcHmQWjT1wN0x1cxiacBgw7n-OuXgtcnGI9aBUR7BLRX4BwTdkzA=s160-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 2,
        title: "Taylor Swift & Martin McDonagh | Directors on Directors",
        channel: "Variety",
        followers: "1.49M",
        type: "podcast",
        views: "2.2M",
        days: "10 hours ago",
        thumbnail: "https://i.ytimg.com/vi/x8zfsf4azLo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD41K9mK95faQq0vGZeOM1D-YNWPA",
        video: "https://cdn.pixabay.com/video/2024/07/25/223067_large.mp4",
        channelLogo: "https://yt3.ggpht.com/ytc/AIdro_lUeys_Ht_DIAe_8BwFUqST-fU7kvBdoh5R7oxBlwzJX8Y=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 3,
        title: "Tom Cruise on Doing Incredibly Dangerous Stunts, Mission Impossible & Top Gun with Val Kilmer",
        channel: "immy Kimmel Live",
        followers: "19.5M",
        type: "podcast",
        views: "4M",
        days: "23 hours ago",
        thumbnail: "https://i.ytimg.com/vi/KA3KDbU5dqk/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDUDS52lPC-pioI6NswrTFpXirghA",
        video: "https://cdn.pixabay.com/video/2023/10/02/183229-870457423_large.mp4",
        channelLogo: "https://yt3.ggpht.com/ytc/AIdro_ni-o03fIMm59CNpCt9fxuL1G4u-ZPwffhAxLxL-4AlHVGX=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 4,
        title: "Chris Hemsworth and Scarlett Johansson Insult Each Other | CONTAINS STRONG LANGUAGE!",
        channel: "BBC Radio 1",
        followers: "8.28M",
        type: "podcast",
        views: "35M",
        days: "2 day ago",
        thumbnail: "https://i.ytimg.com/vi/3jCsZBEuvvw/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDWrhs0UOCENQI7HqNIWrx6swYE_A",
        video: "https://cdn.pixabay.com/video/2017/04/02/8559-211218179_large.mp4",
        channelLogo: "https://yt3.ggpht.com/_XWt3tXQZlU-vWTQD1fyG3nlRubLHd8ttkmvW9U4_RH9z3hqHnPnUyRt_1oXyPOJFGfpDqfb=s68-c-k-c0x00ffffff-no-rj",
    }
];

export const livestreamData = [
    {
        id: 1,
        title: "LIVE: San Francisco 49ers vs. Las Vegas Raiders | 2024 Preseason Week 3 Game Highlights",
        channel: "NFL",
        followers: "13M",
        type: "livestream",
        views: "68K",
        days: "5 hours ago",
        thumbnail: "https://i.ytimg.com/vi/wpPwsQ8nqBU/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBSUwkF20woVRLayXjn6RPYrW4LtA",
        video: "https://cdn.pixabay.com/video/2017/07/23/10814-226624958_large.mp4",
        channelLogo: "https://yt3.ggpht.com/kDUg480xOHHjIEnvADRDAz8w9MapJEx-3UJjJQzfeg4O-SrAGHTOSpd5Tm5QtJi0RlSfafMxQg=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 2,
        title: "LIVE: Latest News Headlines and Events l ABC News Live",
        channel: "ABC News",
        followers: "1.58M",
        type: "livestream",
        views: "895K",
        days: "20 hours ago",
        thumbnail: "https://i.ytimg.com/vi/-mvUkiILTqI/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAiRFg-WWQxvz7Q1PnC0lx5LggjCA",
        video: "https://cdn.pixabay.com/video/2017/07/23/10814-226624958_large.mp4",
        channelLogo: "https://yt3.ggpht.com/OOT0eq8LoW8V3PMURGYuk0kRSgRGP7EsuGZoODhHBcg1jCB1qkuIAxS-XGnjKX9Q6rf-WKTvkQ=s68-c-k-c0x00ffffff-no-rj",
    },
    {
        id: 3,
        title: "LIVE: ABC7 New York - Eyewitness News and more!",
        channel: "Eyewitness News ABC7NY",
        followers: "3.23M",
        type: "livestream",
        views: "12K",
        days: "23 hours ago",
        thumbnail: "https://i.ytimg.com/vi/oFOTr81KHLg/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDeBwlWNj2OFKdmpVocRpBG7ltbzg",
        video: "https://cdn.pixabay.com/video/2017/07/23/10814-226624958_large.mp4",
        channelLogo: "https://yt3.ggpht.com/ytc/AIdro_k1_mRYQG-HAspwbSjYcshWT6yECtfxdmfmCfIchUZsWHB9=s68-c-k-c0x00ffffff-no-rj",
    }
];

export const subscribeData = [
    {
        id: 1,
        channelName: "Chris Hemsworth",
        channelAbout: "The Official YouTube channel for Chris Hemsworth and Wild State",
        channelImage: "https://yt3.googleusercontent.com/GG8Z-YOh1IEfzsqbFx2NhWt8hd1UJ-IHLvOedVzVRXFPtJ9JtW6vEborftrdQ0L8oLlYcBXCzg=s160-c-k-c0x00ffffff-no-rj",
        latestVideos: [
            {
                id: 1,
                title: "Chris Hemsworth Does ASMR | Extraction 2",
                thumbnail: "https://i.ytimg.com/vi/wWk5kfJqFp0/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCbNIDp96ZB8uWNU7V1iW19__GnHA"
            },
            {
                id: 2,
                title: "Extraction | Official Trailer",
                thumbnail: "https://i.ytimg.com/vi/fdmgGFw1SfU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA5Z5K-rhPQs2_BsLW8N7h92XS9aw"
            },
            {
                id: 3,
                title: "Limitless with Chris Hemsworth | BTS Aftershow",
                thumbnail: "https://i.ytimg.com/vi/MFrPvn45kBU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAK1hJO_k_JIhcV2964gkdbFMvb4Q"
            }
        ]
    },
    {
        id: 2,
        channelName: "Matt Wolfe",
        channelAbout: "AI, No-Code, Tech, Futurism - I'm a tech nerd and talk about tech nerd stuff",
        channelImage: "https://yt3.googleusercontent.com/po8MdRNWZmm3xxFL8Iup_FPFXgzzsi_fAzSiGC3RVq0_pYsBV0Oe9jGj37t0yostfFYF3Qe04w=s160-c-k-c0x00ffffff-no-rj",
        latestVideos: [
            {
                id: 1,
                title: "AI News: OpenAI's Next Model Revealed!",
                thumbnail: "https://i.ytimg.com/vi/pt4kuzcFPPk/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDv-E1gNC-4xYCoEApJqAvgeOlPWQ"
            },
            {
                id: 2,
                title: "AI News: We've Entered A New Era of AI Creativity",
                thumbnail: "https://i.ytimg.com/vi/Rfws9ZMmkJk/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD_6cA_6juHv-bCP2e9JBFYSj6n7A"
            },
            {
                id: 3,
                title: "How To Make AI Images Of Yourself (Free)",
                thumbnail: "https://i.ytimg.com/vi/_rjto4ix3rA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAb-xNfED-UFrJEfYU6KY7_OAAbOg"
            }
        ]
    },
    {
        id: 3,
        channelName: "Champs Network",
        channelAbout: "Heyyy my name is Champ, I make the Newest, Classic, and Most Anticipated Upcoming Gameplay, Full Game Walkthroughs, Boss Fights, and Highlights with no commentary from Both Console and PC at the Highest Quality possible 4K HDR.",
        channelImage: "https://yt3.googleusercontent.com/qMalgdcBIJ6ii5v6pP1yXNOTKlm_H3ae-mEHPmpZyGv4dV0hCGnU2_TDQ7f_YjrfInUZdgI2=s160-c-k-c0x00ffffff-no-rj",
        latestVideos: [
            {
                id: 1,
                title: "(PS5) MOST WANTED | IMMERSIVE ULTRA Realistic Graphics Gameplay [4K 60FPS HDR] Call of Duty",
                thumbnail: "https://i.ytimg.com/vi/_siR9x9BChM/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBZQkUO0TselotQO3eu_tK8eJYfHA"
            },
            {
                id: 2,
                title: "TOP 15 NEW MOST REALISTIC ULTRA Graphics Upcoming Games of 2024 and 2025 (PS5, XBOX SERIES X/S, PC)",
                thumbnail: "https://i.ytimg.com/vi/cUstnRxzCqw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDuDSDjnCDM9MEcE3qNqfM8Ye8ACw"
            },
            {
                id: 3,
                title: "(PS5) Ghost Recon Breakpoint | Realistic ULTRA STEALTH Graphics Gameplay [4K 60FPS HDR]",
                thumbnail: "https://i.ytimg.com/vi/5g67-QpOYPU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBmHHlXgJQdOjrBKUgupgabPSON0Q"
            }
        ]
    },
    {
        id: 4,
        channelName: "ABC News In-depth",
        channelAbout: "Welcome to ABC News In-depth, where you'll find our long-form journalism and explainers to help you understand what's going on in the world around you.",
        channelImage: "https://yt3.googleusercontent.com/Z8t36Oh9t8MXUpgb9w_YxoPHGJ6T6lX3kxEjDRuk7Pafk0KISqtVY1Pqj_kScxmGE8xow_dub3w=s160-c-k-c0x00ffffff-no-rj",
        latestVideos: [
            {
                id: 1,
                title: "Telegram CEO Pavel Durov faces charges relating to illegal activity on his app | 7.30",
                thumbnail: "https://i.ytimg.com/vi/hVea5xnZW-A/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC_b6efmX9ZGDwc6X8pCkU-Ax1eAg"
            },
            {
                id: 2,
                title: "Internal emails reveal Liberal Party scramble before local council debacle | 7.30",
                thumbnail: "https://i.ytimg.com/vi/F7LN_hIPVls/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCN5h8JpLxVEDRyiRwKKaP2AcAeaA"
            },
            {
                id: 3,
                title: "Governments Messy Week  | Insiders | ABC News",
                thumbnail: "https://i.ytimg.com/vi/nKCmrRIIT6o/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCzFXml3V0ohLfGTxOYKAZ5rbeSnQ"
            }
        ]
    }
];

export const musicData = [
    {
        id: 1,
        channel: "Justin Bieber",
        totalVideos: 150,
        totalClips: 320,
        title: "Justin Bieber - Peaches ft. Daniel Caesar, Giveon",
        posterURL: "https://i.ytimg.com/vi/tQ0yjYUFKAE/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA9_5ztUTCQDlmjYLOkXhHG-yt8sg",
    },
    {
        id: 2,
        channel: "Marshmello",
        totalVideos: 152,
        totalClips: 105,
        title: "Marshmello - Alone (Official Music Video)",
        posterURL: "https://i.ytimg.com/vi/ALZHF5UqnU4/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBljOJruESu8EkRCxpRogNby-K8Vg",
    },
    {
        id: 3,
        channel: "Martin Garrix",
        totalVideos: 452,
        totalClips: 752,
        title: "Martin Garrix & Bebe Rexha - In The Name Of Love (Official Video)",
        posterURL: "https://i.ytimg.com/vi/RnBT9uUYb1w/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAN4l4BifjY0LaaagJUvsIfds1iKg",
    },
    {
        id: 4,
        channel: "NoCopyrightSounds",
        totalVideos: 630,
        totalClips: 982,
        title: "Janji - Heroes Tonight (feat. Johnning) | Progressive House | NCS - Copyright Free Music",
        posterURL: "https://i.ytimg.com/vi/3nQNiWdeH2Q/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCz5IZe3m6L8905jXseezepL0YAzA",
    },
    {
        id: 5,
        channel: "Deep Board",
        totalVideos: 269,
        totalClips: 845,
        title: "Alan Walker, Dua Lipa, Coldplay, Martin Garrix & Kygo, The Chainsmokers Style 🔥 Summer Vibes",
        posterURL: "https://i.ytimg.com/vi/dIQq3AZkQqg/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBdMyJe8xrW_UFlxJvdeNMP4e9V5Q",
    }
];