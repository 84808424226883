import React from "react";
import { CiStreamOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMusicalNotes } from "react-icons/io5";
// import { BsMusicNoteList } from "react-icons/bs";
import { MdWorkspacePremium } from "react-icons/md";
import { FaPodcast, FaRegNewspaper, FaUserCheck, FaRegCalendarAlt } from "react-icons/fa";

const SecondMenu = () => {
    const navigate = useNavigate();

    return (
        <div className="bg-black flex items-center justify-center py-0">
            <div className="inline-flex items-center justify-center gap-4 border border-gray-500 bg-white text-[#6d6d6d] rounded-full px-3 py-1">
                <MdWorkspacePremium
                    onClick={() => navigate("/sponsore")}
                    className="h-4 w-4 cursor-pointer"
                />
                <FaPodcast
                    onClick={() => navigate("/podcasts")}
                    className="h-4 w-4 cursor-pointer"
                />
                <FaRegNewspaper
                    onClick={() => navigate("/live/news")}
                    className="h-4 w-4 cursor-pointer"
                />
                <CiStreamOn
                    onClick={() => navigate("/livestream")}
                    className="h-4 w-4 cursor-pointer"
                />
                <IoMusicalNotes
                    // onClick={() => navigate("/music")}
                    className="h-4 w-4 cursor-pointer"
                />
                <FaUserCheck
                    onClick={() => navigate("/subscribed")}
                    className="h-4 w-4 cursor-pointer"
                />
                <FaRegCalendarAlt
                    // onClick={() => navigate("/calendar")}
                    className="h-4 w-4 cursor-pointer"
                />
                {/* <BsMusicNoteList
                    // onClick={() => navigate("/slides")}
                    className="h-4 w-4 cursor-pointer"
                /> */}
            </div>
        </div>
    )
}

export default SecondMenu