import React, { Fragment } from "react"
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";

const CreatePostPreview = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / create post details / V1 / 13 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>
            <div className="grid grid-cols-12 items-center px-2 bg-white">
                <div className="col-span-2 flex items-center justify-start">
                    <h1
                        onClick={() => navigate("/home")}
                        className="text-[1.6rem] font-bold uppercase text-black cursor-pointer"
                    >
                        kv
                    </h1>
                </div>
                <div className="col-span-8 flex items-center justify-center">
                    <p className="text-black text-[16px] font-medium">Create Post</p>
                </div>
            </div>
            <div className="px-2 pt-2">
                <IoArrowBackCircle
                    onClick={() => navigate(-1)}
                    className="text-[22px] cursor-pointer text-white"
                />
            </div>
            <div className="px-2 pt-2">
                <img
                    alt="preview"
                    className="w-full h-[25vh] object-cover rounded mb-2"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                />
                <div className="grid grid-cols-12 mb-1">
                    <div className="col-span-3">
                        <button className="py-[2px] w-full bg-white font-semibold text-[12px] rounded-[5px]">
                            Caption
                        </button>
                    </div>
                    <div className="col-span-8 ml-[8px]">
                        <p className="text-white text-[12px] leading-[14px] mt-[7px]">
                            Hey guys going live soon
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-12 mb-1">
                    <div className="col-span-3">
                        <button className="py-[2px] w-full bg-white font-semibold text-[12px] rounded-[5px]">
                            View
                        </button>
                    </div>
                    <div className="col-span-8 ml-[8px]">
                        <p className="text-white text-[12px] leading-[14px] mt-[7px]">
                            Public
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-12 mb-1">
                    <div className="col-span-3">
                        <button className="py-[2px] w-full bg-white font-semibold text-[12px] rounded-[5px]">
                            Music
                        </button>
                    </div>
                    <div className="col-span-8 ml-[8px]">
                        <p className="text-white text-[12px] leading-[14px] mt-[7px]">
                            Eminem - Lose Yourself
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-12 mb-1">
                    <div className="col-span-3">
                        <button className="py-[2px] w-full bg-white font-semibold text-[12px] rounded-[5px]">
                            Mention
                        </button>
                    </div>
                    <div className="col-span-8 ml-[8px]">
                        <p className="text-white text-[12px] leading-[14px] mt-[7px]">
                            @john_doe
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-12 mb-5">
                    <div className="col-span-3">
                        <button className="py-[2px] w-full bg-white font-semibold text-[12px] rounded-[5px]">
                            Location
                        </button>
                    </div>
                    <div className="col-span-8 ml-[8px]">
                        <p className="text-white text-[12px] leading-[14px] mt-[7px]">
                            California, USA
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-12 mb-1">
                    <div className="col-span-6">
                        <button className="py-[2px] px-2 bg-white font-semibold text-[12px] rounded-[5px]">
                            Advance Settings
                        </button>
                    </div>
                    <div className="col-span-6 flex justify-end">
                        <button className="py-[2px] px-[15px] bg-white font-semibold text-[12px] rounded-[5px] mt-10">
                            Post
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CreatePostPreview
