import React from "react";
import { CiSearch } from "react-icons/ci";
import { trendingPageData } from "../data";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { HiOutlineDotsVertical } from "react-icons/hi";
import fireLogo from "../assets/footer/fire_footer_icon.png";

const TrendingPage = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Trending / V1 / 14 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e] mb-2">
                <div className="col-span-8 flex items-center justify-start gap-3">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate(-1)} />
                    <div className="flex items-center gap-1">
                        <img alt="trending" src={fireLogo} className="h-6 w-6" />
                        <p className="text-white text-[16px] font-medium">Top Trending</p>
                    </div>
                </div>
                <div className="col-span-4 flex items-center justify-end gap-3 ">
                    <CiSearch className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate("/search")} />
                    <HiOutlineDotsVertical className="text-white h-5 w-5 cursor-pointer mr-[-8px]" />
                </div>
            </div>

            {/* Trending Page Content */}
            {trendingPageData.map((data) => (
                <div className="px-2 pb-2" key={data.id}>
                    <img
                        alt="thumbnail"
                        src={data.thumbnail}
                        className="w-full h-[10rem] object-cover rounded-sm cursor-pointer"
                    />
                    <div className="grid grid-cols-12 mt-1">
                        <div className="col-span-2">
                            <img
                                alt="profile"
                                className="h-8 w-8 rounded-full object-cover cursor-pointer"
                                src={data.channelLogo}
                            />
                            <div className="flex flex-col items-center justify-start ml-[-12px]">
                                <p className="text-white text-[13px]">{data.views}</p>
                                <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                            </div>
                        </div>
                        <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                            <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                            <p className="text-[#8d8d8d] text-[12px]">{data.channelName} - {data.days}</p>
                        </div>
                        <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                            <p className="text-white text-[14px] cursor-pointer">: :</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TrendingPage