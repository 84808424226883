import React, { Fragment, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { accordionData } from "../../data";
import { HiMiniBars3 } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack, IoMdClose } from "react-icons/io";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

const HelpPage = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [openFeedback, setOpenFeedback] = useState(false);
    const [openDisklikeFeedback, setOpenDisklikeFeedback] = useState({});
    const [isLiked, setIsLiked] = useState({});
    const [mainMenuPopup, setMainMenuPopup] = useState(false);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    const handleDislikeClick = (id) => {
        setOpenDisklikeFeedback((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleLikeClick = (id) => {
        setIsLiked((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const filteredData = accordionData.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Help / V1 / 20 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer ml-[-3px]" onClick={() => navigate(-1)} />
                    <p className="text-white text-[16px] font-medium">Help</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 relative">
                    {mainMenuPopup ?
                        <IoMdClose className="text-white h-5 w-5 cursor-pointer mr-[-1px]" onClick={() => setMainMenuPopup(!mainMenuPopup)} /> :
                        <HiMiniBars3 className="text-white h-5 w-5 cursor-pointer mr-[-1px]" onClick={() => setMainMenuPopup(!mainMenuPopup)} />
                    }

                    {mainMenuPopup &&
                        <div className="absolute top-8 w-[10rem] right-[-4px] bg-white z-50 rounded">
                            <p className="text-[11px] px-2 py-[5px] cursor-pointer hover:text-[#f75555] border-b-[0.2px] border-[#b9b9b9]">
                                Getting Started
                            </p>
                            <p className="text-[11px] px-2 py-[5px] cursor-pointer hover:text-[#f75555] border-b-[0.2px] border-[#b9b9b9]">
                                Manage your account
                            </p>
                            <p className="text-[11px] px-2 py-[5px] cursor-pointer hover:text-[#f75555] border-b-[0.2px] border-[#b9b9b9]">
                                Account Privacy
                            </p>
                            <p className="text-[11px] px-2 py-[5px] cursor-pointer hover:text-[#f75555] border-b-[0.2px] border-[#b9b9b9]">
                                Policies and Reporting
                            </p>
                            <p className="text-[11px] px-2 py-[5px] cursor-pointer hover:text-[#f75555] border-b-[0.2px] border-[#b9b9b9]">
                                Advertisement
                            </p>
                            <p className="text-[11px] px-2 py-[5px] cursor-pointer hover:text-[#f75555]">
                                Frequently asked questions
                            </p>
                        </div>
                    }
                </div>
            </div>

            <div className="px-2">
                <input
                    type="text"
                    placeholder="Search help"
                    className="px-2 py-[4px] text-[14px] w-full mt-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 placeholder:text-[14px]"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <div className="px-2 mt-3">
                {filteredData.length > 0 ? (
                    filteredData.map((item) => (
                        <Accordion
                            key={item.id}
                            open={open === item.id}
                            className="mb-2 rounded-lg border border-blue-gray-100 px-4"
                        >
                            <AccordionHeader
                                onClick={() => handleOpen(item.id)}
                                className={`border-b-0 transition-colors text-[13px] text-[#dddddd] hover:text-[#cacaca] ${open === item.id ? "text-white hover:!text-[#e5e5e5]" : ""}`}
                            >
                                {item.title}
                            </AccordionHeader>

                            <AccordionBody className={`pt-0 mt-[-4px] ${openDisklikeFeedback[item.id] ? "h-[20vh]" : ""} text-base font-normal text-[12px] relative`}>
                                {item.content}
                                <div className="flex flex-col items-center justify-center gap-y-1 mt-2">
                                    <p className="text-[12px] text-gray-300">Did you find this helpful?</p>
                                    <div className="flex items-center justify-center gap-2">
                                        <div
                                            onClick={() => handleLikeClick(item.id)}
                                            className={`${isLiked[item.id] ? "bg-green-600 hover:bg-green-500" : "bg-white"} rounded-full p-1 cursor-pointer`}
                                        >
                                            <AiFillLike className={`${isLiked[item.id] ? "text-white" : "text-green-600"}`} />
                                        </div>
                                        <div className="bg-white rounded-full p-1 cursor-pointer" onClick={() => handleDislikeClick(item.id)}>
                                            <AiFillDislike className="text-red-600" />
                                        </div>
                                    </div>
                                </div>

                                {openDisklikeFeedback[item.id] &&
                                    <div className="absolute top-1 right-0 bg-white w-[80%] p-2 rounded">
                                        <p className="text-[9px] font-bold text-black">Add your review</p>
                                        <p className="text-[7px] text-black leading-[8px] mt-1">
                                            We will fix our article according to your suggestion.
                                            Please select from the options or write your suggestions.
                                        </p>
                                        <select className="border border-[#bebebe] rounded text-black text-[10px] w-full py-[2px] my-1 outline-none">
                                            <option value="1">Select</option>
                                            <option value="2">Data is not clear</option>
                                        </select>
                                        <textarea
                                            type="text"
                                            rows={2}
                                            placeholder="Type your feedback"
                                            className="w-full mt-[2px] px-1 py-[2px] resize-none text-black placeholder:text-[10px] text-[10px] border border-[#bebebe] rounded bg-[#ffffff] focus:outline-none"
                                        />
                                        <div className="flex items-center justify-evenly">
                                            <button className="text-[8px] w-10 border border-gray-500 rounded py-[2px]">
                                                Send
                                            </button>
                                            <button
                                                onClick={() => handleDislikeClick(item.id)}
                                                className="text-[8px] w-10 border-none bg-[#0DD315] text-white rounded py-[2px]"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                }
                            </AccordionBody>
                        </Accordion>
                    ))
                ) : (
                    <p className="text-center text-[14px] text-gray-500">No help topics found.</p>
                )}
            </div>

            <div className="my-4 px-2">
                <p className="text-[14px] text-white">
                    Give Feedback
                </p>

                {!openFeedback &&
                    <div
                        onClick={() => setOpenFeedback(true)}
                        className="bg-white py-[6px] px-1 mt-1 flex items-center justify-end rounded"
                    >
                        <IoMdSend className="text-[18px] cursor-pointer" />
                    </div>
                }

                {openFeedback &&
                    <div className="bg-white p-2 mt-2">
                        <textarea
                            type="text"
                            rows={5}
                            placeholder="Type your feedback"
                            className="w-full mt-[2px] px-2 py-2 resize-none text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                        />
                        <div className="flex items-center justify-between">
                            <button className="w-[8rem] bg-[#474747] rounded text-white text-[12px] py-1">
                                Upload Screenshot
                            </button>
                            <button className="w-[8rem] bg-[#474747] rounded text-white text-[12px] py-1" onClick={() => setOpenFeedback(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    );
};

export default HelpPage;
