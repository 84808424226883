import React, { Fragment, useEffect, useRef, useState } from "react";
import { BiText } from "react-icons/bi";
import { IoMdFlash, IoMdFlashOff } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { MdFlipCameraAndroid, MdAddPhotoAlternate, MdMovieFilter } from "react-icons/md";
import CreatePostPreview from "./CreatePostPreview";

const CreatePost = () => {
    const videoRef = useRef(null);
    const navigate = useNavigate();
    const [isFlashLight, setIsFlashLight] = useState(true);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                console.log("Stream: ", stream);

                if (videoRef.current) {
                    videoRef.current.srcObject = stream;

                    videoRef.current.onloadedmetadata = () => {
                        console.log("Metadata loaded, playing video...");
                        videoRef.current.play().catch(error => console.error("Error playing the video: ", error));
                    };
                } else {
                    console.error("Video element not found!");
                }
            } catch (err) {
                console.error("Error accessing the camera: ", err);
            }
        };

        startCamera();

        const videoElement = videoRef.current;

        return () => {
            if (videoElement && videoElement.srcObject) {
                const stream = videoElement.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, []);

    if (showPreview) {
        return <CreatePostPreview />;
    }

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / create post / V1 / 12 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="absolute w-full top-6 flex items-start justify-between px-2 z-10">
                <div className="flex flex-col">
                    <h1
                        onClick={() => navigate("/home")}
                        className="text-[1.6rem] font-bold uppercase text-white cursor-pointer mb-2"
                    >
                        kv
                    </h1>
                    <IoArrowBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[22px] cursor-pointer text-white"
                    />
                </div>

                <div className="flex flex-col mt-[12px]">
                    <MdFlipCameraAndroid className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer mb-4" />
                    {isFlashLight ?
                        <IoMdFlash
                            onClick={() => setIsFlashLight(!isFlashLight)}
                            className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer mb-4"
                        />
                        :
                        <IoMdFlashOff
                            onClick={() => setIsFlashLight(!isFlashLight)}
                            className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer mb-4"
                        />
                    }
                    <BiText className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                </div>
            </div>

            <div className="w-full h-screen md:h-[72.5vh] relative">
                <video
                    muted
                    autoPlay
                    ref={videoRef}
                    disablePictureInPicture
                    className="object-cover h-full bg-black"
                />

                <div className="absolute bottom-2 w-full grid grid-cols-12 px-2 z-10">
                    <div className="col-span-2 flex items-center justify-start">
                        <MdAddPhotoAlternate
                            className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center relative">
                        <div
                            className="h-10 w-10 rounded-full border-[2px] cursor-pointer border-white bg-red-600 hover:bg-[#c73737]"
                        />
                        <MdMovieFilter className="absolute right-8 text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                    </div>
                    <div className="col-span-2 flex items-center justify-end">
                        <button
                            className="text-[12px] bg-white hover:text-green-500 px-2 py-[2px] rounded-full font-semibold"
                            onClick={() => setShowPreview(true)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CreatePost;