import React, { Fragment, useState } from "react";
import { commentData } from "../data";
import { useSwipeable } from "react-swipeable";
import { SocialIcon } from "react-social-icons";
import { IoClose, IoSend } from "react-icons/io5";
import { FaShareAlt, FaComment } from "react-icons/fa";
import { useSpring, animated } from "@react-spring/web";
import { IoCaretBackCircle } from "react-icons/io5";
import { MdOutlineBookmark, MdReport } from "react-icons/md";
import { BsFillHandThumbsUpFill, BsFillHandThumbsDownFill } from "react-icons/bs";

const ViewLiveNews = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [swipeDirection, setSwipeDirection] = useState("");
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [openReplyId, setOpenReplyId] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);

    // visibility
    const [commentVisible, setCommentVisible] = useState(false);
    const [subscriberVisible, setSubscriberVisible] = useState(true);
    const [controlVisible, setControlVisible] = useState(true);
    const [videoDetailsVisible, setVideoDetailsVisible] = useState(false);
    const [shareVisible, setShareVisible] = useState(false);
    const [reportVisible, setReportVisible] = useState(false);

    const [comment, setComment] = useState("");

    const [likes, setLikes] = useState(350);
    const [dislikes, setDislikes] = useState(140);
    const [userLiked, setUserLiked] = useState(false);
    const [userDisliked, setUserDisliked] = useState(false);

    const [bookmarks, setBookmarks] = useState(190);
    const [isBookmarked, setIsBookmarked] = useState(false);

    const handleLike = () => {
        if (userLiked) {
            setLikes(likes - 1);
            setUserLiked(false);
        } else {
            setLikes(likes + 1);
            if (userDisliked) {
                setDislikes(dislikes - 1);
                setUserDisliked(false);
            }
            setUserLiked(true);
        }
    };

    const handleDislike = () => {
        if (userDisliked) {
            setDislikes(dislikes - 1);
            setUserDisliked(false);
        } else {
            setDislikes(dislikes + 1);
            if (userLiked) {
                setLikes(likes - 1);
                setUserLiked(false);
            }
            setUserDisliked(true);
        }
    };

    const handleBookmark = () => {
        if (isBookmarked) {
            setBookmarks(bookmarks - 1);
        } else {
            setBookmarks(bookmarks + 1);
        }
        setIsBookmarked(!isBookmarked); // Toggle the state
    };

    const videoData = [
        {
            id: 1,
            likes: 350,
            dislikes: 140,
            comment: 190,
            bookmarks: 168,
            views: 7900,
            subscriber: "580K",
            uploaded: "16 Aug 2024",
            channelName: "ABC News",
            title: "Capturing the Lively Streets of Times Square as People Walk By",
            channelLogo: "https://yt3.ggpht.com/OOT0eq8LoW8V3PMURGYuk0kRSgRGP7EsuGZoODhHBcg1jCB1qkuIAxS-XGnjKX9Q6rf-WKTvkQ=s176-c-k-c0x00ffffff-no-rj-mo",
            url: "https://cdn.pixabay.com/video/2022/06/24/121994-724732238_large.mp4",
        },
        {
            id: 2,
            title: "Documenting the Ever-Present Flow of People in Times Square",
            url: "https://cdn.pixabay.com/video/2022/07/02/122848-726398081_large.mp4",
            likes: 450,
            dislikes: 120,
            comment: 210,
            bookmarks: 185,
            views: 18300,
            subscriber: "9.42M",
            uploaded: "18 Aug 2024",
            channelName: "USA TODAY",
            channelLogo: "https://yt3.googleusercontent.com/ytc/AIdro_mpuS6J3PwGJatG2czLGfmZHyE_kNicqukg0jBnCbENmiJl=s160-c-k-c0x00ffffff-no-rj"
        },
        {
            id: 3,
            title: "Exploring the Diverse Stories and Movements at American Railway Stations",
            url: "https://cdn.pixabay.com/video/2022/06/01/118867-716625945_large.mp4",
            likes: 500,
            dislikes: 100,
            comment: 225,
            bookmarks: 200,
            views: 9000,
            subscriber: "2.3M",
            uploaded: "20 Aug 2024",
            channelName: "MSNBC",
            channelLogo: "https://yt3.googleusercontent.com/cexkusY_WDfoU04VaLRfZkPpwaFsopQ43v_g-6Na1U2eO-HV6J1pe3iEx5BI59tqqZou_zOeQw=s160-c-k-c0x00ffffff-no-rj"
        }
    ];

    const handlers = useSwipeable({
        onSwipedUp: () => {
            if (currentIndex < videoData.length - 1) {
                setSwipeDirection("up");
                setIsTransitioning(true);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }
        },
        onSwipedDown: () => {
            if (currentIndex > 0) {
                setSwipeDirection("down");
                setIsTransitioning(true);
                setCurrentIndex((prevIndex) => prevIndex - 1);
            }
        },
        preventScrollOnSwipe: true,
        trackTouch: true,
        trackMouse: true,
    });

    const springProps = useSpring({
        transform: swipeDirection === "up"
            ? "translateY(-100%)"
            : swipeDirection === "down"
                ? "translateY(100%)"
                : "translateY(0%)",
        opacity: isTransitioning ? 0 : 1,
        from: { transform: "translateY(0%)", opacity: 1 },
        reset: true,
        config: { tension: 170, friction: 26 },
        onRest: () => {
            setSwipeDirection("");
            setIsTransitioning(false); // End transition after animation
        },
    });

    const toggleReplies = (id) => {
        setOpenReplyId((prev) => (prev === id ? null : id));
    };

    const handleComment = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(!commentVisible);
    }

    const handleVideoDetails = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(false);
        setVideoDetailsVisible(!videoDetailsVisible);
    }

    const handleShare = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(false);
        setShareVisible(!shareVisible);
    }

    const handleReport = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(false);
        setReportVisible(!reportVisible);
    }

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center sticky top-0 z-50">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / news / V1 / 24 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div {...handlers} className="p-0 m-0 h-[100vh] w-full overflow-hidden">
                <animated.div
                    style={{ ...springProps, position: 'absolute', width: '100%', height: '100%' }}
                    className="w-full h-full"
                >
                    <video
                        key={videoData[currentIndex].id}
                        loop
                        autoPlay
                        preload="auto"
                        src={videoData[currentIndex].url}
                        disablePictureInPicture
                        className="w-full h-full object-cover"
                    />

                    <div className="absolute w-full top-1 left-1">
                        <IoCaretBackCircle
                            onClick={() => window.history.back()} 
                            className="text-white text-[16px] cursor-pointer" 
                        />
                    </div>

                    {/* Like, Dislike, Comment, Bookmark, Share, Report */}
                    {controlVisible &&
                        <div className="absolute right-1 top-[40%] transform -translate-y-[40%]">
                            <div className="flex flex-col items-center space-y-2 bg-[#ffffff7e] rounded-full py-3 px-[6px]">
                                <div className="flex flex-col space-y-[1px] items-center">
                                    <BsFillHandThumbsUpFill
                                        className={`text-[18px] cursor-pointer ${userLiked ? 'text-blue-500' : 'text-[#3c3c3c]'}`}
                                        onClick={handleLike}
                                    />
                                    <p className="text-[8px] text-[#2a2a2a]">{likes.toLocaleString()}</p>
                                </div>
                                <div className="flex flex-col space-y-[1px] items-center">
                                    <BsFillHandThumbsDownFill
                                        className={`text-[18px] cursor-pointer ${userDisliked ? 'text-red-500' : 'text-[#3c3c3c]'}`}
                                        onClick={handleDislike}
                                    />
                                    <p className="text-[8px] text-[#2a2a2a]">{dislikes.toLocaleString()}</p>
                                </div>
                                <div className="flex flex-col space-y-[1px] items-center">
                                    <FaComment
                                        className={`text-[18px] cursor-pointer text-[#3c3c3c]`}
                                        onClick={handleComment}
                                    />
                                    <p className="text-[8px] text-[#2a2a2a]">598</p>
                                </div>
                                <div className="flex flex-col space-y-[1px] items-center">
                                    <MdOutlineBookmark
                                        className={`text-[18px] cursor-pointer ${isBookmarked ? 'text-blue-500' : 'text-[#3c3c3c]'}`}
                                        onClick={handleBookmark}
                                    />
                                    <p className="text-[8px] text-[#2a2a2a]">{bookmarks.toLocaleString()}</p>
                                </div>
                                <div className="pb-2">
                                    <FaShareAlt
                                        className={`text-[18px] cursor-pointer text-[#3c3c3c]`}
                                        onClick={handleShare}
                                    />
                                </div>
                                <div className="pb-0">
                                    <MdReport
                                        className={`text-[18px] cursor-pointer text-[#3c3c3c]`}
                                        onClick={handleReport}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {/* Subscribe */}
                    {subscriberVisible &&
                        <div className="absolute w-full bottom-[8rem] flex flex-col items-center justify-center">
                            <div className="flex rounded-[6px] bg-[#00000065] px-2 py-1 mx-6">
                                <div className="grid grid-cols-12">
                                    <div className="col-span-2 flex items-center justify-start">
                                        <img
                                            alt="account"
                                            className="h-7 w-7 rounded-full object-cover bg-black cursor-pointer"
                                            src={videoData[currentIndex].channelLogo}
                                        />
                                    </div>
                                    <div className="col-span-10 w-[10rem] flex items-center ml-[5px]">
                                        <p
                                            onClick={handleVideoDetails}
                                            className="text-[9.5px] line-clamp-2 leading-[11.5px] text-white"
                                        >
                                            {videoData[currentIndex].title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <button
                                onClick={() => setIsSubscribed(!isSubscribed)}
                                className="text-[13px] bg-[#0DD315] text-white w-[8rem] font-semibold py-1"
                            >
                                {isSubscribed ? "Unsubscribe" : "Subscribe"}
                            </button>

                            <div className="absolute bottom-1 left-2 flex items-center gap-1">
                                <div className="h-[10px] w-[10px] bg-[#F80000] rounded-full animate-blink" />
                                <p className="text-[12px] text-white font-semibold">10:45</p>
                            </div>
                        </div>
                    }

                    {/* Comments */}
                    {commentVisible &&
                        <div className="absolute w-full overflow-y-scroll bottom-[7.1rem] h-[60vh] bg-black rounded-t-2xl">
                            <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
                                <div className="flex items-center gap-2">
                                    <p className="text-white text-[14px]">Comments</p>
                                    <p className="text-[#5e5e5e] text-[10px]">139</p>
                                </div>
                                <IoClose
                                    onClick={handleComment}
                                    className="text-white text-[20px] cursor-pointer"
                                />
                            </div>
                            {commentData.map((data) => (
                                <div className="grid grid-cols-12 bg-[#242424] rounded-lg p-2 my-2 mx-2" key={data.id}>
                                    <div className="col-span-2">
                                        <img
                                            alt={data.name}
                                            src={data.image}
                                            className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                        />
                                    </div>
                                    <div className="col-span-10 -ml-3">
                                        <p className="text-white text-[12px] font-semibold mt-[-2px]">{data.name}</p>
                                        <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                            {data.message}
                                        </p>
                                        <div className="flex items-center gap-3 mt-1">
                                            <p className="text-cyan-500 text-[10px] font-semibold cursor-pointer" onClick={() => toggleReplies(data.id)}>Replies</p>
                                            <BsFillHandThumbsUpFill className="h-3 w-3 text-white cursor-pointer hover:text-lime-500" />
                                            <BsFillHandThumbsDownFill className="h-3 w-3 text-white cursor-pointer hover:text-red-600" />
                                        </div>
                                        {openReplyId === data.id &&
                                            <div className="grid grid-cols-12 mt-2">
                                                <div className="col-span-2">
                                                    <img
                                                        alt={data.name}
                                                        src={data.image}
                                                        className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                                    />
                                                </div>
                                                <div className="col-span-10">
                                                    <p className="text-white text-[12px] font-semibold mt-[-2px]">{data.name}</p>
                                                    <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                                        {data.message}
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))}
                            <div className="px-2 sticky bottom-0 bg-black py-2 border-t-[0.4px] border-[#262626]">
                                <div className="flex items-center justify-between gap-2">
                                    <img
                                        alt="account"
                                        className="h-7 w-7 rounded-full object-cover bg-black cursor-pointer"
                                        src="https://cdn.pixabay.com/photo/2017/09/23/11/43/football-2778583_1280.jpg"
                                    />
                                    <input
                                        type="text"
                                        value={comment}
                                        placeholder="Add a comment..."
                                        onChange={(e) => setComment(e.target.value)}
                                        className="w-full bg-[#1e1e1e] text-white text-[14px] px-2 py-[6px] rounded focus:outline-none"
                                    />
                                    {comment.length > 0 && (
                                        <IoSend className="text-white text-[25px] cursor-pointer" />
                                    )}
                                </div>
                            </div>
                        </div>
                    }

                    {/* Video Details */}
                    {videoDetailsVisible &&
                        <div className="absolute w-full overflow-y-scroll bottom-[7.1rem] h-[35vh] bg-black rounded-t-2xl">
                            <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
                                <p className="text-white text-[14px]">Description</p>
                                <IoClose
                                    onClick={handleVideoDetails}
                                    className="text-white text-[20px] cursor-pointer"
                                />
                            </div>
                            <div className="flex px-2 pt-2 items-center justify-between">
                                <div className="flex items-center">
                                    <img
                                        alt="account"
                                        className="h-9 w-9 rounded-full object-cover bg-black cursor-pointer"
                                        src={videoData[currentIndex].channelLogo}
                                    />
                                    <div className="flex flex-col ml-2">
                                        <p className="text-white text-[12px] font-semibold">{videoData[currentIndex].channelName}</p>
                                        <p className="text-[#717171] text-[10px]">{videoData[currentIndex].subscriber}</p>
                                    </div>
                                </div>
                                <button
                                    onClick={() => setIsSubscribed(!isSubscribed)}
                                    className="text-[12px] text-white border border-[#ffffff] px-2 py-[2px] rounded-full"
                                >
                                    {isSubscribed ? "Unsubscribe" : "Subscribe"}
                                </button>
                            </div>
                            <div className="flex items-center justify-evenly pt-4">
                                <div className="flex flex-col items-center leading-[14px]">
                                    <p className="text-[12px] text-[#ffffff]">{videoData[currentIndex].likes}</p>
                                    <p className="text-[12px] text-[#717171]">Likes</p>
                                </div>
                                <div className="flex flex-col items-center leading-[14px]">
                                    <p className="text-[12px] text-[#ffffff]">{videoData[currentIndex].views}</p>
                                    <p className="text-[12px] text-[#717171]">Views</p>
                                </div>
                                <div className="flex flex-col items-center leading-[14px]">
                                    <p className="text-[12px] text-[#ffffff]">{videoData[currentIndex].uploaded}</p>
                                    <p className="text-[12px] text-[#717171]">Uploaded</p>
                                </div>
                            </div>
                            <p className="text-[12px] leading-[14px] text-white px-2 pt-4 pb-2">
                                {videoData[currentIndex].title}
                            </p>
                            <div className="flex flex-wrap items-center gap-x-2 px-2">
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#livenews</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#life</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#viral</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#enjoy</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#happy</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#fun</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#smile</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#joy</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#happiness</p>
                                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#positive</p>
                            </div>
                        </div>
                    }

                    {/* Share */}
                    {shareVisible &&
                        <div className="absolute w-full overflow-y-scroll bottom-[7.1rem] h-[20vh] bg-black rounded-t-2xl">
                            <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
                                <p className="text-white text-[14px]">Share</p>
                                <IoClose
                                    onClick={handleShare}
                                    className="text-white text-[20px] cursor-pointer"
                                />
                            </div>
                            <div className="flex items-center justify-between gap-1 p-2">
                                <SocialIcon network="facebook" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                                <SocialIcon network="whatsapp" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                                <SocialIcon network="instagram" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                                <SocialIcon network="x" style={{ height: 40, width: 40 }} className="cursor-pointer" bgColor="#1e1e1e" />
                                <SocialIcon network="pinterest" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                                <SocialIcon network="linkedin" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                            </div>
                            <div className="flex items-center justify-between gap-1 p-2">
                                <SocialIcon network="telegram" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                                <SocialIcon network="threads" style={{ height: 40, width: 40 }} className="cursor-pointer" bgColor="#1e1e1e" />
                                <SocialIcon network="email" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                                <SocialIcon network="discord" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                                <SocialIcon network="reddit" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                                <SocialIcon network="sharethis" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                            </div>
                        </div>
                    }

                    {/* Report */}
                    {reportVisible &&
                        <div className="absolute w-full overflow-y-scroll bottom-[7.1rem] h-[30vh] bg-black rounded-t-2xl">
                            <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
                                <p className="text-white text-[14px]">Report</p>
                                <IoClose
                                    onClick={handleReport}
                                    className="text-white text-[20px] cursor-pointer"
                                />
                            </div>
                            <div className="mt-2 px-2 text-white">
                                <label className="flex items-center mb-2">
                                    <input type="checkbox" className="mr-2" />
                                    Inappropriate Content
                                </label>
                                <label className="flex items-center mb-2">
                                    <input type="checkbox" className="mr-2" />
                                    Spam or Advertising
                                </label>
                                <label className="flex items-center mb-2">
                                    <input type="checkbox" className="mr-2" />
                                    Harassment or Hate Speech
                                </label>
                                <label className="flex items-center mb-2">
                                    <input type="checkbox" className="mr-2" />
                                    Misinformation
                                </label>
                            </div>
                            <div className="flex items-center justify-center mt-3">
                                <button
                                    onClick={handleReport}
                                    className="px-3 py-1 text-[12px] rounded-full bg-[#ebebeb] hover:bg-[#ffffff]"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    }
                </animated.div>
            </div>
        </Fragment>
    )
}

export default ViewLiveNews