import React, { Fragment, useState } from "react";
import { popularSearchlistData, searchlistData } from "../data";
import { GoHistory } from "react-icons/go";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { FaArrowTrendUp } from "react-icons/fa6";

const SearchPage = () => {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState("");
    const [searchHistory, setSearchHistory] = useState(searchlistData);
    const [showAll, setShowAll] = useState(false);

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const clearInput = () => {
        setSearchInput("");
    };

    const clearAllHistory = () => {
        setSearchHistory([]);
    };

    const removeSearchItem = (id) => {
        setSearchHistory(searchHistory.filter(item => item.id !== id));
    };

    const addToSearchHistory = () => {
        if (searchInput.trim() !== "") {
            const newSearchItem = { id: new Date().getTime(), search: searchInput };
            setSearchHistory([newSearchItem, ...searchHistory]);
            setSearchInput("");
        }
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const displayedHistory = showAll ? searchHistory : searchHistory.slice(0, 10);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Search / V1 / 16 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="grid grid-cols-12 items-center px-2 mt-2 gap-1">
                <div className="col-span-1 flex items-start justify-start">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate(-1)} />
                </div>
                <div className="col-span-11 relative">
                    <input
                        type="text"
                        placeholder="Search for anything ..."
                        value={searchInput}
                        onChange={handleInputChange}
                        onKeyDown={(e) => { if (e.key === 'Enter') addToSearchHistory() }}
                        className="w-full pl-3 pr-8 py-[5px] border text-[14px] rounded-full focus:outline-none bg-[#1e1e1e] border-none text-white placeholder:text-[14px]"
                    />
                    {searchInput && (
                        <div className="absolute top-1/2 transform -translate-y-1/2 right-2">
                            <IoCloseOutline className="text-white hover:text-[#f94444] h-[20px] w-[20px] cursor-pointer" onClick={clearInput} />
                        </div>
                    )}
                </div>
            </div>

            <div className="px-2 pt-2">
                <div className="flex items-center justify-between">
                    <p className="text-[13px] text-white font-semibold">
                        Recent Search
                    </p>
                    {searchHistory.length > 0 && (
                        <p
                            className="text-[13px] text-blue-500 hover:text-blue-600 font-semibold cursor-pointer"
                            onClick={clearAllHistory}
                        >
                            clear all
                        </p>
                    )}
                </div>
                {displayedHistory.map((data) => (
                    <div className="grid grid-cols-12 gap-1 items-center mt-3" key={data.id}>
                        <div className="col-span-1">
                            <GoHistory className="text-[15px] text-white" />
                        </div>
                        <div className="col-span-10 cursor-pointer" onClick={() => removeSearchItem(data.id)}>
                            <p className="text-[12px] text-white font-medium leading-4 overflow-hidden line-clamp-1">{data.search}</p>
                        </div>
                        <div className="col-span-1 flex items-center justify-end">
                            <IoCloseOutline
                                className="text-[15px] text-black hover:text-white bg-white hover:bg-[#f94444] rounded-full p-[1px] h-3 w-3 cursor-pointer"
                                onClick={() => removeSearchItem(data.id)}
                            />
                        </div>
                    </div>
                ))}
                {searchHistory.length > 10 && (
                    <div className="text-center mt-2">
                        <button
                            className="text-[13px] text-blue-500 hover:text-blue-600 font-semibold cursor-pointer"
                            onClick={toggleShowAll}
                        >
                            {showAll ? "Show Less" : "View All"}
                        </button>
                    </div>
                )}
            </div>

            <div className="px-2 py-5">
                <p className="text-[13px] text-white font-semibold">
                    Popular Search
                </p>

                {popularSearchlistData.map((data) => (
                    <div className="grid grid-cols-12 gap-1 items-start mt-2" key={data.id}>
                        <div className="col-span-1">
                            <FaArrowTrendUp className="text-[14px] text-white mt-[2px]" />
                        </div>
                        <div className="col-span-9 flex flex-col">
                            <p className="text-[12px] text-white font-medium leading-4 overflow-hidden">
                                {data.search}
                            </p>
                            <p className="text-[10px] text-[#424242]">total {data.totalSearch} search</p>
                        </div>
                        <div className="col-span-2">
                            <img
                                alt={data.search}
                                src={data.thumbnail}
                                className="h-8 w-full object-cover rounded-[4px]"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default SearchPage;
