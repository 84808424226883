import React from "react"
import { useNavigate } from "react-router-dom";

const EnterOTP = () => {
    const lable = "KV / FE / ENTER OTP / V1 / 27 July, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const navigate = useNavigate();

    return (
        <div className="h-full bg-[#2C672E] relative">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>
            
            <h1 className="px-3 text-[3rem] font-semibold uppercase text-white">
                kv
            </h1>

            <form className="px-6 mt-14">
                <p className="text-white font-semibold text-[24px] text-center">
                    Enter OTP
                </p>
                <div className="mt-4">
                    <label className="block text-[#D9D9D9] font-normal" htmlFor="email">
                        abc@example.com
                    </label>
                    <div className="flex items-center justify-between mt-3">
                        <input
                            required
                            type="text"
                            maxLength={1}
                            className="h-10 w-10 text-center text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                        <input
                            required
                            type="text"
                            maxLength={1}
                            className="h-10 w-10 text-center text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                        <input
                            required
                            type="text"
                            maxLength={1}
                            className="h-10 w-10 text-center text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                        <input
                            required
                            type="text"
                            maxLength={1}
                            className="h-10 w-10 text-center text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                        <input
                            required
                            type="text"
                            maxLength={1}
                            className="h-10 w-10 text-center text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                    </div>
                </div>
                <div className="mt-2 flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <p className="text-[12px] text-white">01:59</p>
                        <p className="cursor-pointer text-[12px] text-[#0DD315] font-semibold">
                            Resend OTP
                        </p>
                    </div>
                    <p
                        onClick={() => navigate("/changepass")}
                        className="cursor-pointer text-[12px] text-[#0DD315] font-semibold"
                    >
                        Lost my mail
                    </p>
                </div>
            </form>

            <div className="absolute bottom-[10rem] w-full px-14">
                <div className="flex items-center justify-evenly">
                    <div className="w-8 bg-white h-[3px]" />
                    <div className="w-8 bg-black h-[3px]" />
                    <div className="w-8 bg-white h-[3px]" />
                </div>
            </div>
        </div>
    )
}

export default EnterOTP