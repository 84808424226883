import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiShow, BiHide } from "react-icons/bi";

const ChangePass = () => {
    const lable = "KV / FE / CHANGE PASSWORD / V1 / 27 July , 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    return (
        <div className="h-full bg-[#2C672E] relative">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>
            
            <h1 className="text-[3rem] px-3 font-semibold uppercase text-white">
                kv
            </h1>

            <form className="px-6 mt-6">
                <p className="text-white font-semibold text-[24px] text-center">
                    Change Password
                </p>
                <div className="mt-4">
                    <label className="block text-[#D9D9D9] font-normal" htmlFor="new-password">
                        New Password
                    </label>
                    <div className="relative">
                        <input
                            required
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            id="new-password"
                            className="w-full pl-3 pr-10 py-2 mt-1 text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-3 flex items-center text-[#D9D9D9] cursor-pointer"
                        >
                            {showPassword ? <BiHide className="text-[20px]" /> : <BiShow className="text-[20px]" />}
                        </button>
                    </div>
                </div>
                <div className="mt-2">
                    <label className="block text-[#D9D9D9] font-normal" htmlFor="confirm-password">
                        Confirm Password
                    </label>
                    <div className="relative">
                        <input
                            required
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            id="confirm-password"
                            className="w-full pl-3 pr-10 py-2 mt-1 text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                        <button
                            type="button"
                            onClick={toggleConfirmPasswordVisibility}
                            className="absolute inset-y-0 right-3 flex items-center text-[#D9D9D9] cursor-pointer"
                        >
                            {showConfirmPassword ? <BiHide className="text-[20px]" /> : <BiShow className="text-[20px]" />}
                        </button>
                    </div>
                </div>
                <div className="mt-2 flex items-center justify-start">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            className="mr-1 border-none focus:outline-none cursor-pointer"
                        />
                        <p className="text-white text-[12px]">
                            Logout from all devices
                        </p>
                    </div>
                </div>
                <button
                    type="submit"
                    onClick={() => navigate("/")}
                    className="w-full py-2 mt-4 text-white bg-[#00FF0A] rounded-md focus:outline-none"
                >
                    Reset & Login
                </button>
            </form>

            <div className="absolute bottom-[10rem] w-full px-14">
                <div className="flex items-center justify-evenly">
                    <div className="w-8 bg-white h-[3px]" />
                    <div className="w-8 bg-white h-[3px]" />
                    <div className="w-8 bg-black h-[3px]" />
                </div>
            </div>
        </div>
    );
};

export default ChangePass;
