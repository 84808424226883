import React, { Fragment, useRef, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import MainMenu from "../../components/common/MainMenu";
import { Input, Option, Select, Switch } from "@material-tailwind/react";

const CreatePodcast = () => {
    const navigate = useNavigate();
    const [videoFile, setVideoFile] = useState(null);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [frames, setFrames] = useState([]);
    const videoRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setVideoPreviewUrl(fileUrl);
            setSelectedFile(file);
        }
    };

    const handleLoadedMetadata = async () => {
        const videoElement = videoRef.current;
        const duration = Math.floor(videoElement.duration);
        const framesArray = await captureFrames(videoElement, duration);
        setFrames(framesArray);
    };

    const captureFrames = (videoElement, duration) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const framesArray = [];

            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            const captureFrameAtSecond = (second) => {
                return new Promise((resolve) => {
                    videoElement.currentTime = second;
                    videoElement.onseeked = () => {
                        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                        const frameData = canvas.toDataURL("image/jpeg");
                        framesArray.push(frameData);
                        resolve();
                    };
                });
            };

            const captureFramesAsync = async () => {
                for (let i = 0; i < duration; i++) {
                    await captureFrameAtSecond(i);
                }
                resolve(framesArray);
            };

            captureFramesAsync();
        });
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Create Podcast / V1 / 27 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="bg-white px-2 grid grid-cols-12 items-center sticky top-0 z-10">
                <div className="col-span-4 flex items-center justify-between gap-3">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                </div>
                <div className="col-span-4 flex items-center justify-center gap-2">
                    <p className="text-[14px] font-bold">
                        Create Podcast
                    </p>
                </div>
                <div className="col-span-4 flex items-center justify-end">
                    <MainMenu />
                </div>
            </div>

            {!videoPreviewUrl &&
                <form className="px-2 mt-3">
                    <Input
                        color="blue"
                        variant="standard"
                        label="Podcast Name"
                        placeholder="Enter podcast name"
                        className="text-[14px] text-white"
                    />
                    <div className="mt-3">
                        <Select variant="standard" label="Podcast Genre" color="blue" className="text-[14px] text-white">
                            <Option>Tech</Option>
                            <Option>Politics</Option>
                            <Option>Crime</Option>
                            <Option>Finances</Option>
                            <Option>Currencies</Option>
                        </Select>
                    </div>
                    <div className="mt-3">
                        <Input
                            color="blue"
                            label="Host Name"
                            variant="standard"
                            placeholder="Enter podcast name"
                            className="text-[14px] text-white"
                        />
                    </div>
                    <div className="mt-3">
                        <input
                            type="file"
                            accept="video/*"
                            id="fileInput"
                            className="hidden"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="fileInput" className="w-full h-28 bg-[#3e3e3e] rounded flex items-center justify-center cursor-pointer">
                            <CiCirclePlus className="text-white text-[25px]" />
                        </label>
                    </div>
                    <div className="mt-3">
                        <Select variant="standard" label="Video Quality" color="blue" className="text-[14px] text-white">
                            <Option>144p</Option>
                            <Option>240p</Option>
                            <Option>360p</Option>
                            <Option>480p</Option>
                            <Option>720p</Option>
                            <Option>1080p</Option>
                        </Select>
                    </div>
                    <div className="flex items-center justify-between mt-4">
                        <p className="text-[14px] text-white">
                            Comments
                        </p>
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[14px] text-white">
                            Share
                        </p>
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[14px] text-white">
                            Download
                        </p>
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[14px] text-white">
                            Public / Private
                        </p>
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="w-full my-6 flex items-center justify-center">
                        <button type="submit" className="text-[16px] py-[6px] px-14 rounded text-white bg-[#3e3e3e] hover:opacity-90">
                            Next
                        </button>
                    </div>
                </form>
            }

            {videoPreviewUrl &&
                <div className="px-2 mt-2">
                    <p className="text-white text-[15px] font-bold text-center">
                        Review Podcast
                    </p>

                    <div className="mt-2">
                        <video
                            ref={videoRef}
                            muted
                            loop
                            autoPlay
                            src={videoPreviewUrl}
                            disablePictureInPicture
                            type={videoFile?.type}
                            className="w-full h-full max-h-[22rem] rounded"
                            onLoadedMetadata={handleLoadedMetadata}
                        />
                    </div>

                    <div className="flex overflow-x-scroll mt-2">
                        {frames.map((frame, index) => (
                            <img
                                key={index}
                                src={frame}
                                alt={`Frame ${index + 1}`}
                                className="w-[2.5rem] h-[2.5rem] object-cover"
                            />
                        ))}
                    </div>

                    <div className="mt-5">
                        <Input
                            required
                            color="blue"
                            label="Title"
                            variant="standard"
                            placeholder="Enter podcast title"
                            className="text-[14px] text-white"
                        />
                    </div>

                    <div className="grid grid-cols-12 mt-4">
                        <div className="col-span-6 flex flex-col items-start">
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Format</p>
                                <p className="text-[13px] text-[#3e3e3e]">mp4</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Quality</p>
                                <p className="text-[13px] text-[#3e3e3e]">1080p</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Comments</p>
                                <p className="text-[13px] text-[#3e3e3e]">on</p>
                            </div>
                        </div>
                        <div className="col-span-6 flex flex-col items-start">
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Viewers</p>
                                <p className="text-[13px] text-[#3e3e3e]">public</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Sharing</p>
                                <p className="text-[13px] text-[#3e3e3e]">on</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Download</p>
                                <p className="text-[13px] text-[#3e3e3e]">on</p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full mt-6 mb-5 flex items-center justify-between">
                        <button type="submit" className="text-[14px] py-[5px] w-24 rounded text-white bg-[#3e3e3e] hover:opacity-90">
                            Draft
                        </button>
                        <button type="submit" className="text-[14px] py-[5px] w-24 rounded text-white bg-[#3e3e3e] hover:opacity-90">
                            Upload
                        </button>
                    </div>


                </div>
            }
        </Fragment>
    );
};

export default CreatePodcast;
