import React, { Fragment, useState } from "react";
import { subscribeData } from "../../data";
import { CgBlock, CgUnblock } from "react-icons/cg";
import { VscMute, VscUnmute } from "react-icons/vsc";
import { IoIosNotifications, IoIosNotificationsOff } from "react-icons/io";

const SubscribedList = () => {
    // State for each channel's mute, block, and notification status
    const [channelStates, setChannelStates] = useState(
        subscribeData.reduce((acc, channel) => {
            acc[channel.id] = { isMuted: false, isBlocked: false, isNotified: true };
            return acc;
        }, {})
    );

    const [selectAll, setSelectAll] = useState(false);

    // Handle individual channel updates
    const handleToggle = (id, key) => {
        setChannelStates((prevState) => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                [key]: !prevState[id][key],
            },
        }));
    };

    // Handle "Select all" functionality
    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setChannelStates((prevState) =>
            Object.keys(prevState).reduce((acc, id) => {
                acc[id] = {
                    ...prevState[id],
                    isSelected: !selectAll,
                };
                return acc;
            }, {})
        );
    };

    return (
        <Fragment>
            <div className="flex gap-2 px-2 pt-2">
                <input
                    type="checkbox"
                    className="w-4 h-4 cursor-pointer"
                    checked={selectAll}
                    onChange={handleSelectAll}
                />
                <p className="text-[14px] text-[#ffffff] mt-[-2px]">Select all</p>
            </div>

            <div className="px-2 pt-3">
                {subscribeData.map((item) => (
                    <div className="grid grid-cols-12 gap-x-1 items-center mb-2" key={item.id}>
                        <div className="col-span-2">
                            <img
                                alt={item.channelName}
                                src={item.channelImage}
                                className={`w-[2rem] h-[2rem] object-cover rounded-[4px] cursor-pointer ${
                                    channelStates[item.id]?.isSelected
                                        ? "border-[2px] border-light-blue-500"
                                        : "border-transparent"
                                }`}
                            />
                        </div>
                        <div className="col-span-7 ml-[-10px]">
                            <p className="text-[14px] text-white line-clamp-1">
                                {item.channelName}
                            </p>
                        </div>
                        <div className="col-span-3 flex items-center justify-between">
                            {channelStates[item.id]?.isNotified ? (
                                <IoIosNotifications
                                    onClick={() => handleToggle(item.id, "isNotified")}
                                    className="h-4 w-4 cursor-pointer text-white"
                                />
                            ) : (
                                <IoIosNotificationsOff
                                    onClick={() => handleToggle(item.id, "isNotified")}
                                    className="h-4 w-4 cursor-pointer text-white"
                                />
                            )}

                            {channelStates[item.id]?.isBlocked ? (
                                <CgBlock
                                    onClick={() => handleToggle(item.id, "isBlocked")}
                                    className="h-[18px] w-[18px] cursor-pointer text-white"
                                />
                            ) : (
                                <CgUnblock
                                    onClick={() => handleToggle(item.id, "isBlocked")}
                                    className="h-4 w-4 cursor-pointer text-white"
                                />
                            )}

                            {channelStates[item.id]?.isMuted ? (
                                <VscMute
                                    onClick={() => handleToggle(item.id, "isMuted")}
                                    className="h-4 w-4 cursor-pointer text-white"
                                />
                            ) : (
                                <VscUnmute
                                    onClick={() => handleToggle(item.id, "isMuted")}
                                    className="h-4 w-4 cursor-pointer text-white"
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default SubscribedList;
