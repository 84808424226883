import React, { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoMusicalNotesSharp , IoTimer } from "react-icons/io5";
import { FaCrown } from "react-icons/fa";
import { IoGift } from "react-icons/io5";
import { MdFlipCameraAndroid, MdAddPhotoAlternate, MdMovieFilter } from "react-icons/md";

const CreateShort = () => {
    const videoRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                // console.log("Stream: ", stream);

                if (videoRef.current) {
                    videoRef.current.srcObject = stream;

                    videoRef.current.onloadedmetadata = () => {
                        console.log("Metadata loaded, playing video...");
                        videoRef.current.play().catch(error => console.error("Error playing the video: ", error));
                    };
                } else {
                    // console.error("Video element not found!");
                }
            } catch (err) {
                // console.error("Error accessing the camera: ", err);
            }
        };

        startCamera();

        const videoElement = videoRef.current;

        return () => {
            if (videoElement && videoElement.srcObject) {
                const stream = videoElement.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, []);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Create Short / V1 / 14 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="relative">
                <div className="grid grid-cols-12 items-center bg-white px-2">
                    <div className="col-span-3 flex items-center justify-start">
                        <h1
                            onClick={() => navigate("/home")}
                            className="text-[1.6rem] font-bold uppercase text-black cursor-pointer"
                        >
                            kv
                        </h1>
                    </div>
                    <div className="col-span-6 flex items-center justify-center">
                        <p className="font-semibold text-[16px]">Create Shorts</p>
                    </div>
                    <div className="col-span-3 flex items-center justify-end">
                        <p>Shorts</p>
                    </div>
                </div>

                <div className="absolute w-full top-12 grid grid-cols-12 px-2 z-10">
                    <div className="col-span-3 flex justify-start">
                        <IoMusicalNotesSharp className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer mb-4" />
                    </div>
                    <div className="col-span-6"></div>
                    <div className="col-span-3 flex justify-end">
                        <div className="flex flex-col">
                            <MdFlipCameraAndroid className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer mb-4" />
                            <FaCrown className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer mb-4" />
                            <IoGift className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full h-screen md:h-[67vh] relative">
                <video
                    muted
                    autoPlay
                    ref={videoRef}
                    disablePictureInPicture
                    className="object-cover h-full bg-black"
                />

                <div className="absolute bottom-2 w-full grid grid-cols-12 px-2 z-10">
                    <div className="col-span-2 flex items-center justify-start">
                        <MdAddPhotoAlternate
                            className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center relative">
                        <div
                            className="h-10 w-10 rounded-full border-[2px] cursor-pointer border-white bg-red-600 hover:bg-[#c73737]"
                        />
                        <MdMovieFilter className="absolute right-8 text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                        <IoTimer className="absolute right-0 text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                    </div>
                    <div className="col-span-2 flex items-center justify-end">
                        <button
                            className="text-[12px] bg-white hover:text-green-500 px-2 py-[2px] rounded-full font-semibold"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CreateShort