import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FaImage } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { CiStreamOn } from "react-icons/ci";
import { FaCircleUser } from "react-icons/fa6";
import { MdOutlineMovieFilter } from "react-icons/md";
import { IoNotifications, IoVideocam } from "react-icons/io5";

import { FaShare } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import { IoIosSettings } from "react-icons/io";
import { IoIosHelpCircle } from "react-icons/io";
// import { MdWorkspacePremium } from "react-icons/md";
import { IoCall, IoNewspaper } from "react-icons/io5";

import { FaFilter } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoEyeSharp, IoHeartSharp } from "react-icons/io5";

import Carousel from "react-multi-carousel";

import MainMenu from "../components/common/MainMenu";
import { likedVideoData, livestreamData, mainHomePageData } from "../data";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1
    }
};

const responsiveClips = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1
    }
}

const Account = () => {
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [activePopupId, setActivePopupId] = useState(false);
    const popupRef = useRef(null);

    const menuItems = [
        { id: 1, icon: IoVideocam, numbers: "6", type: "Videos" },
        { id: 2, icon: MdOutlineMovieFilter, numbers: "6K", type: "Clips" },
        { id: 3, icon: FaImage, numbers: "1K", type: "Posts" },
        { id: 4, icon: CiStreamOn, numbers: "10", type: "Lives" },
    ];

    const popupMenu = [
        { id: 1, icon: AiFillEdit, label: "Edit my profile" },
        { id: 2, icon: IoIosSettings, label: "Go to settings" },
        // { id: 3, icon: MdWorkspacePremium, label: "KV prime" },
        { id: 4, icon: FaShare, label: "Share my profile" },
        { id: 5, icon: IoNewspaper, label: "Terms of use" },
        { id: 6, icon: IoCall, label: "Contact us" },
        { id: 7, icon: IoIosHelpCircle, label: "Help" },
    ];

    const handleMenuItemClick = (id) => {
        setSelectedId(id);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsPopupOpen(false);
            setActivePopupId(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const videoData = [
        {
            id: 1,
            title: "Epic Goals and Game-Changing Moments on the Field",
            url: "https://cdn.pixabay.com/video/2022/11/18/139533-772542665_large.mp4",
        },
        {
            id: 2,
            title: "Exploring Hidden Gems Around the World",
            url: "https://cdn.pixabay.com/video/2024/06/01/214780_large.mp4",
        },
        {
            id: 3,
            title: "Riding Through Scenic Trails and Urban Adventures",
            url: "https://cdn.pixabay.com/video/2023/01/22/147560-791705573_large.mp4",
        },
    ];

    const postData = [
        {
            id: 1,
            title: "Navigating the Departure Boards: Searching for the Perfect Flight",
            url: "https://cdn.pixabay.com/photo/2017/06/05/11/01/airport-2373727_1280.jpg",
        },
        {
            id: 2,
            title: "Ready for Takeoff: A Majestic Aircraft Awaiting Departure",
            url: "https://cdn.pixabay.com/photo/2020/02/27/20/47/airplane-4885803_1280.jpg",
        },
        {
            id: 3,
            title: "Passengers settle in as the cabin fills with the soft hum of engines and the anticipation of an upcoming journey.",
            url: "https://cdn.pixabay.com/photo/2023/03/11/11/34/travelling-7844283_1280.jpg",
        },
    ];

    const handlePopupClick = (id) => {
        setActivePopupId(id === activePopupId ? null : id); // Toggle the popup
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Account / V2 / 29 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 z-20">
                <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                    <div className="col-span-6 flex items-center justify-start gap-6">
                        <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                            kv
                        </h1>
                        <IoSearch className="text-[20px] text-black cursor-pointer" onClick={() => navigate("/search")} />
                    </div>
                    <div className="col-span-6 flex items-center justify-end gap-4">
                        <IoNotifications className="text-[20px] cursor-pointer" onClick={() => navigate("/notification")} />
                        <FaCircleUser className="w-[18px] h-[18px] cursor-pointer" onClick={() => setIsPopupOpen(!isPopupOpen)} />
                        <MainMenu />
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-x-2 bg-black px-2 py-2 border-b-[0.4px] border-[#3e3e3e]">
                    <div className="col-span-4">
                        <img
                            alt="profile"
                            onClick={() => setSelectedId(null)}
                            className="w-full h-[6rem] object-cover rounded-[10px] cursor-pointer"
                            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        />
                    </div>
                    <div className="col-span-8 flex flex-col items-start justify-between">
                        <div className="flex items-center justify-between w-full mt-[-0.32em] relative">
                            <p className="text-white text-[16px] font-bold">John Smith</p>
                            <p className="text-white text-[14px] cursor-pointer" onClick={() => setIsPopupOpen(!isPopupOpen)}>: :</p>

                            {isPopupOpen && (
                                <div ref={popupRef} className="absolute top-0 right-0 bg-white rounded shadow-md z-50">
                                    {popupMenu.map((item, index) => (
                                        <div
                                            key={item.id}
                                            className={`flex items-center gap-2 px-[10px] py-1 
                                                ${index === popupMenu.length - 1 ? "" : "border-b-[0.4px] border-[#c4c4c4]"}
                                            `}
                                        >
                                            <item.icon className="text-[14px]" />
                                            <p className="text-[10px] hover:text-[#f75555] cursor-pointer">{item.label}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <p className="text-white text-[10px]">Alexander Grey</p>
                        <div className="flex items-center gap-5">
                            <p className="text-white text-[10px]">101k subscriber</p>
                            <p className="text-white text-[10px]">50 subscribed</p>
                        </div>
                        <div className="flex w-full justify-between items-center">
                            {menuItems.map((item) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleMenuItemClick(item.id)}
                                    className={`w-[42px] h-[35px] flex flex-col gap-y-[2px] justify-center items-center rounded-sm cursor-pointer select-none
                                    ${selectedId === item.id ? "bg-[#0DD315] text-white" : "bg-white text-black"}`}
                                >
                                    <item.icon className={`text-[17px] ${selectedId === item.id ? "text-white" : "text-black"}`} />
                                    <p className="text-[7px]">{item.numbers} {item.type}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            {selectedId === null &&
                <div className="pt-1">
                    <div className="px-2 flex items-center justify-between">
                        <p className="text-[12px] font-semibold text-white">Last watched history</p>
                        <p className="text-[12px] font-semibold text-light-blue-600">Show all</p>
                    </div>

                    <div className="my-2">
                        <div className="px-2 flex mb-1 items-center justify-between">
                            <p className="text-[10px] text-[#a1a1a1]">Recent watched Videos</p>
                            <p
                                onClick={() => navigate("/history")}
                                className="text-[10px] text-[#a1a1a1] hover:text-[#ffffff] cursor-pointer"
                            >
                                view all
                            </p>
                        </div>
                        <div className="px-1">
                            <Carousel
                                ssr={true}
                                arrows={false}
                                infinite={false}
                                swipeable={true}
                                draggable={true}
                                responsive={responsive}
                                className="select-none"
                            >
                                {mainHomePageData.map((item) => (
                                    <div className="select-none mx-1" key={item.id}>
                                        <img
                                            alt="post"
                                            src={item.thumbnail}
                                            className="w-full h-[5.3rem] object-cover rounded-[6px] cursor-pointer"
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>


                    <div className="mt-5">
                        <div className="px-2 flex mb-1 items-center justify-between">
                            <p className="text-[10px] text-[#a1a1a1]">Recent watched clips</p>
                            <p
                                onClick={() => navigate("/clips")}
                                className="text-[10px] text-[#a1a1a1] hover:text-[#ffffff] cursor-pointer">view all</p>
                        </div>
                        <div className="px-1">
                            <Carousel
                                ssr={true}
                                arrows={false}
                                infinite={true}
                                swipeable={true}
                                draggable={true}
                                responsive={responsiveClips}
                                className="select-none"
                            >
                                {videoData.map((item) => (
                                    <div className="mx-1" key={item.id}>
                                        <video
                                            muted
                                            loop
                                            disablePictureInPicture
                                            src={item.url}
                                            className="w-full h-full object-cover rounded-[6px] cursor-pointer"
                                        />
                                    </div>
                                ))}

                            </Carousel>
                        </div>
                    </div>

                    <div className="my-5">
                        <div className="px-2 flex mb-1 items-center justify-between">
                            <p className="text-[10px] text-[#a1a1a1]">Added to Watchlater</p>
                            <p
                                onClick={() => navigate("/watchlist")}
                                className="text-[10px] text-[#a1a1a1] hover:text-[#ffffff] cursor-pointer"
                            >
                                view all
                            </p>
                        </div>
                        <div className="px-1">
                            <Carousel
                                ssr={true}
                                arrows={false}
                                infinite={true}
                                swipeable={true}
                                draggable={true}
                                responsive={responsiveClips}
                                className="select-none"
                            >
                                {likedVideoData.map((item) => (
                                    <div className="select-none mx-1" key={item.id}>
                                        <img
                                            alt="post"
                                            src={item.thumbnail}
                                            className="w-full h-[3.5rem] object-cover rounded-[6px] cursor-pointer"
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            }

            {/* view video */}
            {selectedId === 1 &&
                <div className="px-2 pt-2 pb-10 grid grid-cols-12 gap-2">
                    <div className="col-span-12 flex items-center justify-between">
                        <IoMdArrowRoundBack
                            onClick={() => setSelectedId(null)}
                            className="text-[15px] text-white cursor-pointer"
                        />
                        <div className="flex items-center gap-5">
                            <p className="text-[12px] text-[#00C2FF]">Total 6 Videos</p>
                            <FaFilter className="text-[12px] text-white cursor-pointer" />
                        </div>
                    </div>
                    {mainHomePageData.map((item) => (
                        <div className="col-span-6 flex flex-col items-center" key={item.id}>
                            <img
                                alt="post"
                                src={item.thumbnail}
                                className="w-full h-[5.3rem] object-cover rounded-[6px] cursor-pointer"
                            />
                            <div className="grid grid-cols-12 px-[3px] items-center bg-white w-[90%] rounded-b-[6px]">
                                <div className="col-span-3 flex items-center justify-start">
                                    <p className="text-[8px]">{item.videoLength}</p>
                                </div>
                                <div className="col-span-8">
                                    <p className="text-[8px] line-clamp-1">{item.title}</p>
                                </div>
                                <div ref={popupRef} className="col-span-1 flex items-center justify-end relative">
                                    <p
                                        onClick={() => handlePopupClick(item.id)}
                                        className="text-[8px] font-semibold cursor-pointer hover:text-green-600"
                                    >
                                        : :
                                    </p>
                                    {activePopupId === item.id && (
                                        <div className="absolute top-0 -right-2 bg-[#f8f8f8] rounded">
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#646464] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                <FaShare className="text-[11px]" />
                                                <p className="text-[10px]">Share</p>
                                            </div>
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                <FaRegEdit className="text-[11px]" />
                                                <p className="text-[10px]" >Edit</p>
                                            </div>
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer">
                                                <RiDeleteBin5Fill className="text-[11px]" />
                                                <p className="text-[10px]" >Delete</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-12 flex items-center justify-around my-[2px]">
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <IoEyeSharp className="text-[11px]" />
                                        <p className="text-[6px]">{item.views}</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <FaShare className="text-[11px]" />
                                        <p className="text-[6px]">105K</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <IoHeartSharp className="text-[11px]" />
                                        <p className="text-[6px]">586K</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <FaCommentDots className="text-[11px]" />
                                        <p className="text-[6px]">60K</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }

            {/* view clips */}
            {selectedId === 2 &&
                <div className="px-2 pt-2 pb-10 grid grid-cols-12 gap-4">
                    <div className="col-span-12 flex items-center justify-between">
                        <IoMdArrowRoundBack
                            onClick={() => setSelectedId(null)}
                            className="text-[15px] text-white cursor-pointer"
                        />
                        <div className="flex items-center gap-5">
                            <p className="text-[12px] text-[#00C2FF]">Total 6K Clips</p>
                            <FaFilter className="text-[12px] text-white cursor-pointer" />
                        </div>
                    </div>
                    {videoData.map((item) => (
                        <div className="col-span-6 flex flex-col items-center justify-center" key={item.id}>
                            <video
                                muted
                                loop
                                disablePictureInPicture
                                src={item.url}
                                className="w-full h-[13rem] object-cover rounded-[2px] cursor-pointer"
                            />
                            <div className="grid grid-cols-12 px-[3px] items-center bg-white w-full rounded-b-[6px]">
                                <div className="col-span-2 flex items-center justify-start">
                                    <p className="text-[8px]">0:38</p>
                                </div>
                                <div className="col-span-9">
                                    <p className="text-[8px] line-clamp-1">{item.title}</p>
                                </div>
                                <div ref={popupRef} className="col-span-1 flex items-center justify-end relative">
                                    <p
                                        onClick={() => handlePopupClick(item.id)}
                                        className="text-[8px] font-semibold cursor-pointer hover:text-green-600"
                                    >
                                        : :
                                    </p>
                                    {activePopupId === item.id && (
                                        <div className="absolute top-0 -right-[3px] bg-[#f8f8f8] rounded">
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#646464] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                <FaShare className="text-[11px]" />
                                                <p className="text-[10px]">Share</p>
                                            </div>
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                <FaRegEdit className="text-[11px]" />
                                                <p className="text-[10px]" >Edit</p>
                                            </div>
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer">
                                                <RiDeleteBin5Fill className="text-[11px]" />
                                                <p className="text-[10px]" >Delete</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-12 flex items-center justify-around my-[2px]">
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <IoEyeSharp className="text-[11px]" />
                                        <p className="text-[6px]">3.85M</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <FaShare className="text-[11px]" />
                                        <p className="text-[6px]">105K</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <IoHeartSharp className="text-[11px]" />
                                        <p className="text-[6px]">586K</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <FaCommentDots className="text-[11px]" />
                                        <p className="text-[6px]">60K</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }

            {/* view posts */}
            {selectedId === 3 &&
                <div className="px-2 pt-2 pb-10 grid grid-cols-12 gap-2">
                    <div className="col-span-12 flex items-center justify-between">
                        <IoMdArrowRoundBack
                            onClick={() => setSelectedId(null)}
                            className="text-[15px] text-white cursor-pointer"
                        />
                        <div className="flex items-center gap-5">
                            <p className="text-[12px] text-[#00C2FF]">Total 1K Posts</p>
                            <FaFilter className="text-[12px] text-white cursor-pointer" />
                        </div>
                    </div>
                    {postData.map((item) => (
                        <div className="col-span-12 pb-2 flex flex-col items-center justify-center" key={item.id}>
                            <img
                                alt="post"
                                src={item.url}
                                className="w-full h-[10rem] object-cover rounded-[3px] cursor-pointer"
                            />
                            <div className="grid grid-cols-12 px-[3px] items-center bg-white w-[60%] rounded-b-[6px]">
                                <div className="col-span-11 flex items-center justify-start">
                                    <p className="text-[8px] line-clamp-1">{item.title}</p>
                                </div>
                                <div ref={popupRef} className="col-span-1 flex items-center justify-end relative">
                                    <p
                                        onClick={() => handlePopupClick(item.id)}
                                        className="text-[8px] font-semibold cursor-pointer hover:text-green-600"
                                    >
                                        : :
                                    </p>
                                    {activePopupId === item.id && (
                                        <div className="absolute top-0 -right-2 bg-[#f8f8f8] rounded">
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#646464] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                <FaShare className="text-[11px]" />
                                                <p className="text-[10px]">Share</p>
                                            </div>
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                <FaRegEdit className="text-[11px]" />
                                                <p className="text-[10px]" >Edit</p>
                                            </div>
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer">
                                                <RiDeleteBin5Fill className="text-[11px]" />
                                                <p className="text-[10px]" >Delete</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-12 flex items-center justify-around my-[2px]">
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <IoEyeSharp className="text-[11px]" />
                                        <p className="text-[6px]">3.85M</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <FaShare className="text-[11px]" />
                                        <p className="text-[6px]">105K</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <IoHeartSharp className="text-[11px]" />
                                        <p className="text-[6px]">586K</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <FaCommentDots className="text-[11px]" />
                                        <p className="text-[6px]">60K</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }

            {/* view lives */}
            {selectedId === 4 &&
                <div className="px-2 pt-2 pb-10 grid grid-cols-12 gap-2">
                    <div className="col-span-12 flex items-center justify-between">
                        <IoMdArrowRoundBack
                            onClick={() => setSelectedId(null)}
                            className="text-[15px] text-white cursor-pointer"
                        />
                        <div className="flex items-center gap-5">
                            <p className="text-[12px] text-[#00C2FF]">Total 10 Lives</p>
                            <FaFilter className="text-[12px] text-white cursor-pointer" />
                        </div>
                    </div>
                    {livestreamData.map((item) => (
                        <div className="col-span-12 pb-2 flex flex-col items-center justify-center" key={item.id}>
                            <img
                                alt="post"
                                src={item.thumbnail}
                                className="w-full h-[10rem] object-cover rounded-[3px] cursor-pointer"
                            />
                            <div className="grid grid-cols-12 px-[3px] items-center bg-white w-[60%] rounded-b-[6px]">
                                <div className="col-span-11 flex items-center justify-start">
                                    <p className="text-[8px] line-clamp-1">{item.title}</p>
                                </div>
                                <div ref={popupRef} className="col-span-1 flex items-center justify-end relative">
                                    <p
                                        onClick={() => handlePopupClick(item.id)}
                                        className="text-[8px] font-semibold cursor-pointer hover:text-green-600"
                                    >
                                        : :
                                    </p>
                                    {activePopupId === item.id && (
                                        <div className="absolute top-0 -right-2 bg-[#f8f8f8] rounded">
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#646464] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                <FaShare className="text-[11px]" />
                                                <p className="text-[10px]">Share</p>
                                            </div>
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                <FaRegEdit className="text-[11px]" />
                                                <p className="text-[10px]" >Edit</p>
                                            </div>
                                            <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer">
                                                <RiDeleteBin5Fill className="text-[11px]" />
                                                <p className="text-[10px]" >Delete</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-12 flex items-center justify-around my-[2px]">
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <IoEyeSharp className="text-[11px]" />
                                        <p className="text-[6px]">{item.views}</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <FaShare className="text-[11px]" />
                                        <p className="text-[6px]">105K</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <IoHeartSharp className="text-[11px]" />
                                        <p className="text-[6px]">586K</p>
                                    </div>
                                    <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                        <FaCommentDots className="text-[11px]" />
                                        <p className="text-[6px]">60K</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </Fragment>
    );
};

export default Account;
