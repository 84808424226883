import React from "react"
import { social_logo } from "../data"
import { useNavigate } from "react-router-dom";

const SignUpPage = () => {
    const lable = "KV / FE / SIGNUP / V1 / 27 July, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const navigate = useNavigate();

    return (
        <div className="h-full bg-[#2C672E] overflow-y-scroll pb-2">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>

            <h1 className="text-[3rem] px-3 font-semibold uppercase text-white">
                kv
            </h1>

            <form className="px-4 mt-[-12px]">
                <p className="text-white font-semibold text-[20px] text-center">
                    Let’s Get Started
                </p>
                <div className="mt-[2px]">
                    <label className="block text-[#D9D9D9] mb-[2px] font-normal" htmlFor="email">
                        Full name
                    </label>
                    <input
                        required
                        type="text"
                        placeholder="Enter your Full name"
                        className="w-full px-2 py-[6px] text-white placeholder:text-[15px] bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                    />
                </div>
                <div className="mt-[2px]">
                    <label className="block text-[#D9D9D9] mb-[2px] font-normal" htmlFor="email">
                        Email
                    </label>
                    <input
                        required
                        type="email"
                        placeholder="Enter your email address"
                        className="w-full px-2 py-[6px] text-white placeholder:text-[15px] bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                    />
                </div>
                <div className="mt-[2px]">
                    <label className="block text-[#D9D9D9] mb-[2px] font-normal" htmlFor="email">
                        Password
                    </label>
                    <input
                        required
                        type="password"
                        placeholder="Enter your password"
                        className="w-full px-2 py-[6px] text-white placeholder:text-[15px] bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                    />
                </div>
                <div className="mt-[2px]">
                    <label className="block text-[#D9D9D9] mb-[2px] font-normal" htmlFor="email">
                        Confirm Password
                    </label>
                    <input
                        required
                        type="password"
                        placeholder="Enter your password"
                        className="w-full px-2 py-[6px] text-white placeholder:text-[15px] bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                    />
                </div>
                <div className="mt-[2px] flex items-center justify-between gap-3">
                    <div>
                        <label className="block text-[#D9D9D9] mb-[2px] font-normal" htmlFor="email">
                            Date of Birth
                        </label>
                        <input
                            required
                            type="text"
                            placeholder="mm/dd/yyyy"
                            className="w-full px-2 py-[6px] text-white placeholder:text-[15px] bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                    </div>
                    <div>
                        <label className="block text-[#D9D9D9] mb-[2px] font-normal" htmlFor="email">
                            Gender
                        </label>
                        <input
                            required
                            type="text"
                            placeholder="Prefer not to say"
                            className="w-full px-2 py-[6px] text-white placeholder:text-[15px] bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                        />
                    </div>
                </div>

                <div className="flex items-start mt-1">
                    <input
                        type="checkbox"
                        className="mr-1 mt-[3px] border-none focus:outline-none cursor-pointer"
                    />
                    <p className="text-white text-[12px]">
                        By creating an account, I agree to the
                        <span className="text-[#0DD315] ml-[4px] cursor-pointer">Terms of Use, </span>
                        <span className="text-[#0DD315] ml-[4px] cursor-pointer">Privacy Policy </span>
                        and
                        <span className="text-[#0DD315] ml-[4px] cursor-pointer">Cookies Policy.</span>
                    </p>
                </div>

                <button
                    type="submit"
                    className="w-full py-2 mt-1 text-white bg-[#00FF0A] rounded-md focus:outline-none"
                >
                    Sign Up
                </button>

                <div className="flex items-center justify-between gap-4 mt-1">
                    <div className="h-[0.4px] bg-white rounded-full w-full" />
                    <p className="text-[10px] text-white whitespace-nowrap">or Sign Up with</p>
                    <div className="h-[0.4px] bg-white rounded-full w-full" />
                </div>

                <div className="flex items-center justify-between mt-1">
                    {social_logo.map((data) => (
                        <div key={data.id} className="bg-white p-2 rounded">
                            {data.src ? (
                                <img
                                    alt={data.name}
                                    src={data.src}
                                    className="h-5 w-5"
                                />
                            ) : (
                                <p className="text-sm font-extrabold h-5 w-5 text-center">{data.name}</p>
                            )}
                        </div>
                    ))}
                </div>

                <div className="flex items-center justify-center gap-1 w-full pt-2">
                    <p className="text-[14px] text-white">I have an account?</p>
                    <p className="font-semibold text-[#00FF0A] text-[14px] cursor-pointer" onClick={() => navigate("/")}>Log in</p>
                </div>
            </form>
        </div>
    )
}

export default SignUpPage