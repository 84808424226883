import React, { useState } from "react";
import { mainHomePageData } from "../data";
import { useNavigate } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { GoBookmark } from "react-icons/go";
import { MdOutlineWatchLater } from "react-icons/md";
import { PiShareFat } from "react-icons/pi";
import { IoBanOutline, IoClose } from "react-icons/io5";
import { LiaUserAltSlashSolid } from "react-icons/lia";
import { CiFlag1 } from "react-icons/ci";

const HomePage = () => {
    const navigate = useNavigate();
    const [openVideoInfo, setOpenVideoInfo] = useState(null);

    const handleToggle = (id) => {
        if (openVideoInfo === id) {
            setOpenVideoInfo(null);
        } else {
            setOpenVideoInfo(id);
        }
    };

    return (
        <div className="bg-black h-full overflow-y-scroll">
            {mainHomePageData.map((data) => (
                <div className="pb-2" key={data.id}>
                    <img
                        alt="thumbnail"
                        src={data.thumbnail}
                        onClick={() => navigate(`/video/${data.id}`)}
                        className="w-full px-2 h-[10rem] object-cover rounded-sm cursor-pointer"
                    />
                    <div className="grid grid-cols-12 mt-1 px-2">
                        <div className="col-span-2">
                            <img
                                alt="profile"
                                src={data.channelLogo}
                                onClick={() => navigate(`/otheruser/account/${data.id}`)}
                                className="h-8 w-8 rounded-full object-cover cursor-pointer"
                            />
                            <div className="flex flex-col items-center justify-start ml-[-12px]">
                                <p className="text-white text-[13px]">{data.views}</p>
                                <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                            </div>
                        </div>
                        <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                            <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                            <p className="text-[#8d8d8d] text-[12px]">{data.channel} - {data.days}</p>
                        </div>
                        <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                            <p
                                onClick={() => handleToggle(data.id)}
                                className="text-white text-[14px] cursor-pointer"
                            >
                                : :
                            </p>
                        </div>
                    </div>

                    {openVideoInfo === data.id && (
                        <>
                            <div className="absolute inset-0 bg-black opacity-50 z-5" />
                            <div className="absolute bottom-0 w-full bg-[#191919] h-[46vh] z-5">
                                <div className="px-2 sticky top-0 bg-[#191919] z-10 flex items-center gap-3 justify-between pt-2 pb-1 border-b-[0.4px] border-[#474747]">
                                    <p className="text-white text-[14px] line-clamp-1">
                                        {data.title}
                                    </p>
                                    <IoClose
                                        onClick={() => handleToggle(data.id)}
                                        className="text-white text-[20px] cursor-pointer"
                                    />
                                </div>
                                <div className="flex items-center gap-3 pt-2 px-2">
                                    <MdOutlineWatchLater className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                    <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Save to Watch list</p>
                                </div>
                                <div className="flex items-center gap-3 pt-2 px-2">
                                    <GoBookmark className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                    <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Save to playlist</p>
                                </div>
                                <div className="flex items-center gap-3 pt-2 px-2">
                                    <FiDownload className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                    <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Download video</p>
                                </div>
                                <div className="flex items-center gap-3 pt-2 px-2">
                                    <PiShareFat className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                    <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Share</p>
                                </div>
                                <div className="flex items-center gap-3 pt-2 px-2">
                                    <IoBanOutline className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                    <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Not interested</p>
                                </div>
                                <div className="flex items-center gap-3 pt-2 px-2">
                                    <LiaUserAltSlashSolid className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                    <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Don't recommend this channel</p>
                                </div>
                                <div className="flex items-center gap-3 pt-2 px-2">
                                    <CiFlag1 className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                    <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Report</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default HomePage;
