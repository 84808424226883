import React, { Fragment, useState, useEffect, useRef } from "react";
import { CiSearch } from "react-icons/ci";
import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdPlaylistPlay } from "react-icons/md";
import { likedVideoData, playlistData } from "../data";
import { Input, Switch } from "@material-tailwind/react";

const PlayList = () => {
    const navigate = useNavigate();
    const [showLikedVideos, setShowLikedVideos] = useState(false);
    const [visibleMenuId, setVisibleMenuId] = useState(null);
    const [isCreatePlaylist, setIsCreatePlaylist] = useState(false);
    const menuRefs = useRef({});

    const handlePlaylistClick = () => {
        setShowLikedVideos(true);
    };

    const toggleMenu = (id) => {
        setVisibleMenuId(visibleMenuId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        if (
            !Object.values(menuRefs.current).some((ref) => ref && ref.contains(event.target))
        ) {
            setVisibleMenuId(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Play List / V1 / 15 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate(-1)} />
                    <p className="text-white text-[16px] font-medium">Your Playlist</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate("/search")} />
                    <HiOutlineDotsVertical className="text-white h-5 w-5 cursor-pointer mr-[-8px]" />
                </div>
            </div>

            {isCreatePlaylist ?
                <div className="px-2 mt-2">
                    <div className="flex items-center justify-between">
                        <p className="text-white text-[14px] font-bold">Create Playlist</p>
                        <p
                            onClick={() => setIsCreatePlaylist(false)}
                            className="text-[10px] text-light-blue-600 hover:text-light-blue-500 cursor-pointer"
                        >
                            Close
                        </p>
                    </div>
                    <div className="flex flex-col mt-4">
                        <Input label="Title" color="white" />
                    </div>
                    <div className="flex items-center justify-between mt-4">
                        <p className="text-[12px] text-white">
                            Private
                        </p>
                        <Switch
                            ripple={false}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mt-4">
                        <button
                            onClick={() => setIsCreatePlaylist(false)}
                            className="bg-[#3e3e3e] opacity-80 hover:opacity-100 transition-all duration-300 text-[13px] text-white px-4 py-[3px] rounded-md"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => setIsCreatePlaylist(false)}
                            className="bg-[#3e3e3e] opacity-80 hover:opacity-100 transition-all duration-300 text-[13px] text-white px-4 py-[3px] rounded-md"
                        >
                            Create
                        </button>
                    </div>
                </div>
                :
                <div className="mt-2 px-2">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2 text-white">
                            <p className="text-[14px] font-medium">Total Playlists</p>
                            <p className="text-[14px] font-bold">02</p>
                        </div>
                        <p
                            onClick={() => setIsCreatePlaylist(true)}
                            className="text-[10px] text-light-blue-600 hover:text-light-blue-500 cursor-pointer"
                        >
                            Create Playlist
                        </p>
                    </div>


                    <div className="flex items-center justify-between my-2">
                        <p
                            onClick={() => setShowLikedVideos(false)}
                            className="bg-white px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80"
                        >
                            Recently Added
                        </p>
                        <p
                            onClick={() => setShowLikedVideos(false)}
                            className="bg-white px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80"
                        >
                            Recently Played
                        </p>
                        <p
                            onClick={() => setShowLikedVideos(false)}
                            className="bg-white px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80"
                        >
                            A-Z
                        </p>
                        <p
                            onClick={() => setShowLikedVideos(false)}
                            className="bg-white px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80"
                        >
                            Z-A
                        </p>
                    </div>

                    {!showLikedVideos ? (
                        playlistData.map((data) => (
                            <div className="grid grid-cols-12 my-4 gap-2" key={data.id}>
                                <div className="col-span-5 relative" onClick={handlePlaylistClick}>
                                    <img
                                        alt="liked video"
                                        className="w-full h-[4.35rem] object-cover rounded cursor-pointer"
                                        src={data.playListCover}
                                    />
                                    <div className="absolute bottom-1 right-2 flex items-center gap-1 bg-[#292929] px-2 py-[1px] rounded-[4px]">
                                        <MdPlaylistPlay className="text-white text-[12px]" />
                                        <p className="text-white text-[10px]">{data.totalItems}</p>
                                    </div>
                                </div>
                                <div className="col-span-7">
                                    <div className="flex justify-between">
                                        <div className="flex flex-col" onClick={handlePlaylistClick}>
                                            <p className="text-[#808080] text-[10px] cursor-pointer">{data.playListName}</p>
                                            <div className="flex items-center gap-1">
                                                <p className="text-[#808080] text-[10px]">{data.playListType}</p>
                                                <p className="text-[#808080] text-[10px]">•</p>
                                                <p className="text-[#808080] text-[10px]">Playlist</p>
                                            </div>
                                            <p className="text-[#808080] text-[10px] cursor-pointer">{data.updated}</p>
                                        </div>
                                        <div className="relative" ref={(el) => (menuRefs.current[data.id] = el)}>
                                            <HiOutlineDotsVertical
                                                onClick={() => toggleMenu(data.id)}
                                                className="text-[#808080] text-[14px] cursor-pointer mr-[-5px] z-10"
                                            />
                                            {visibleMenuId === data.id && (
                                                <div className="absolute top-0 right-0 bg-white px-2 rounded">
                                                    <p className="text-[12px] hover:text-[#f83d3d] cursor-pointer">delete</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        likedVideoData.map((data) => (
                            <div className="grid grid-cols-12 px-2 my-4 gap-2" key={data.id}>
                                <div className="col-span-5">
                                    <img
                                        alt="liked video"
                                        src={data.thumbnail}
                                        className="w-full h-[4.35rem] object-cover rounded cursor-pointer"
                                    />
                                </div>
                                <div className="col-span-7">
                                    <p className="text-white text-[10px] leading-[14px] cursor-pointer line-clamp-2">
                                        {data.title}
                                    </p>
                                    <p className="text-[#808080] text-[10px] cursor-pointer">{data.channel}</p>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2">
                                            <p className="text-[#808080] text-[10px]">{data.views} views</p>
                                            <p className="text-[#808080] text-[10px]">•</p>
                                            <p className="text-[#808080] text-[10px]">{data.uploaded}</p>
                                        </div>

                                        <FaRegTrashAlt className="text-[#808080] text-[10px] cursor-pointer hover:text-[#f04f4f]" />
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            }
        </Fragment>
    );
};

export default PlayList;
