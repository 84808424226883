import React, { Fragment, useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Switch } from "@material-tailwind/react";
import MainMenu from "../components/common/MainMenu";
import SubscribedCard from "./subscribed/SubscribedCard";
import SubscribedList from "./subscribed/SubscribedList";

import { FaCircleUser } from "react-icons/fa6";
import { IoIosSettings } from "react-icons/io";
import { IoFilterCircle, IoNotifications, IoSearch } from "react-icons/io5";

const Subscribed = () => {
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showSubscriptions, setShowSubscriptions] = useState(false);

    const filterRef = useRef(null);
    const settingsRef = useRef(null);

    const handleFilter = () => {
        setShowFilter(!showFilter);
        setShowSettings(false);
    };

    const handleSettings = () => {
        setShowSettings(!showSettings);
        setShowFilter(false);
    };

    const handleShowSubscriptions = () => {
        setShowSubscriptions(true);
    }

    const handleClickOutside = (event) => {
        if (
            filterRef.current && !filterRef.current.contains(event.target) &&
            settingsRef.current && !settingsRef.current.contains(event.target)
        ) {
            setShowFilter(false);
            setShowSettings(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Subscribed / V2 / 02 Sep, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 border-b-[0.4px] border-[#3e3e3e] z-20">
                <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                    <div className="col-span-6 flex items-center justify-start gap-6">
                        <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                            kv
                        </h1>
                        <IoSearch className="text-[20px] text-black cursor-pointer" onClick={() => navigate("/search")} />
                    </div>
                    <div className="col-span-6 flex items-center justify-end gap-4">
                        <IoNotifications className="text-[20px] cursor-pointer" onClick={() => navigate("/notification")} />
                        <FaCircleUser className="w-[18px] h-[18px] cursor-pointer" onClick={() => navigate("/account")} />
                        <MainMenu />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center px-2 py-1 bg-black">
                    <div className="col-span-2 flex justify-start relative" ref={filterRef}>
                        <IoFilterCircle
                            onClick={handleFilter}
                            className="text-white text-[22px] cursor-pointer -ml-[2.5px]"
                        />
                        {/* Filter PopUp Menu */}
                        {showFilter &&
                            <div className="absolute top-8 left-0 bg-white px-2 py-1 rounded-lg">
                                <div className="flex items-center justify-between gap-3 py-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Latest Notifications</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Older Notifications</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Sort by date</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">A - Z</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-span-8 flex justify-center">
                        <p
                            onClick={() => setShowSubscriptions(false)}
                            className="text-[17px] font-[550] text-white"
                        >
                            Subscriptions
                        </p>
                    </div>
                    <div className="col-span-2 flex justify-end relative" ref={settingsRef}>
                        <IoIosSettings
                            onClick={handleSettings}
                            className="text-white text-[22px] cursor-pointer -mr-[2.5px]"
                        />
                        {/* Settings PopUp Menu */}
                        {showSettings &&
                            <div className="absolute top-8 right-0 bg-white px-2 py-1 rounded-lg">
                                <div className="flex items-center justify-between gap-3 py-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Mute Ratings</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Hide Ratings</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Delete Ratings</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {!showSubscriptions &&
                <p
                    onClick={handleShowSubscriptions}
                    className="px-2 py-[6px] text-[10px] text-[#00ff48e4] hover:text-[#00FF47] cursor-pointer text-end">
                    Show my subscriptions
                </p>
            }

            {!showSubscriptions &&
                <SubscribedCard />
            }

            {showSubscriptions &&
                <SubscribedList />
            }
        </Fragment>
    );
};

export default Subscribed;
