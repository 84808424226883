import React, { Fragment, useState } from "react";
import Carousel from "react-multi-carousel";
import { subscribeData } from "../../data";

import { RiTvFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { LuStarOff } from "react-icons/lu";
import { BiSolidHide } from "react-icons/bi";
import { FaVolumeMute } from "react-icons/fa";
import { MdOutlineReport } from "react-icons/md";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3.5,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3.5,
        slidesToSlide: 1
    }
};

const responsivePost = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1
    }
}

const SubscribedCard = () => {
    const [menuId, setMenuId] = useState(null);

    const toggleMenu = (id) => {
        setMenuId(menuId === id ? null : id);
    };

    return (
        <Fragment>
            <div className="px-1 pb-2 border-b-[0.4px] border-[#3e3e3e]">
                <Carousel
                    ssr={true}
                    arrows={false}
                    infinite={true}
                    swipeable={true}
                    draggable={true}
                    responsive={responsive}
                    className="select-none"
                >
                    {subscribeData.map((item) => (
                        <div className="mx-1" key={item.id}>
                            <img
                                alt="post"
                                src={item.channelImage}
                                className="w-full h-[3.5rem] object-cover rounded-[4px] cursor-pointer"
                            />
                        </div>
                    ))}
                </Carousel>
            </div>

            {/* Subscribed Card */}
            {subscribeData.map((data) => (
                <div className="px-2 grid grid-cols-12 gap-x-1 py-2 border-b-[0.4px] border-[#3e3e3e]" key={data.id}>
                    <div className="col-span-2">
                        <img
                            alt={data.channelName}
                            src={data.channelImage}
                            className="w-[3rem] h-[3rem] object-cover rounded-[4px] cursor-pointer"
                        />
                    </div>
                    <div className="col-span-9">
                        <p className="text-[15px] mt-[-3px] font-bold text-[#ffffff] line-clamp-1">
                            {data.channelName}
                        </p>
                        <p className="text-[10px] mt-[3px] text-[#8f8f8f] leading-3 line-clamp-2">
                            {data.channelAbout}
                        </p>
                    </div>
                    <div className="col-span-1 flex justify-end relative">
                        <p
                            onClick={() => toggleMenu(data.id)}
                            className="text-[14px] text-[#ffffff] -mt-[6px] font-semibold cursor-pointer"
                        >
                            : :
                        </p>
                        {menuId === data.id && (
                            <div className="absolute top-4 right-0 bg-white px-2 py-1 rounded-lg z-[1]">
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <RiTvFill className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap">View Channel</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <FaVolumeMute className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap">Mute Ratings</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <LuStarOff className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap">Rate Off</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <MdDelete className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap">Delete</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <BiSolidHide className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap">Hide</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <MdOutlineReport className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap">Report</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-span-12 mt-2 px-3">
                        <Carousel
                            ssr={true}
                            arrows={false}
                            infinite={true}
                            swipeable={true}
                            draggable={true}
                            responsive={responsivePost}
                            className="select-none"
                        >
                            {data.latestVideos.map((item) => (
                                <div className="flex flex-col px-1" key={item.id}>
                                    <img
                                        alt="videos"
                                        src={item.thumbnail}
                                        className="w-full h-[3.5rem] object-cover rounded-[4px] cursor-pointer"
                                    />
                                    <p className="text-[8px] text-[#8f8f8f] leading-[9px] line-clamp-2 mt-1">
                                        {item.title}
                                    </p>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            ))}
        </Fragment>
    )
}

export default SubscribedCard;
