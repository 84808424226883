import React, { Fragment } from "react";
import { CiSearch } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";

const EditAccount = () => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Edit Account / V1 / 15 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate(-1)} />
                    <p className="text-white text-[16px] font-medium">Edit Account</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate("/search")} />
                    <HiOutlineDotsVertical className="text-white h-5 w-5 cursor-pointer mr-[-8px]" />
                </div>
            </div>

            {/* Edit Account form will be displayed here */}
            <div className="px-2 my-2">
                <div className="relative w-20 h-20 mb-3">
                    <img
                        alt="profile"
                        className="absolute top-0 left-0 w-full h-full rounded-full object-cover"
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-full"></div>
                    <FaRegEdit className="absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[18px]" />
                    <input
                        type="file"
                        accept="image/*"
                        className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                    />
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Name</p>
                    <input
                        type="text"
                        placeholder="Enter your name"
                        value="John Smith"
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Tag Name</p>
                    <input
                        type="text"
                        placeholder="Enter your tag name"
                        value="@john_smith"
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Profile URL</p>
                    <input
                        type="text"
                        readOnly
                        placeholder="Enter your profile URL"
                        value={"https://www.domain.com/user/yourchannel"}
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Description</p>
                    <input
                        type="text"
                        placeholder="Enter your description"
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <button className="bg-[#181818] hover:bg-[#141414] hover:border-[0.5px] hover:border-[#0c2812] text-white py-1 w-full rounded-md text-[14px]">
                    Save
                </button>
            </div>
        </Fragment>
    )
}

export default EditAccount