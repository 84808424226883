import React, { Fragment } from "react";
import { likedVideoData } from "../data";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";

const LikedVideoPage = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Liked Video / V1 / 14 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate(-1)} />
                    <p className="text-white text-[16px] font-medium">Liked Video</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate("/search")} />
                    <HiOutlineDotsVertical className="text-white h-5 w-5 cursor-pointer mr-[-8px]" />
                </div>
            </div>

            {/* Liked video data will be displayed here */}
            {likedVideoData.map((data) => (
                <div className="grid grid-cols-12 px-2 my-4 gap-2" key={data.id}>
                    <div className="col-span-5">
                        <img 
                            alt="liked video"
                            src={data.thumbnail}
                            className="w-full h-[4.2rem] object-cover rounded cursor-pointer"
                        />
                    </div>
                    <div className="col-span-7">
                        <p className="text-white text-[12px] leading-[14px] cursor-pointer line-clamp-2">
                            {data.title}
                        </p>
                        <p className="text-[#808080] text-[12px] cursor-pointer">{data.channel}</p>
                        <div className="flex items-center gap-2">
                            <p className="text-[#808080] text-[10px]">{data.views} views</p>
                            <p className="text-[#808080] text-[10px]">•</p>
                            <p className="text-[#808080] text-[10px]">{data.uploaded}</p>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    )
}

export default LikedVideoPage