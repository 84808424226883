import React, { Fragment, useState } from "react";

import { padcastData } from "../data";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import MainMenu from "../components/common/MainMenu";
import SecondMenu from "../components/common/SecondMenu";

import { CiFlag1 } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { GoBookmark } from "react-icons/go";
import { PiShareFat } from "react-icons/pi";
import { FaCirclePlus } from "react-icons/fa6";
import { FaCircleUser } from "react-icons/fa6";
import { IoNotifications } from "react-icons/io5";
import { MdOutlineWatchLater } from "react-icons/md";
import { LiaUserAltSlashSolid } from "react-icons/lia";
import { IoBanOutline, IoClose } from "react-icons/io5";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3.5,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3.5,
        slidesToSlide: 1
    }
};

const Podcast = () => {
    const navigate = useNavigate();
    const [openVideoInfo, setOpenVideoInfo] = useState(null);

    const handleToggle = (id) => {
        if (openVideoInfo === id) {
            setOpenVideoInfo(null);
        } else {
            setOpenVideoInfo(id);
        }
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Podcast / V1 / 24 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                <div className="col-span-4 flex items-center justify-between gap-3">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                    <FaSearch className="text-black text-[18px] cursor-pointer" />
                    <FaCirclePlus className="text-black text-[18px] m-[4px] cursor-pointer" onClick={() => navigate("/create/podcast")} />
                </div>
                <div className="col-span-4 flex items-center justify-center gap-2">
                    <p className="text-[14px] font-bold">
                        Podcasts
                    </p>
                </div>
                <div className="col-span-4 flex items-center justify-between">
                    <FaCircleUser className="w-[18px] h-[18px] cursor-pointer ml-4" onClick={() => navigate("/account")} />
                    <IoNotifications className="text-[20px] cursor-pointer" onClick={() => navigate("/notification")} />
                    <MainMenu />
                </div>
            </div>
            
            <div className="pt-1">
                <SecondMenu />
            </div>
            
            <div className="px-2 pt-2 pb-2 bg-black sticky top-0">
                <Carousel
                    ssr={true}
                    arrows={false}
                    infinite={false}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    className=" select-none"
                >
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Tech
                    </p>

                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Politics
                    </p>

                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Crime
                    </p>

                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Finances
                    </p>

                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Currencies
                    </p>
                </Carousel>
            </div>

            <div className="bg-black h-full overflow-y-scroll">
                {padcastData.map((data) => (
                    <div className="pb-2" key={data.id}>
                        <img
                            alt="thumbnail"
                            src={data.thumbnail}
                            onClick={() => navigate(`/video/${data.id}`)}
                            className="w-full px-2 h-[10rem] object-cover rounded-sm cursor-pointer"
                        />
                        <div className="grid grid-cols-12 mt-1 px-2">
                            <div className="col-span-2">
                                <img
                                    alt="profile"
                                    className="h-8 w-8 rounded-full object-cover cursor-pointer"
                                    src={data.channelLogo}
                                />
                                <div className="flex flex-col items-center justify-start ml-[-12px]">
                                    <p className="text-white text-[13px]">{data.views}</p>
                                    <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                                </div>
                            </div>
                            <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                                <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                                <p className="text-[#8d8d8d] text-[12px]">{data.channel} - {data.days}</p>
                            </div>
                            <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                                <p
                                    onClick={() => handleToggle(data.id)}
                                    className="text-white text-[14px] cursor-pointer"
                                >
                                    : :
                                </p>
                            </div>
                        </div>

                        {openVideoInfo === data.id && (
                            <>
                                <div className="absolute inset-0 bg-black opacity-50 z-15" />
                                <div className="absolute bottom-0 w-full bg-[#191919] h-[46vh] z-5">
                                    <div className="px-2 sticky top-0 bg-[#191919] z-10 flex items-center gap-3 justify-between pt-2 pb-1 border-b-[0.4px] border-[#474747]">
                                        <p className="text-white text-[14px] line-clamp-1">
                                            {data.title}
                                        </p>
                                        <IoClose
                                            onClick={() => handleToggle(data.id)}
                                            className="text-white text-[20px] cursor-pointer"
                                        />
                                    </div>
                                    <div className="flex items-center gap-3 pt-2 px-2">
                                        <MdOutlineWatchLater className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Save to Watch list</p>
                                    </div>
                                    <div className="flex items-center gap-3 pt-2 px-2">
                                        <GoBookmark className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Save to playlist</p>
                                    </div>
                                    <div className="flex items-center gap-3 pt-2 px-2">
                                        <FiDownload className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Download video</p>
                                    </div>
                                    <div className="flex items-center gap-3 pt-2 px-2">
                                        <PiShareFat className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Share</p>
                                    </div>
                                    <div className="flex items-center gap-3 pt-2 px-2">
                                        <IoBanOutline className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Not interested</p>
                                    </div>
                                    <div className="flex items-center gap-3 pt-2 px-2">
                                        <LiaUserAltSlashSolid className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Don't recommend this channel</p>
                                    </div>
                                    <div className="flex items-center gap-3 pt-2 px-2">
                                        <CiFlag1 className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px]" />
                                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[14px]">Report</p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default Podcast