import React, { Fragment, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { AboutAccordionData } from "../../data";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

const AboutPage = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(1);
    const [activeTab, setActiveTab] = useState("About KV");

    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        if (tabName === "About KV") {
            setOpen(1); // Open the About KV section
        } else if (tabName === "How it works") {
            setOpen(2); // Open the How it works section
        } else if (tabName === "Our team") {
            setOpen(3); // Open the Our team section
        }
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / About / V1 / 20 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer ml-[-3px]" onClick={() => navigate(-1)} />
                    <p className="text-white text-[16px] font-medium">About Us</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3">
                    <CiSearch className="text-white h-5 w-5 cursor-pointer" onClick={() => navigate("/search")} />
                    <HiOutlineDotsVertical className="text-white h-5 w-5 cursor-pointer mr-[-8px]" />
                </div>
            </div>

            <div className="mx-2 px-4 py-1 mt-2 bg-white flex items-center justify-between rounded-full">
                <p
                    onClick={() => handleTabClick("About KV")}
                    className={`text-[12px] font-semibold cursor-pointer ${activeTab === "About KV" ? "text-light-blue-600" : "text-gray-600"}`}
                >
                    About KV
                </p>
                <p
                    onClick={() => handleTabClick("How it works")}
                    className={`text-[12px] font-semibold cursor-pointer ${activeTab === "How it works" ? "text-light-blue-600" : "text-gray-600"}`}
                >
                    How it works
                </p>
                <p
                    onClick={() => handleTabClick("Our team")}
                    className={`text-[12px] font-semibold cursor-pointer ${activeTab === "Our team" ? "text-light-blue-600" : "text-gray-600"}`}
                >
                    Our team
                </p>
            </div>

            {/* about kv */}
            {activeTab === "About KV" &&
                <div className="px-2 mt-5">
                    <p className="text-[20px] font-extrabold text-center leading-[22px] text-white">
                        We love people giving chances so that they can learn to do something
                    </p>

                    <div className="grid grid-cols-2 gap-3 mt-5">
                        <div className="col-span-1">
                            <img
                                alt="About Us"
                                className="w-full h-[100px] object-cover rounded"
                                src="https://plus.unsplash.com/premium_photo-1661775434014-9c0e8d71de03?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-1">
                            <img
                                alt="About Us"
                                className="w-full h-[100px] object-cover rounded"
                                src="https://images.unsplash.com/photo-1551434678-e076c223a692?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-1">
                            <img
                                alt="About Us"
                                className="w-full h-[100px] object-cover rounded"
                                src="https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-1">
                            <img
                                alt="About Us"
                                className="w-full h-[100px] object-cover rounded"
                                src="https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                    </div>

                    <p className="text-[20px] font-extrabold text-center leading-[22px] text-white my-5">
                        Giving chances to everyone will allow people rebelieve in
                        themselves and leads to successful stories and brings through
                        <span className="text-green-500">{" "}KV</span> and allows others to learn from it.
                    </p>
                </div>
            }

            {/* how it works */}
            {activeTab === "How it works" &&
                <div className="px-2 mt-5">
                    <p className="text-[20px] font-extrabold text-center leading-[22px] text-white">
                        How KV works ?
                    </p>
                    <div className="grid grid-cols-2 gap-3 my-4">
                        <div className="col-span-1">
                            <img
                                alt="About Us"
                                className="w-full h-[100px] object-cover rounded"
                                src="https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-1">
                            <img
                                alt="About Us"
                                className="w-full h-[100px] object-cover rounded"
                                src="https://images.unsplash.com/photo-1532622785990-d2c36a76f5a6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-1">
                            <img
                                alt="About Us"
                                className="w-full h-[100px] object-cover rounded"
                                src="https://images.unsplash.com/photo-1527219525722-f9767a7f2884?q=80&w=1773&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-1">
                            <img
                                alt="About Us"
                                className="w-full h-[100px] object-cover rounded"
                                src="https://images.unsplash.com/photo-1532619187608-e5375cab36aa?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                    </div>
                    <p className="text-[12px] text-white leading-4">
                        <span className="text-[20px]">KV</span> allows all the users to express their knowledge
                        by creating, sharing, uploading any video, post or banner in our app. An interesting
                        summary in our applications is to allow all the users to create their own news by showing
                        their social responsibility to take an immediate action according to the situation.
                    </p>
                    <p className="text-[12px] text-white leading-4 mt-2 mb-5">
                        Allows all the users to express their knowledge by creating, sharing, uploading any video,
                        post or banner in our app. An interesting summary in our applications is to allow all the
                        users to create their own news by showing their social responsibility to take an immediate action.
                    </p>
                    {AboutAccordionData.map((item) => (
                        <Accordion
                            key={item.id}
                            open={open === item.id}
                            className="mb-2 rounded-lg border border-blue-gray-100 px-4"
                        >
                            <AccordionHeader
                                onClick={() => handleOpen(item.id)}
                                className={`border-b-0 transition-colors text-[13px] text-[#dddddd] hover:text-[#cacaca] ${open === item.id ? "text-white hover:!text-[#e5e5e5]" : ""}`}
                            >
                                {item.title}
                            </AccordionHeader>
                            <AccordionBody className="pt-0 mt-[-4px] text-base font-normal text-[12px]">
                                {item.content}
                            </AccordionBody>
                        </Accordion>
                    ))}
                </div>
            }

            {/* our team */}
            {activeTab === "Our team" &&
                <div className="px-2 mt-5">
                    <div className="grid grid-cols-12 gap-2 mt-2 mb-5">
                        <div className="col-span-5 flex items-center justify-center">
                            <img
                                alt="our team"
                                className="w-[7rem] h-[7rem] object-cover rounded"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-7 flex items-center justify-center">
                            <p className="text-white text-[10px] leading-[14px]">
                                It is very interesting to work in this project which allowed our team to
                                get recognized by huge number of people all our world. It is very interesting
                                to work in this project which allowed our team to get recognized by huge number
                                of people all our world.
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-2 my-5">
                        <div className="col-span-7 flex items-center justify-center">
                            <p className="text-white text-[10px] leading-[14px]">
                                It is very interesting to work in this project which allowed our team to
                                get recognized by huge number of people all our world. It is very interesting
                                to work in this project which allowed our team to get recognized by huge number
                                of people all our world.
                            </p>
                        </div>
                        <div className="col-span-5 flex items-center justify-center">
                            <img
                                alt="our team"
                                className="w-[7rem] h-[7rem] object-cover rounded"
                                src="https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-2 my-5">
                        <div className="col-span-5 flex items-center justify-center">
                            <img
                                alt="our team"
                                className="w-[7rem] h-[7rem] object-cover rounded"
                                src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-7 flex items-center justify-center">
                            <p className="text-white text-[10px] leading-[14px]">
                                It is very interesting to work in this project which allowed our team to
                                get recognized by huge number of people all our world. It is very interesting
                                to work in this project which allowed our team to get recognized by huge number
                                of people all our world.
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-2 my-5">
                        <div className="col-span-7 flex items-center justify-center">
                            <p className="text-white text-[10px] leading-[14px]">
                                It is very interesting to work in this project which allowed our team to
                                get recognized by huge number of people all our world. It is very interesting
                                to work in this project which allowed our team to get recognized by huge number
                                of people all our world.
                            </p>
                        </div>
                        <div className="col-span-5 flex items-center justify-center">
                            <img
                                alt="our team"
                                className="w-[7rem] h-[7rem] object-cover rounded"
                                src="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-2 my-5">
                        <div className="col-span-5 flex items-center justify-center">
                            <img
                                alt="our team"
                                className="w-[7rem] h-[7rem] object-cover rounded"
                                src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                        <div className="col-span-7 flex items-center justify-center">
                            <p className="text-white text-[10px] leading-[14px]">
                                It is very interesting to work in this project which allowed our team to
                                get recognized by huge number of people all our world. It is very interesting
                                to work in this project which allowed our team to get recognized by huge number
                                of people all our world.
                            </p>
                        </div>
                    </div>
                </div>
            }

            <div className="px-2 flex items-center justify-between my-5">
                <p 
                    onClick={() => navigate("/terms")}
                    className="text-[10px] text-light-blue-700 hover:text-light-blue-500 cursor-pointer" 
                >
                    Terms and Conditions
                </p>
                <p 
                    onClick={() => navigate("/privacy")}
                    className="text-[10px] text-light-blue-700 hover:text-light-blue-500 cursor-pointer" 
                >
                    Privacy policy
                </p>
                <p 
                    onClick={() => navigate("/help")}
                    className="text-[10px] text-light-blue-700 hover:text-light-blue-500 cursor-pointer" 
                >
                    Help
                </p>
                <p 
                    onClick={() => navigate("/contact")}
                    className="text-[10px] text-light-blue-700 hover:text-light-blue-500 cursor-pointer" 
                >
                    Contact us
                </p>
            </div>
        </Fragment>
    )
}

export default AboutPage