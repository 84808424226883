import React from "react"
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-center h-[76vh]">
            <h1 className="text-8xl font-bold text-gray-800">404</h1>
            <p className="text-2xl font-light text-gray-800">page not found</p>

            <button
                onClick={() => navigate("/home")} 
                className="bg-gray-800 rounded-full px-7 py-1 text-[#b5b5b5] hover:text-[#ffffff] mt-5 uppercase"
            >
                HOME
            </button>
        </div>
    )
}

export default NotFoundPage