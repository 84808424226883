import React, { Fragment } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ContactPage = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Contact Us / V1 / 28 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-10 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Contact Us</p>
                </div>
            </div>

            <form className="m-2">
                <div className="mb-2">
                    <label className="text-white text-[14px] font-normal">Full Name</label>
                    <input
                        type="text"
                        placeholder="Enter your full name"
                        className="w-full mt-[2px] px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                    />
                </div>
                <div className="mb-2">
                    <label className="text-white text-[14px] font-normal">Email</label>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="w-full mt-[2px] px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                    />
                </div>
                <div className="mb-2">
                    <label className="text-white text-[14px] font-normal">Contact Number (optional)</label>
                    <input
                        type="text"
                        placeholder="Enter your contact number"
                        className="w-full mt-[2px] px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                    />
                </div>
                <div className="mb-2">
                    <label className="text-white text-[14px] font-normal">Message</label>
                    <textarea
                        type="text"
                        rows={7}
                        placeholder="Enter your message"
                        className="w-full mt-[2px] px-2 py-2 resize-none text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                    />
                </div>
                <button className="bg-[#ffffff] rounded-full py-1 w-full">
                    Submit
                </button>
            </form>
        </Fragment>
    )
}

export default ContactPage