import React from "react";
import { likeNotification } from "../../data";
import { IoNotificationsCircle } from "react-icons/io5";

const Messages = () => {
    return (
        <div className="pt-1">
            <div className="flex items-center gap-2 mb-3">
                <IoNotificationsCircle className="text-white h-6 w-6" />
                <p className="text-white font-semibold text-[20px]">Messages</p>
            </div>
            {likeNotification.map((data) => (
                <div className="grid grid-cols-12 gap-1 mb-2" key={data.id}>
                    <div className="col-span-3">
                        <img
                            alt="img"
                            src={data.posterImg}
                            className="w-full h-12 object-cover rounded-md cursor-pointer"
                        />
                    </div>
                    <div className="col-span-6">
                        <p className="text-white text-[10px] font-semibold line-clamp-1">
                            {data.title}
                        </p>
                        <p className="text-[#6e6e6e] text-[10px] leading-[12px] font-semibold line-clamp-2">
                            {data.description}
                        </p>
                        <p className="text-cyan-600 text-[10px]">{data.time}</p>
                    </div>
                    <div className="col-span-3 flex items-start justify-between">
                        <img
                            alt="img"
                            src={data.channelLogo}
                            className="h-11 w-11 rounded-2xl object-cover"
                        />
                        <p className="text-white text-[14px] cursor-pointer mt-[-8px]">: :</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Messages