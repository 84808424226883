import React, { useRef, useEffect, useState, Fragment } from "react";

import { useNavigate } from "react-router-dom";
import { Switch } from "@material-tailwind/react";

import { PiTextAaFill } from "react-icons/pi";
import { MdMovieCreation } from "react-icons/md";
import { MdSettingsVoice } from "react-icons/md";
import { MdMusicNote } from "react-icons/md";
import { FaRegImages } from "react-icons/fa6";
import { CgMenuGridR } from "react-icons/cg";
import { musicData } from "../../data";
import { IoPlay } from "react-icons/io5";
import { BsBookmarkPlus } from "react-icons/bs";

import { AudioRecorder } from 'react-audio-voice-recorder';
import { LuImagePlus } from "react-icons/lu";

const CreateVideo = () => {
    const navigate = useNavigate();

    const fileInputRef = useRef(null);
    const isFileInputClicked = useRef(false);

    const [videoSrc, setVideoSrc] = useState("");
    const [thumbnailSrc, setThumbnailSrc] = useState("");
    const [showMusicMenu, setShowMusicMenu] = useState(false);

    const [isTextClicked, setIsTextClicked] = useState(true);
    const [isTrimClicked, setIsTrimClicked] = useState(false);
    const [isVoiceClicked, setIsVoiceClicked] = useState(false);
    const [isMusicClicked, setIsMusicClicked] = useState(false);
    const [isThumbnailClicked, setIsThumbnailClicked] = useState(false);

    const handleTextClick = () => {
        setIsTextClicked(true);
        setIsTrimClicked(false);
        setIsVoiceClicked(false);
        setIsMusicClicked(false);
        setIsThumbnailClicked(false);
    }

    const handleTrimClick = () => {
        setIsTextClicked(false);
        setIsTrimClicked(true);
        setIsVoiceClicked(false);
        setIsMusicClicked(false);
        setIsThumbnailClicked(false);
    }

    const handleVoiceClick = () => {
        setIsTextClicked(false);
        setIsTrimClicked(false);
        setIsVoiceClicked(true);
        setIsMusicClicked(false);
        setIsThumbnailClicked(false);
    }

    const handleMusicClick = () => {
        setIsTextClicked(false);
        setIsTrimClicked(false);
        setIsVoiceClicked(false);
        setIsMusicClicked(true);
        setIsThumbnailClicked(false);
    }

    const handleThumbnailClick = () => {
        setIsTextClicked(false);
        setIsTrimClicked(false);
        setIsVoiceClicked(false);
        setIsMusicClicked(false);
        setIsThumbnailClicked(true);
    }

    useEffect(() => {
        if (!isFileInputClicked.current && fileInputRef.current) {
            fileInputRef.current.click();
            isFileInputClicked.current = true;
        }
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.type === "video/mp4" && file.size <= 1024 * 1024 * 1024) { // 1GB max
                const videoURL = URL.createObjectURL(file);
                setVideoSrc(videoURL);
            } else {
                navigate("/home");
            }
        } else {
            navigate("/home");
        }
    };

    const handleFileInputClick = () => {
        fileInputRef.current.value = null;
    };

    const addAudioElement = (blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement("audio");
        audio.src = url;
        audio.controls = true;
        document.body.appendChild(audio);
    };

    const handleThumbnailSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setThumbnailSrc(imageURL);
        }
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center sticky top-0">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Create Video / V2 / 03 Sep, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            {/* Video Preview */}
            <div className="px-2">
                {!videoSrc &&
                    <input
                        type="file"
                        accept="video/mp4"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        onClick={handleFileInputClick}
                        className="relative mt-2 m-0 block w-full min-w-0 text-white flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3 file:py-[0.32rem] file:text-surface file:text-white focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none"

                    />
                }
                {!isMusicClicked && videoSrc && (
                    <video
                        loop
                        muted
                        autoPlay
                        src={videoSrc}
                        disablePictureInPicture
                        className="w-full h-auto max-h-[15rem] mt-2 rounded object-cover"
                    />
                )}
            </div>

            {videoSrc &&
                <div className="px-2">
                    {/* Add Text */}
                    {isTextClicked &&
                        <div className="mt-2">
                            <div className="flex flex-col">
                                <p className="text-[12px] text-white font-semibold">Add text</p>
                                <textarea
                                    type="text"
                                    rows={3}
                                    placeholder="Add text to your video"
                                    className="w-full mt-[2px] px-2 py-2 resize-none text-white placeholder:text-[13px] text-[13px] rounded border-none bg-[#474747] focus:outline-none"
                                />
                            </div>

                            <div className="flex items-center justify-between my-2">
                                <p className="text-[12px] text-white">
                                    Private
                                </p>
                                <Switch
                                    ripple={false}
                                    className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                    containerProps={{
                                        className: "w-8 h-4",
                                    }}
                                    circleProps={{
                                        className: "before:hidden left-0.5 border-none w-3 h-3",
                                    }}
                                />
                            </div>
                        </div>
                    }

                    {/* Video Trim */}
                    {isTrimClicked &&
                        <div className="my-2">
                            <p className="text-[12px] text-white font-semibold">Trim your video</p>
                            <video
                                loop
                                muted
                                src={videoSrc}
                                disablePictureInPicture
                                className="w-full h-10 max-h-[40px] mt-[2px] rounded object-cover"
                            />
                        </div>
                    }

                    {/* Add Voice */}
                    {isVoiceClicked &&
                        <div className="my-2">
                            <p className="text-[12px] text-white font-semibold">Add your voice</p>

                            <div className="flex items-center justify-center mt-2">
                                <AudioRecorder
                                    onRecordingComplete={addAudioElement}
                                    audioTrackConstraints={{
                                        noiseSuppression: true,
                                        echoCancellation: true,
                                        // autoGainControl,
                                        // channelCount,
                                        // deviceId,
                                        // groupId,
                                        // sampleRate,
                                        // sampleSize,
                                    }}
                                    onNotAllowedOrFound={(err) => console.table(err)}
                                    downloadOnSavePress={true}
                                    downloadFileExtension="webm"
                                    mediaRecorderOptions={{
                                        audioBitsPerSecond: 128000,
                                    }}
                                // showVisualizer={true}
                                />
                            </div>
                        </div>
                    }

                    {/* Add Music */}
                    {isMusicClicked &&
                        <div className="my-2 h-[51vh] overflow-y-scroll">
                            <input
                                type="text"
                                placeholder="Search music"
                                className="w-full px-3 py-1 text-white placeholder:text-[13px] text-[13px] rounded-full border-none bg-[#474747] focus:outline-none"
                            />

                            <div className="flex items-center justify-between mt-2 relative">
                                <CgMenuGridR
                                    onClick={() => setShowMusicMenu(!showMusicMenu)}
                                    className={`text-[20px] ${showMusicMenu ? "text-[#27b129]" : "text-[#ffffff]"}  cursor-pointer`}
                                />
                                <p className="text-[10px] font-semibold text-white bg-[#3e3e3e] hover:bg-[#ffffff] hover:text-black w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer transition-all duration-500">
                                    Most Played
                                </p>
                                <p className="text-[10px] font-semibold text-white bg-[#3e3e3e] hover:bg-[#ffffff] hover:text-black w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer transition-all duration-500">
                                    Top Rated
                                </p>
                                <p className="text-[10px] font-semibold text-white bg-[#3e3e3e] hover:bg-[#ffffff] hover:text-black w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer transition-all duration-500">
                                    Saved
                                </p>
                                {showMusicMenu &&
                                    <div className=" absolute top-7 left-0 bg-white rounded px-2 py-1">
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Electronic Dance Music</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Rock Music</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Jazz</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Dubstep</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Rhythm and Blues</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Techno</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Country Music</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Electro</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Indie Rock</p>
                                        <p className="text-[10px] text-[#1c1c1c] hover:text-[#27b129] pb-1 cursor-pointer">Pop Music</p>
                                    </div>
                                }
                            </div>

                            <div className="mt-2">
                                {musicData.map((data) => (
                                    <div className="grid grid-cols-12 gap-x-1 mb-3" key={data.id}>
                                        <div className="col-span-2">
                                            <img
                                                alt={data.channel}
                                                src={data.posterURL}
                                                className="w-10 h-10 rounded-full object-cover"
                                            />
                                        </div>
                                        <div className="col-span-7 ml-[-5px] flex flex-col justify-between">
                                            <p className="text-[9px] text-white line-clamp-1 leading-[12px]">
                                                {data.title}
                                            </p>
                                            <p className="text-[10px] font-bold text-[#4d4d4d]">
                                                {data.channel}
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <p className="text-[8px] text-[#27b129]">
                                                    {data.totalClips} clips
                                                </p>
                                                <p className="text-[8px] text-[#27b129]">
                                                    {data.totalVideos} videos
                                                </p>
                                            </div>

                                        </div>
                                        <div className="col-span-3 flex justify-around items-start">
                                            <div className="flex flex-col items-center">
                                                <IoPlay className="text-[10px] text-[#ffffff] cursor-pointer" />
                                                <p className="text-[8px] text-[#ffffff]">
                                                    {data.totalClips + data.totalVideos}
                                                </p>
                                            </div>
                                            <BsBookmarkPlus className="text-[10px] text-[#ffffff] mt-[0.5px] cursor-pointer" />
                                            <input
                                                type="radio"
                                                name="musicSelection"
                                                className="cursor-pointer accent-[#0be40e] outline-none shadow-none h-[10px] w-[10px] rounded-full"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    }

                    {/* Add Thumbnail */}
                    {isThumbnailClicked &&
                        <div className="my-2">
                            <p className="text-[12px] text-white font-semibold">Add Thumbnail</p>
                            <div className="flex items-center justify-center">
                                {!thumbnailSrc &&
                                    <div className="flex items-center mt-1 justify-center w-full h-[140px] bg-[#101010] rounded">
                                        <LuImagePlus 
                                            onClick={() => document.getElementById('thumbnailInput').click()}
                                            className="text-[20px] cursor-pointer text-[#ffffff]"
                                        />
                                    </div>
                                }
                                <input
                                    type="file"
                                    id="thumbnailInput"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleThumbnailSelect}
                                />
                                {thumbnailSrc &&
                                    <div className="flex flex-col w-full">
                                        <img
                                            alt="thumbnail"
                                            src={thumbnailSrc}
                                            className="w-full h-[150px] rounded object-cover mt-2"
                                        />
                                        <button
                                            onClick={() => setThumbnailSrc("")}
                                            className="text-[12px] rounded px-3 mt-1 py-1 bg-[#e72e2e] text-white whitespace-nowrap"
                                        >
                                            remove
                                        </button>
                                    </div>

                                }
                            </div>
                        </div>
                    }

                    <div className="bg-black flex items-center justify-center my-5">
                        <div className="inline-flex items-center justify-center gap-4 bg-white rounded-[8px] px-3 py-[5px]">
                            <PiTextAaFill
                                onClick={handleTextClick}
                                className={`h-[20px] w-[20px] cursor-pointer ${isTextClicked ? "text-[#0DD315]" : "text-[#636363]"}`}
                            />
                            <MdMovieCreation
                                onClick={handleTrimClick}
                                className={`h-[20px] w-[20px] cursor-pointer ${isTrimClicked ? "text-[#0DD315]" : "text-[#636363]"}`}
                            />
                            <MdSettingsVoice
                                onClick={handleVoiceClick}
                                className={`h-[20px] w-[20px] cursor-pointer ${isVoiceClicked ? "text-[#0DD315]" : "text-[#636363]"}`}
                            />
                            <MdMusicNote
                                onClick={handleMusicClick}
                                className={`h-[20px] w-[20px] cursor-pointer ${isMusicClicked ? "text-[#0DD315]" : "text-[#636363]"}`}
                            />
                            <FaRegImages
                                onClick={handleThumbnailClick}
                                className={`h-[20px] w-[20px] cursor-pointer ${isThumbnailClicked ? "text-[#0DD315]" : "text-[#636363]"}`}
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <p className="text-[14px] text-[#ffffff] font-bold cursor-pointer">Cancel</p>
                        <p className="text-[14px] text-[#0DD315] font-bold cursor-pointer">Upload</p>
                    </div>

                    <div className="flex items-center justify-center mt-5 mb-3">
                        <button className="text-[14px] px-4 py-[3px] tracking-[1px] rounded bg-[#333333] hover:bg-[#3e3e3e] text-[#ffffff]">
                            Draft
                        </button>
                    </div>
                </div>
            }
        </Fragment>
    );
};

export default CreateVideo;