import React, { Fragment, useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
// import { IoVideocam } from "react-icons/io5";
// import { FaCircleUser } from "react-icons/fa6";
import { FaLock, FaUnlock } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

// import { GiFullMotorcycleHelmet } from "react-icons/gi";
import handLogo from "../assets/footer/hand_footer_icon.png";
// import lockLogo from "../assets/footer/lock_footer_icon.png";

import fireLogo from "../assets/footer/fire_footer_icon.png";
import homeLogo from "../assets/footer/home_footer_icon.png";
import plusLogo from "../assets/footer/plus_footer_icon.png";

import { CiStreamOn } from "react-icons/ci";
import { FaRegNewspaper } from "react-icons/fa";
import { MdOutlinePodcasts, MdOutlineMovieFilter } from "react-icons/md";
import { RiVideoAddFill, RiVideoUploadFill, RiImageAddFill } from "react-icons/ri";

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Hook to track the current location
    const [isLocked, setIsLocked] = useState(true);
    const [isCreatePopup, setIsCreatePopup] = useState(false);
    const popupRef = useRef(null);

    const handleCreatePopup = () => {
        setIsCreatePopup(!isCreatePopup);
    }

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsCreatePopup(false);
        }
    };

    useEffect(() => {
        if (isCreatePopup) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isCreatePopup]);

    // Close the popup when the route changes
    useEffect(() => {
        if (isCreatePopup) {
            setIsCreatePopup(false);
        }
    }, [location.pathname]);

    return (
        <Fragment>
            <div className="grid grid-cols-12 items-center text-white py-[8px] bg-[#272727] px-3">
                <div className="col-span-8">
                    <button className="text-black bg-[#FFF500] text-[16px] md:text-[13px] font-bold w-[95%] py-[7px] cursor-pointer rounded-[5px]">
                        Secret sauce panel
                    </button>
                </div>
                <div className="col-span-4">
                    <button className="text-black bg-[#E4080A] text-[16px] md:text-[13px] font-bold w-full py-[7px] cursor-pointer rounded-[5px]">
                        Invite
                    </button>
                </div>
            </div>
            <div className="bg-[#0DD315] flex items-center justify-between px-3 py-2">
                {/* <GiFullMotorcycleHelmet className="w-[20px] h-[20px] cursor-pointer" /> */}
                {/* <img src={lockLogo} alt="lock" className="w-4 h-4 cursor-pointer" /> */}
                {isLocked ?
                    <FaLock className="w-[17px] h-[17px] cursor-pointer" onClick={() => setIsLocked(!isLocked)} />
                    : <FaUnlock className="w-[17px] h-[17px] cursor-pointer" onClick={() => setIsLocked(!isLocked)} />
                }
                <img src={plusLogo} alt="plus" className="w-5 h-5 cursor-pointer" onClick={handleCreatePopup} />
                <img src={homeLogo} alt="home" className="w-6 h-6 cursor-pointer" onClick={() => navigate("/home")} />
                <img src={handLogo} alt="home" className="w-6 h-6 cursor-pointer" onClick={() => navigate("/clips")} />
                {/* <IoVideocam className="text-[20px] cursor-pointer" onClick={() => navigate("/clips")} /> */}
                {/* <FaCircleUser className="w-[18px] h-[18px] cursor-pointer" onClick={() => navigate("/account")} /> */}
                <img src={fireLogo} alt="fire" className="w-6 h-6 cursor-pointer" onClick={() => navigate("/trending")} />
            </div>

            {isCreatePopup &&
                <div ref={popupRef} className="absolute w-full h-[28vh] bg-[#1a1a1a] bottom-[5.6rem] rounded-t-xl z-50">
                    <div className="px-2 sticky top-0 z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#343434]">
                        <p className="text-white text-[14px]">Create</p>
                        <IoClose
                            onClick={() => setIsCreatePopup(false)}
                            className="text-white text-[20px] cursor-pointer"
                        />
                    </div>

                    <div className="grid grid-cols-12 gap-3 mt-2">
                        <div className="col-span-4 flex flex-col items-center" onClick={() => navigate("/createvideo")}>
                            <RiVideoAddFill className="w-[25px] h-[25px] text-[#dedede] hover:text-[#ffffff] cursor-pointer" />
                            <p className="text-[#dedede] hover:text-[#ffffff] cursor-pointer text-[12px]">
                                Create Video
                            </p>
                        </div>
                        <div className="col-span-4 flex flex-col items-center" onClick={() => navigate("/uploadvideo")}>
                            <RiVideoUploadFill className="w-[25px] h-[25px] text-[#dedede] hover:text-[#ffffff] cursor-pointer" />
                            <p className="text-[#dedede] hover:text-[#ffffff] cursor-pointer text-[12px]">
                                Upload Video
                            </p>
                        </div>
                        <div className="col-span-4 flex flex-col items-center" onClick={() => navigate("/createshort")}>
                            <MdOutlineMovieFilter className="w-[25px] h-[25px] text-[#dedede] hover:text-[#ffffff] cursor-pointer" />
                            <p className="text-[#dedede] hover:text-[#ffffff] cursor-pointer text-[12px]">
                                Create Clips
                            </p>
                        </div>
                        <div className="col-span-4 flex flex-col items-center" onClick={() => navigate("/createpost")}>
                            <RiImageAddFill className="w-[25px] h-[25px] text-[#dedede] hover:text-[#ffffff] cursor-pointer" />
                            <p className="text-[#dedede] hover:text-[#ffffff] cursor-pointer text-[12px]">
                                Create Post
                            </p>
                        </div>
                        <div className="col-span-4 flex flex-col items-center" onClick={() => navigate("/createnews")}>
                            <FaRegNewspaper className="w-[25px] h-[25px] text-[#dedede] hover:text-[#ffffff] cursor-pointer" />
                            <p className="text-[#dedede] hover:text-[#ffffff] cursor-pointer text-[12px]">
                                Create News
                            </p>
                        </div>
                        <div className="col-span-4 flex flex-col items-center" onClick={() => navigate("/create/podcast")}>
                            <MdOutlinePodcasts className="w-[25px] h-[25px] text-[#dedede] hover:text-[#ffffff] cursor-pointer" />
                            <p className="text-[#dedede] hover:text-[#ffffff] cursor-pointer text-[12px]">
                                Create Podcast
                            </p>
                        </div>
                        <div className="col-span-12 flex flex-col items-center" onClick={() => navigate("/golive")}>
                            <CiStreamOn className="w-[25px] h-[25px] text-[#dedede] hover:text-[#ffffff] cursor-pointer" />
                            <p className="text-[#dedede] hover:text-[#ffffff] cursor-pointer text-[12px] ">
                                Go Live
                            </p>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Footer