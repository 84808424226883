import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainMenu from "../components/common/MainMenu";
import { IoCaretBackCircle } from "react-icons/io5";
import Carousel from "react-multi-carousel";
import { mainHomePageData } from "../data";
import { FaSearch } from "react-icons/fa";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1
    }
};

const OtherUserAccount = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [videoData, setVideoData] = useState(null);

    useEffect(() => {
        const data = mainHomePageData.find(video => video.id === parseInt(id));
        setVideoData(data);
    }, [id]);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / other user account / V1 / 26 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                <div className="col-span-3 flex items-center justify-start gap-3">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[20px] text-black cursor-pointer"
                    />
                </div>
                <div className="col-span-6 flex items-center justify-center gap-2">
                    <p className="text-[14px] font-bold">
                        {videoData?.channel}
                    </p>
                </div>
                <div className="col-span-3 flex items-center justify-between gap-2">
                    <FaSearch className="text-black text-[18px] ml-4 cursor-pointer" />
                    <MainMenu />
                </div>
            </div>

            <div className="mt-2 grid grid-cols-12 px-2 border-b-[0.4px] border-[#3e3e3e] pb-1">
                <div className="col-span-4 flex flex-col items-start justify-between">
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Subscribe
                    </p>
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Notification
                    </p>
                </div>
                <div className="col-span-4 flex items-center justify-center">
                    {videoData && (
                        <img
                            alt={videoData?.channel}
                            src={videoData?.channelLogo}
                            className="h-[4rem] w-[4rem] rounded-full object-cover cursor-pointer"
                        />
                    )}
                </div>
                <div className="col-span-4 flex flex-col items-end justify-between">
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        {videoData?.followers}
                    </p>
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Related
                    </p>
                </div>
                <div className="col-span-12 mt-2">
                    <p className="text-[10px] leading-[14px] line-clamp-3 text-justify text-white">
                        {videoData?.description}
                    </p>
                    <div className="flex flex-col items-start mt-1">
                        <p className="text-[10px] flex-grow-0 text-light-blue-600 hover:text-light-blue-500 cursor-pointer">
                            https://www.example1.com
                        </p>
                        <p className="text-[10px] text-light-blue-600 hover:text-light-blue-500 cursor-pointer">
                            https://www.example2.com
                        </p>
                    </div>
                </div>
            </div>

            <div className="px-2 py-2 bg-black sticky top-0">
                <Carousel
                    ssr={true}
                    arrows={false}
                    infinite={false}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    className=" select-none"
                >
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Popular
                    </p>

                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Videos
                    </p>

                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Clips
                    </p>

                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Podcasts
                    </p>

                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        News
                    </p>
                </Carousel>
            </div>

            <div className="px-2">
                {mainHomePageData.map((data) => (
                    <div className="pb-3" key={data.id}>
                        <img
                            alt="thumbnail"
                            src={data.thumbnail}
                            onClick={() => navigate(`/video/${data.id}`)}
                            className="w-full h-[10rem] object-cover rounded-sm cursor-pointer"
                        />
                        <div className="grid grid-cols-12 mt-1">
                            <div className="col-span-11 flex leading-[18px]">
                                <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                            </div>
                            <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                                <p className="text-white text-[14px] cursor-pointer">
                                    : :
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className="col-span-4 flex items-center justify-start">
                                <p className="bg-[#353535] text-[10px] text-[#ffffff] flex items-center px-3 py-[2px] rounded-full cursor-pointer">
                                    {data.videoLength} length
                                </p>
                            </div>
                            <div className="col-span-4 flex items-center justify-center">
                                <p className="bg-[#353535] text-[10px] text-[#ffffff] flex items-center px-3 py-[2px] rounded-full cursor-pointer">
                                    {data.date} date
                                </p>
                            </div>
                            <div className="col-span-4 flex items-center justify-end">
                                <p className="bg-[#353535] text-[10px] text-[#ffffff] flex items-center px-3 py-[2px] rounded-full cursor-pointer">
                                    {data.views} views
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </Fragment>
    );
};

export default OtherUserAccount;
