import React, { Fragment, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import menuIcon from "../assets/16_DOTS_SQUARE_BOX.png";
import createVideo from "../assets/16dot_menu/create_video.png";
import uploadVideo from "../assets/16dot_menu/upload_video.png";
import createShorts from "../assets/16dot_menu/create_clips.png";
import createPost from "../assets/16dot_menu/create_post.png";
import goLive from "../assets/16dot_menu/go_live.png";
import createNews from "../assets/16dot_menu/create_news.png";
import watchList from "../assets/16dot_menu/watch_list.png";
import myPlaylist from "../assets/16dot_menu/my_playlist.png";
import downloaded from "../assets/16dot_menu/downloaded.png";
import settings from "../assets/16dot_menu/settings.png";
import editAccount from "../assets/16dot_menu/change_account.png";
import likedVideo from "../assets/16dot_menu/liked_video.png";
import history from "../assets/16dot_menu/history.png";
import trending from "../assets/16dot_menu/trending.png";
import clips from "../assets/16dot_menu/clips.png";
import bottomIcon from "../assets/16dot_menu/bottom_icon.png";

import { CiStreamOn } from "react-icons/ci";
import { FaCircleUser } from "react-icons/fa6";
import { IoIosHelpCircle } from "react-icons/io";
// import { BsMusicNoteList } from "react-icons/bs";
import { IoNotifications, IoMusicalNotes, IoSearch } from "react-icons/io5";
import { MdWorkspacePremium, MdOutlinePermMedia, MdOutlineContentPaste } from "react-icons/md";
import { FaUsers, FaPodcast, FaRegNewspaper, FaUserCheck, FaRegCalendarAlt } from "react-icons/fa";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [menu, setMenu] = useState(false);
    const [additionalMenu, setAdditionalMenu] = useState(false);
    const menuRef = useRef(null);
    const toggleButtonRef = useRef(null);

    const menuItems = [
        { icon: FaCircleUser, label: "User Account", pathName: "/account" },
        { img: trending, label: "Trending", pathName: "/trending" },
        { img: clips, label: "Clips", pathName: "/clips" },
        { img: createVideo, label: "Create Video", pathName: "/createvideo" },
        { img: uploadVideo, label: "Upload Video", pathName: "/uploadvideo" },
        { img: createShorts, label: "Create Clips", pathName: "/createshort" },
        { img: createPost, label: "Create Post", pathName: "/createpost" },
        { img: createNews, label: "Create News", pathName: "/createnews" },
        { img: goLive, label: "Go Live", pathName: "/golive" },
        { img: watchList, label: "Watch List", pathName: "/watchlist" },
        { img: myPlaylist, label: "My Playlist", pathName: "/playlist" },
        { img: downloaded, label: "Downloaded", pathName: "/downloads" },
        { img: settings, label: "Settings", pathName: "/settings" },
        { img: editAccount, label: "Edit Account", pathName: "/edit/account" },
        { img: likedVideo, label: "Liked Video", pathName: "/likedvideos" },
        { img: history, label: "History", pathName: "/history" },
        { icon: MdWorkspacePremium, label: "KV Sponsored", pathName: "/sponsore" },
    ];

    const additionalMenuItems = [
        { icon: FaUsers, label: "About Us", pathName: "/about" },
        { icon: MdOutlinePermMedia, label: "Media", pathName: "/media" },
        { icon: MdOutlineContentPaste, label: "Blog", pathName: "/blog" },
        { icon: IoIosHelpCircle, label: "Help", pathName: "/help" },
    ];

    const getLabel = () => {
        if (location.pathname.startsWith("/video/")) {
            return "KV / FE / VIDEO DETAILS / V1 / 03 AUG, 2024";
        } else if (location.pathname === "/home") {
            return "KV / FE / HOME / V1 / 31 JULY, 2024";
        } else {
            return "KV / FE / HOME / V1 / 31 JULY, 2024";
        }
    };

    const label = getLabel();
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const handleMenuToggle = (e) => {
        e.stopPropagation();
        setMenu((prevMenu) => !prevMenu);
        setAdditionalMenu(false);
    };

    const handleAdditionalMenuToggle = () => {
        setAdditionalMenu((prevMenu) => !prevMenu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !toggleButtonRef.current.contains(event.target)
            ) {
                setMenu(false);
                setAdditionalMenu(false); // Close both menus on outside click
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>

            <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                <div className="col-span-6 flex items-center justify-start gap-6">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                    <IoSearch className="text-[20px] text-black cursor-pointer" onClick={() => navigate("/search")} />
                </div>
                <div className="col-span-6 flex items-center justify-end gap-4">
                    <IoNotifications className="text-[20px] cursor-pointer" onClick={() => navigate("/notification")} />
                    <FaCircleUser className="w-[18px] h-[18px] cursor-pointer" onClick={() => navigate("/account")} />
                    {/* <IoVideocam className="text-[20px] cursor-pointer" onClick={() => navigate("/clips")} /> */}
                    <img
                        alt="menu"
                        src={menuIcon}
                        ref={toggleButtonRef}
                        onClick={handleMenuToggle}
                        className="h-6 w-6 cursor-pointer"
                    />
                </div>
                {menu && (
                    <div ref={menuRef} className="absolute top-[2.45rem] right-2 bg-[#ffffff]">
                        {menuItems.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => navigate(item.pathName)}
                                className={`flex items-center gap-3 px-2 py-[2px] 
                                    ${index !== menuItems.length - 1 || additionalMenu ? "border-b-[0.4px] border-[#dedede]" : ""}
                                `}
                            >
                                {item.img ? (
                                    <img src={item.img} alt={item.label} className="h-4 w-4" />
                                ) : (
                                    <item.icon className="h-4 w-4 text-[#5c5b5b]" />
                                )}
                                <p className="text-[12px] hover:text-[#f33535] cursor-pointer">{item.label}</p>
                            </div>
                        ))}
                        {menu && additionalMenu && (
                            <>
                                {additionalMenuItems.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => navigate(item.pathName)}
                                        className={`flex items-center gap-3 px-2 py-[2px] border-[#dedede] 
                                            ${index === additionalMenuItems.length - 1 ? "" : "border-b-[0.4px]"}
                                        `}
                                    >
                                        {item.img ? (
                                            <img src={item.img} alt={item.label} className="h-4 w-4" />
                                        ) : (
                                            <item.icon className="h-4 w-4 text-[#5c5b5b]" />
                                        )}
                                        <p className="text-[12px] hover:text-[#f33535] cursor-pointer">{item.label}</p>
                                    </div>
                                ))}
                            </>
                        )}
                        <div className="flex items-center gap-3 px-2 pt-[2px] pb-2 relative">
                            {/* {!additionalMenu &&
                                <img
                                    src={clips}
                                    alt="create video"
                                    className="h-4 w-4"
                                    onClick={() => navigate("/clips")}
                                />
                            }
                            {!additionalMenu &&
                                <p
                                    onClick={() => navigate("/clips")}
                                    className="text-[12px] hover:text-[#f33535] cursor-pointer"
                                >
                                    Clips
                                </p>
                            } */}
                            <div className="absolute -bottom-[7px] left-1/2 -translate-x-1/2">
                                <div className="bg-[#9F9F9F] px-5 py-1 cursor-pointer" onClick={handleAdditionalMenuToggle}>
                                    <img alt="bottom icon" src={bottomIcon} className="h-[6px] w-[6px] flex items-center justify-center" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            
            {location.pathname === "/home" && (
                <div className="bg-black flex items-center justify-center py-1">
                    <div className="inline-flex items-center justify-center gap-4 border border-gray-500 bg-white text-[#6d6d6d] rounded-full px-3 py-1">
                        <MdWorkspacePremium
                            onClick={() => navigate("/sponsore")}
                            className="h-4 w-4 cursor-pointer"
                        />
                        <FaPodcast
                            onClick={() => navigate("/podcasts")}
                            className="h-4 w-4 cursor-pointer"
                        />
                        <FaRegNewspaper
                            onClick={() => navigate("/live/news")}
                            className="h-4 w-4 cursor-pointer"
                        />
                        <CiStreamOn
                            onClick={() => navigate("/livestream")}
                            className="h-4 w-4 cursor-pointer"
                        />
                        <IoMusicalNotes
                            // onClick={() => navigate("/music")}
                            className="h-4 w-4 cursor-pointer"
                        />
                        <FaUserCheck
                            onClick={() => navigate("/subscribed")}
                            className="h-4 w-4 cursor-pointer"
                        />
                        <FaRegCalendarAlt
                            // onClick={() => navigate("/calendar")}
                            className="h-4 w-4 cursor-pointer"
                        />
                        {/* <BsMusicNoteList
                            // onClick={() => navigate("/slides")}
                            className="h-4 w-4 cursor-pointer"
                        /> */}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default Header;
